<template>
  <div>
    <v-card flat class="borda">
      <v-card-text>
        <v-form ref="form" :action="url" v-model="valid" v-on:submit="search($event)" method="POST" enctype="multipart/form-data" id="form">
          <input type="hidden" name="_token" :value="token" />
          <input type="hidden" name="_method" value="POST" />
          <v-row justify="center">
            <v-col cols="12" sm="12" md="4" class="mb-n5">
              <span class="font-weight-bold black--text">Produto *</span>
              <v-select
                no-data-text="Nenhum produto cadastrado"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="formData.product_id"
                :items="products"
                item-value="id"
                item-text="name"
                @change="selectProduct()"
                :loading="loadingProducts"
                :disabled="loadingProducts"
                required
                :rules="requiredRules"
                name="product_id"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="mb-n5">
              <span class="font-weight-bold black--text">Categoria</span>
              <v-select
                no-data-text="Nenhuma categoria cadastrada"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="formData.category_id"
                :items="categories"
                item-value="id"
                item-text="name"
                :loading="loadingCategories"
                :disabled="loadingCategories || !formData.product_id"
                name="category_id"
                clearable
                multiple
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="mb-n5">
              <span class="font-weight-bold black--text">Tipo</span>
              <v-select
                no-data-text="Nenhum tipo cadastrado"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="formData.type_id"
                :items="types"
                item-value="id"
                item-text="name"
                :loading="loadingTypes"
                :disabled="loadingTypes || !formData.product_id"
                name="type_id"
                clearable
                multiple
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="mb-n5">
              <span class="font-weight-bold black--text">Calibre</span>
              <v-select
                no-data-text="Nenhuma calibre cadastrado"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="formData.caliber_id"
                :items="calibers"
                item-value="id"
                item-text="value"
                :loading="loadingCalibers"
                :disabled="loadingCalibers || !formData.product_id"
                name="caliber_id"
                clearable
                multiple
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="mb-n5">
              <span class="font-weight-bold black--text">Peso</span>
              <v-select
                no-data-text="Nenhum peso cadastrado"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="formData.weight_id"
                :items="weights"
                item-value="id"
                item-text="value"
                :loading="loadingWeights"
                :disabled="loadingWeights || !formData.product_id"
                name="weight_id"
                clearable
                multiple
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="mb-n5">
              <span class="font-weight-bold black--text">Marca</span>
              <v-select
                no-data-text="Nenhuma marca cadastrada"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="formData.brand_id"
                :items="brands"
                item-value="id"
                item-text="name"
                :loading="loadingBrands"
                :disabled="loadingBrands || !formData.product_id"
                name="brand_id"
                clearable
                multiple
              ></v-select>
            </v-col>
            <v-col cols="12" sm="8" md="4" class="mb-n5">
              <span class="font-weight-bold black--text">Pólo</span>
              <v-select
                no-data-text="Nenhum pólo cadastrado"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="formData.pole"
                :items="poles"
                item-value="id"
                item-text="name"
                :loading="loadingPoles"
                :disabled="loadingPoles"
                name="pole"
                clearable
                multiple
                required
                :rules="requiredRules"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="8" md="4" class="mb-n5">
              <span class="font-weight-bold black--text">Parceiro / Produtor</span>
              <v-autocomplete
                no-data-text="Nenhuma empresa cadastrada"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="formData.company"
                :items="companies"
                item-value="id"
                item-text="name"
                :loading="loadingCompanies"
                :disabled="loadingCompanies"
                name="company"
                clearable
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{data.item.name}}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-chip x-small class="font-weight-bold" text-color="white" :color="data.item.type.color">{{data.item.type.text}}</v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-col cols="12" sm="12" md="12" class="" v-if="!$vuetify.breakpoint.mobile">
            <v-row justify="center" class="mb-2 mt-5">
              <v-btn
                color="#95D904"
                style="color: #FFF !important"
                type="submit"
                :loading="loadingSearch"
                :disabled="loadingSearch"
                >
                BUSCAR
              </v-btn>
            </v-row>
          </v-col>
          <div style="position:fixed;bottom:40px;left:50%;margin-left: -50px;z-index: 9999;" v-if="$vuetify.breakpoint.mobile">
            <v-btn
              color="#95D904"
              style="color: #FFF !important"
              type="submit"
              :loading="loadingSearch"
              :disabled="loadingSearch"
              >
              BUSCAR
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

  </div>
</template>
<script>
import axios from "axios";

export default {
    props: [
      'edit',
      'company',
      'url',
      'token',
    ],
    data() {        
        return {
          requiredRules: [v => !!v || "Campo Obrigatório."],
          ruleType: [],
          ruleCategory: [],
          ruleCaliber: [],
          ruleLote: [],
          ruleBrand: [],
          rulePicture: [],
          valid: true,
          productForms: [],
          formSelected: '',
          products: [],
          types: [],
          categories: [],
          calibers: [],
          brands: [],
          weights: [],
          poles: [],
          companies: [],
          loadingSearch: false,
          loadingProducts: false,
          loadingTypes: false,
          loadingCategories: false,
          loadingCalibers: false,
          loadingBrands: false,
          loadingWeights: false,
          loadingPoles: false,
          loadingCompanies: false,
          dialog_gallery: false,
          itemsGallery: [],
          dialog_video: false,
          itemsVideo: [],
          formData: {
            id: '',
            company_id: this.company,
            user_id: '',
            product_id: '',
            type_id: '',
            category_id: '',
            caliber_id: '',
            lote: '',
            brand_id: '',
            weight_id: '',
            min_atacado: '',
            min_varejo: '',
            available: '',
            projection: '',
            date_validate: '',
            special_offer: '',
          },
          moneyFormat: {
            decimal: ",",
            thousands: ".",
            prefix: "R$ ",
            precision: 2,
            masked: false,
          },
          menu1: false,
          dateFormatted: "",
          data_mask: "##/##/####",
          date: "",
        };
    },

    created() {
      this.getPoles();
      this.getProducts();
      this.getCompanies();
    },    

    methods: {

      clear(){
        this.formData = {
          id: '',
          company_id: this.company,
          user_id: '',
          product_id: '',
          type_id: '',
          category_id: '',
          caliber_id: '',
          lote: '',
          brand_id: '',
          weight_id: '',
          min_atacado: '',
          min_varejo: '',
          available: '',
          projection: '',
          date_validate: '',
          special_offer: '',
        }
        this.formSelected = '';
        this.$refs.form.resetValidation();
      },

      getProducts(edit){
        this.loadingProducts = true;
        axios
          .get('/api/product')
          .then((response) => {
            console.log("getProducts");
            console.log(response.data);
            this.products = response.data;
            this.loadingProducts = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os produtos.");
            this.loadingProducts = false;
            console.log(err)
          })
      },

      getTypes(){
        this.loadingTypes = true;
        axios
          .get('/api/product-type?product_id='+this.formData.product_id)
          .then((response) => {
            console.log("getTypes");
            console.log(response.data);
            this.types = response.data;
            this.loadingTypes = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os tipos.");
            this.loadingTypes = false;
            console.log(err)
          })
      },

      getCategories(){
        this.loadingCategories = true;
        axios
          .get('/api/product-category?product_id='+this.formData.product_id)
          .then((response) => {
            console.log("getCategories");
            console.log(response.data);
            this.categories = response.data;
            this.loadingCategories = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar as categorias.");
            this.loadingCategories = false;
            console.log(err)
          })
      },

      getCalibers(){
        this.loadingCalibers = true;
        axios
          .get('/api/product-caliber?product_id='+this.formData.product_id)
          .then((response) => {
            console.log("getCalibers");
            console.log(response.data);
            this.calibers = response.data;
            this.loadingCalibers = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os calibres.");
            this.loadingCalibers = false;
            console.log(err)
          })
      },

      getBrands(){
        this.loadingBrands = true;
        axios
          .get('/api/product-brand')
          .then((response) => {
            console.log("getBrands");
            console.log(response.data);
            this.brands = response.data;
            this.loadingBrands = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar as marcas.");
            this.loadingBrands = false;
            console.log(err)
          })
      },

      getWeights(){
        this.loadingWeights = true;
        axios
          .get('/api/product-weight?product_id='+this.formData.product_id)
          .then((response) => {
            console.log("getWeights");
            console.log(response.data);
            this.weights = response.data;
            this.loadingWeights = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os pesos.");
            this.loadingWeights = false;
            console.log(err)
          })
      },

      getPoles(){
        this.loadingPoles = true;
        axios
          .get('/api/pole')
          .then((response) => {
            console.log("getPoles");
            console.log(response.data);
            this.poles = response.data;
            this.loadingPoles = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os polos.");
            this.loadingPoles = false;
            console.log(err)
          })
      },

      getCompanies(){
        this.loadingCompanies = true;
        axios
          .get('/api/company/active')
          .then((response) => {
            console.log("getCompanies");
            console.log(response.data);
            this.companies = response.data;
            this.loadingCompanies = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os polos.");
            this.loadingCompanies = false;
            console.log(err)
          })
      },

      selectProduct(){
        if (this.formData.product_id){
          console.log("this.formData.product_id");
          console.log(this.formData.product_id);
          
          this.getTypes();
          this.getCategories();
          this.getCalibers();
          this.getBrands();
          this.getWeights();
        }
      },

      search (e){
        this.loadingSearch = true;
        if (!this.$refs.form.validate()) {
          this.loadingSearch = false;
          e.preventDefault();
        }
      }

    },
        
}
</script>
<style lang="scss" scoped>

</style>