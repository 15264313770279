<template>
  <div>
    <v-card class="borda" flat :loading="loadingDetails">
      <div v-if="details">
        <v-col cols="12" sm="12" md="12" class="">
          <v-row justify="center" class="mb-2 mt-5">
            <v-btn
              color="primary"
              class="white--text"
              @click="dialog_order_generate = true"
              v-if="!details.date_generated && (!company || company == details.seller_id)"
            >
              Gerar pedido
            </v-btn>
            <v-btn
              color="primary"
              class="white--text ml-3"
              @click="generatePdfOrder()"
              :loading="loadingGenerate"
              :disabled="loadingGenerate"
              v-if="details.date_generated"
            >
              <v-icon left dark>
                mdi-file-pdf-outline
              </v-icon>
              VISUALIZAR PEDIDO
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="">
          <v-col sm="12" md="12" class="px-0 py-3 text-center">
            <a class="text-underline" @click="accessProposal(details.proposal.id)">Ver detalhes da proposta</a>
          </v-col>
          <!-- <v-col sm="12" md="12" class="px-0">
            <div class="headline">Proposta</div>
            <div class="text--primary">
              OFERTA: R$ {{details.proposal.value}}<br>
              CAIXAS: {{details.proposal.boxes}}<br>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="px-0">
            <div class="headline">Produto</div>
            <div class="text--primary">
              <span v-if="details.type">{{details.product.name}}</span> |
              <span v-if="details.type">{{details.type.name}}</span>
              <span v-if="details.category">{{details.category.name}}</span>
              <span v-if="details.caliber">Cal.{{details.caliber.value}}</span>
              <span v-if="details.weight">{{details.weight.value}}kg</span>
              <span v-if="details.brand">- {{details.brand.name}}</span>
            </div>
          </v-col> -->
        </v-col>

        <v-col cols="12" sm="12" md="12" class="row mx-0">
          <!-- NOTA FISCAL -->
          <v-col sm="4" md="3" class="pl-0 pr-3 pt-0" v-if="details.invoice">
            <v-card class="mb-3 w-100" min-height="140">
              <v-card-subtitle class="pb-3 text-center">
                Nota Fiscal
              </v-card-subtitle>
              <v-card-text class="px-0 py-0 d-flex justify-center align-items-center">
                <a :href="details.invoice" target="_blank">
                  <!-- <v-icon size="60" class="">mdi-file-document-outline</v-icon> -->
                  <v-img
                    src="/img/icon-nfe.svg"
                    lazy-src="/img/icon-nfe.svg"
                    max-width="60"
                    ></v-img>
                </a>
              </v-card-text>
              <v-card-actions class="d-flex justify-center align-items-center">
                <v-btn icon @click="deleteInvoice()" :disabled="loadingInvoice" :loading="loadingInvoice" v-if="!company || company == details.seller_id">
                  <v-icon small class="">mdi-delete-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <!-- BOLETO -->
          <v-col sm="4" md="3" class="pl-0 pr-3 pt-0" v-if="details.bank_slip">
            <v-card class="mb-3 w-100" min-height="140">
              <v-card-subtitle class="pb-3 text-center">
                Boleto
              </v-card-subtitle>
              <v-card-text class="px-0 py-0 d-flex justify-center align-items-center">
                <a :href="details.bank_slip" target="_blank">
                  <v-icon size="60" class="">mdi-file-document-outline</v-icon>
                </a>
              </v-card-text>
              <v-card-actions class="d-flex justify-center align-items-center">
                <v-btn icon @click="deleteBankSlip()" :disabled="loadingBankSlip" :loading="loadingBankSlip" v-if="!company || company == details.seller_id">
                  <v-icon small class="">mdi-delete-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <!-- OUTROS ANEXOS -->
          <v-col sm="4" md="3" class="pl-0 pr-3 pt-0" v-for="(item, index) in this.details.attachments" :key="index">
            <v-card class="mb-3 w-100" min-height="140">
              <v-card-subtitle class="pb-3 text-center">
                {{item.name}}
              </v-card-subtitle>
              <v-card-text class="px-0 py-0 d-flex justify-center align-items-center">
                <a :href="item.url" target="_blank">
                  <v-icon size="60" class="">mdi-paperclip</v-icon>
                </a>
              </v-card-text>
              <v-card-actions class="d-flex justify-center align-items-center">
                <v-btn icon @click="deleteAttachment(item.id, index)" v-if="!company || company == details.seller_id">
                  <v-icon small class="">mdi-delete-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-col>

        <v-col cols="12" sm="12" md="12" class="row mx-0" v-if="details.date_generated">
          <v-col sm="12" md="6" class="pl-0 pr-3" v-if="!details.invoice && (!company || company == details.seller_id)">
            <span class="black--text">Nota Fiscal</span>
            <v-file-input
              label="Clique para selecionar"
              show-size
              prepend-icon="mdi-file-document-outline"
              solo
              v-model="invoice"
              outlined
              flat
              dense
              @change="saveInvoice()"
              :disabled="loadingInvoice"
              :loading="loadingInvoice"
              @click="$event.stopPropagation()"
            ></v-file-input>
          </v-col>
          <v-col sm="12" md="6" class="pl-0 pr-3" v-if="!details.bank_slip && (!company || company == details.seller_id)">
            <span class="black--text">Boleto</span>
            <v-file-input
              label="Clique para selecionar"
              show-size
              prepend-icon="mdi-file-document-outline"
              solo
              v-model="bank_slip"
              outlined
              flat
              dense
              @change="saveBankSlip()"
              :disabled="loadingBankSlip"
              :loading="loadingBankSlip"
              @click="$event.stopPropagation()"
            ></v-file-input>
          </v-col>
        </v-col>

        <v-col cols="12" sm="12" md="12" class="row mx-0 my-3" v-if="!details.date_generated">
          <span class="text-warning">Aguardando o pedido ser gerado...</span>
        </v-col>

        <v-form cols="12" sm="12" md="12" class="row mx-0" ref="formAttachment" v-model="valid" @submit.prevent="saveAttachment()" style="padding: 15px;" v-if="(!company || company == details.seller_id) && details.date_generated">
          <div class="black--text">Outros Anexos</div>
          <v-col sm="12" md="12" class="px-0 row mx-0">
            <v-col sm="12" md="5" class="pl-0 pr-3">
              <v-file-input
                label="Clique para selecionar"
                show-size
                prepend-icon="mdi-paperclip"
                solo
                v-model="attachment.file"
                outlined
                flat
                dense
                required
                :rules="requiredFileRules"
                @click="$event.stopPropagation()"
              ></v-file-input>
            </v-col>
            <v-col sm="12" md="5" class="pl-0 pr-3">
              <v-text-field
                placeholder="Descrição do anexo"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="attachment.name"
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col align="center" justify="center" sm="12" md="2" class="px-0">
              <v-btn
                color="#95D904"
                style="color: #FFF !important"
                class="mt-2"
                type="submit"
                :disabled="loadingAttachment || attachment.file.length == 0"
                :loading="loadingAttachment"
              >
                Anexar
              </v-btn>
            </v-col>
          </v-col>
        </v-form>

        <v-row>
          <v-col cols="12" sm="12" md="6" class="">
            <v-card class="mb-3 pa-3" min-height="150" v-if="details.company">
              <v-card-text>
                <!-- <div class="headline">Comprador</div> -->
                <v-chip small class="mb-1" color="#17a2b8" text-color="white">Comprador</v-chip>
                <p class="headline text--primary">{{details.company.fantasy_name}}</p>
                <div class="text--primary">
                  CNPJ: {{details.company.cnpj}}<br>
                  Telefone: {{details.company.phone}}<br>
                  E-mail: {{details.company.email}}<br>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="">
            <v-card class="mb-3 pa-3" min-height="150" v-if="details.seller">
              <v-card-text>
                <!-- <div class="headline">Vendedor</div> -->
                <v-chip small class="mb-1" color="#17a2b8" text-color="white">Vendedor</v-chip>
                <p class="headline text--primary">{{details.seller.fantasy_name}}</p>
                <div class="text--primary">
                  CNPJ: {{details.seller.cnpj}}<br>
                  Telefone: {{details.seller.phone}}<br>
                  E-mail: {{details.seller.email}}<br>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="6" class="">
            <v-card class="mb-3 pa-3" min-height="150" v-if="details.address">
              <v-card-text>
                <!-- <div class="headline">Endereço de entrega</div> -->
                <v-chip small class="mb-1" color="#17a2b8" text-color="white">Endereço de entrega</v-chip>
                <p class="headline text--primary">{{details.address.cep}}</p>
                <div class="text--primary">
                  {{details.address.street}}, {{details.address.number}}, {{details.address.district}}<br>
                  <span v-if="details.address.complement">{{details.address.complement}}</span>
                  {{details.address.city}} - {{details.address.state}}, {{details.address.country}}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="">
            <v-card class="mb-3 pa-3" min-height="150" v-if="details.address">
              <v-card-text v-if="details.transport">
                <!-- <div class="headline">Transportadora</div> -->
                <v-chip small class="mb-1" color="#17a2b8" text-color="white">Transportadora</v-chip>
                <p class="headline text--primary">{{details.transport.fantasy_name}}</p>
                <div class="text--primary">
                  CNPJ: {{details.transport.cnpj}}<br>
                  Telefone: {{details.transport.phone}}<br>
                  E-mail: {{details.transport.email}}<br>
                </div>
              </v-card-text>
              <v-card-text v-if="!details.transport">
                <!-- <div class="headline">Transportadora</div> -->
                <v-chip small class="mb-1" color="#17a2b8" text-color="white">Transportadora</v-chip>
                <p class="headline text--primary">{{details.transport_name}}</p>
                <div class="text--primary">
                  CNPJ: {{details.transport_cnpj}}<br>
                  Telefone: {{details.transport_phone}}<br>
                  E-mail: {{details.transport_email}}<br>
                  Informações adicionais: {{details.transport_information}}<br>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        
      </div>
    </v-card>

    <!-- DIALOG ATUALIZAR PEDIDO -->
    <v-dialog persistent v-model="dialog_order_generate" max-width="550">
      <v-card class="borda" flat min-height="300">
        <v-card-title class="ma-2">
          Gerar Pedido
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_order_generate = false;">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form2" v-model="valid2" v-on:submit="updateOrder($event)">
            <div class="h5">Transporte</div>
            <v-row class="pa-0">
              <v-col cols="12" sm="12" md="6" class="mb-n5">
                <v-text-field
                  label="Placa do Cavalo *"
                  outlined
                  color="#858585"
                  v-model="details.vehicle_plate"
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="mb-n5">
                <v-text-field
                  label="Placa da Carreta *"
                  outlined
                  color="#858585"
                  v-model="details.trailer_plate"
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-n5 mt-2 mb-2">
                <v-radio-group v-model="details.transport_type" row class="p-0 mt-0" label="Tipo de Transporte" required :rules="requiredRules">
                  <v-radio label="Refrigerado" value="refrigerado" class="pr-5 mt-3"></v-radio>
                  <v-radio label="Lona Térmica" value="lona" class="mt-3"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="mb-n5 mt-2">
                <span class="" style="font-size:16px;">Valor Frete (por caixa)</span>
                <money
                  class="input mt-2"
                  v-model.lazy="details.value_shipment"
                  v-bind="moneyFormat"
                  label="frete"
                  required
                  :rules="requiredRules"
                ></money>
                <span v-if="details.value_shipment">
                  {{details.total_boxes}} caixas =
                  <strong>{{details.total_boxes * details.value_shipment | currency('R$ ', 2, { thousandsSeparator: '.', decimalSeparator: ',' })}}</strong>
                </span>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="mb-n5 mt-2">
                <span class="" style="font-size:16px;">Valor FunRural</span>
                <money
                  class="input mt-2"
                  v-model.lazy="details.value_funrural"
                  v-bind="moneyFormat"
                  required
                  :rules="requiredRules"
                ></money>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-n5 mt-3 mb-2">
                <v-radio-group v-model="details.shipment_type" row class="p-0 mt-0" label="Tipo de Frete" required :rules="requiredRules">
                  <v-radio label="FOB" value="FOB" class="pr-5 mt-3"></v-radio>
                  <v-radio label="CIF" value="CIF" class="mt-3"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-n5">
                <v-text-field
                  label="Prazo de Pagamento"
                  outlined
                  color="#858585"
                  v-model="details.payment_term"
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-col cols="12" sm="12" md="12" class="text-center">
              <!-- <span class="text-danger font-weight-bold mb-1" v-if="errorPedido">{{errorPedido}}</span> -->
              <v-row justify="center" class="mb-2 mt-5">
                <v-btn
                  color="#95D904"
                  style="color: #FFF !important"
                  type="submit"
                  :disabled="loadingUpdate"
                  :loading="loadingUpdate"
                  >
                  SALVAR
                </v-btn>
              </v-row>
            </v-col>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}
.v-middle {
  vertical-align: middle !important;
}
</style>

<script>
import axios from "axios";
import io from 'socket.io-client';
import moment from 'moment';

export default {
  props: [
    "order",
    "user",
    "company",
  ],
  data: function() {
    var tunnel = 'https://tunnel.d3t.com.br';
    var api = 'https://manzanas.app/api';

    if (window.location.hostname == 'localhost'){
      tunnel = 'http://172.20.0.1:5555';
      api = 'http://172.24.0.1/api';
    }
    return {
      details: '',
      loadingDetails: false,
      invoice: [],
      loadingInvoice: false,
      bank_slip: [],
      loadingBankSlip: false,
      attachment: {
        file: [],
        name: '',
      },
      loadingAttachment: false,
      requiredRules: [v => !!v || "Campo Obrigatório."],
      requiredFileRules: [
        v => !!v || 'Campo Obrigatório.',
        // v => (v && v.length > 0) || 'Campo Obrigatório.',
      ],
      valid: true,
      valid2: true,
      socket: io(tunnel, {
        query: `domain=${api}&authorization=${this.token}`
      }),
      loadingUpdate: false,
      loadingGenerate: false,
      dialog_order_generate: false,
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
    };
  },
  created() {
    if (this.order) {
      this.getOrder(this.order);
    }
  },
  methods: {

    getOrder(id, notLoading) {
      if (!notLoading){
        this.loadingDetails = true;
        this.details = '';
      }

      axios.get('/api/order/' + id)
      .then(response => {
        console.log('getOrder');
        console.log(response.data);
        if (response.data){
          this.details = response.data;
          this.details.value_shipment = 0;
          this.details.value_funrural = 0;
        }
      })
      .catch(e => {
        var msg = 'Houve um erro ao buscar os detalhes do pedido! Por favor, tente novamente.'
        this.$swal("Oops!", msg, "error")
        console.error(e)
      })
      .finally(() => {
        this.loadingDetails = false;
      })
    },

    saveInvoice() {
      this.loadingInvoice = true;

      let formData = new FormData();
      formData.append(`id`, this.details.id);
      formData.append(`invoice[]`, this.invoice);

      axios.post('/api/order/invoice', formData)
      .then(response => {
        console.log('saveInvoice');
        console.log(response.data);
        if (!response.data.errors) {
          this.details.invoice = response.data.content;
          this.invoice = '';
          this.$toast.success("Nota Fiscal anexada com sucesso! :)");
          this.enviar_mensagem('order_invoice_add');
        }
      })
      .catch(e => {
        var msg = 'Houve um erro ao salvar a Nota Fiscal! Por favor, tente novamente.'
        this.$swal("Oops!", msg, "error")
        console.error(e)
      })
      .finally(() => {
        this.loadingInvoice = false;
      })
    },

    deleteInvoice(){
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente excluir a NOTA FISCAL?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim!",
        cancelButtonText: "Não!"
      }).then(result => {
        if (result.value) {
          this.loadingInvoice = true;
          var dados = {
            'id': this.details.id
          }
          axios.post('/api/order/invoice-delete/', dados)
          .then(response => {
            console.log('deleteInvoice');
            console.log(response.data);
            if (!response.data.errors) {
              this.details.invoice = '';
              this.$toast.success("Nota Fiscal deletada com sucesso! :)");
              this.enviar_mensagem('order_invoice_delete');
            }
          })
          .catch(e => {
            var msg = 'Houve um erro ao deletar a Nota Fiscal! Por favor, tente novamente.'
            this.$swal("Oops!", msg, "error")
            console.error(e)
          })
          .finally(() => {
            this.loadingInvoice = false;
          })
        }
      });
    },

    saveBankSlip() {
      this.loadingBankSlip = true;

      let formData = new FormData();
      formData.append(`id`, this.details.id);
      formData.append(`bank_slip[]`, this.bank_slip);

      axios.post('/api/order/bank-slip', formData)
      .then(response => {
        console.log('saveBankSlip');
        console.log(response.data);
        if (!response.data.errors) {
          this.details.bank_slip = response.data.content;
          this.bank_slip = '';
          this.$toast.success("Boleto anexado com sucesso! :)");
          this.enviar_mensagem('order_bank_slip_add');
        }
      })
      .catch(e => {
        this.$swal("Oops!", 'Houve um erro ao salvar o Boleto! Por favor, tente novamente.', "error")
        console.error(e)
      })
      .finally(() => {
        this.loadingBankSlip = false;
      })
    },

    deleteBankSlip(){
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente excluir o Boleto?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim!",
        cancelButtonText: "Não!"
      }).then(result => {
        if (result.value) {
          this.loadingBankSlip = true;
          var dados = {
            'id': this.details.id
          }
          axios.post('/api/order/bank-slip-delete/', dados)
          .then(response => {
            console.log('deleteBankSlip');
            console.log(response.data);
            if (!response.data.errors) {
              this.details.bank_slip = '';
              this.$toast.success("Boleto deletado com sucesso! :)");
              this.enviar_mensagem('order_bank_slip_delete');
            }
          })
          .catch(e => {
            this.$swal("Oops!", 'Houve um erro ao deletar o Boleto! Por favor, tente novamente.', "error");
            console.error(e)
          })
          .finally(() => {
            this.loadingBankSlip = false;
          })
        }
      });
    },

    saveAttachment() {
      if (this.$refs.formAttachment.validate()) {
        this.loadingAttachment = true;

        let formData = new FormData();
        formData.append(`id`, this.details.id);
        formData.append(`user_id`, this.user);
        formData.append(`attachment[]`, this.attachment.file);
        formData.append(`name`, this.attachment.name);

        axios.post('/api/order/attachment', formData)
        .then(response => {
          console.log('saveAttachment');
          console.log(response.data);
          if (!response.data.errors) {
            this.details.attachments.push(response.data.content);
            this.$toast.success("Arquivo anexado com sucesso! :)");
            this.attachment = {
              file: [],
              name: '',
            }
            this.$refs.formAttachment.resetValidation();
            this.enviar_mensagem('order_attachment_add');
          }
        })
        .catch(e => {
          this.$swal("Oops!", 'Houve um erro ao anexar o arquivo! Por favor, tente novamente.', "error")
          console.error(e)
        })
        .finally(() => {
          this.loadingAttachment = false;
        })
      }
    },

    deleteAttachment(id, index){
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente excluir o Anexo?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim!",
        cancelButtonText: "Não!"
      }).then(result => {
        if (result.value) {
          // this.loadingBankSlip = true;
          var dados = {
            'id': id
          }
          axios.post('/api/order/attachment-delete/', dados)
          .then(response => {
            console.log('deleteAttachment');
            console.log(response.data);
            if (!response.data.errors) {
              this.details.attachments.splice(index, 1);
              this.$toast.success("Anexo deletado com sucesso! :)");
              this.enviar_mensagem('order_attachment_delete');
            }
          })
          .catch(e => {
            this.$swal("Oops!", 'Houve um erro ao deletar o Anexo! Por favor, tente novamente.', "error");
            console.error(e)
          })
          .finally(() => {
            this.loadingBankSlip = false;
          })
        }
      });
    },

    accessProposal(proposal){
      this.$emit('openProposalChild', { 'proposal': proposal});
    },

    enviar_mensagem (type) {
      if (type == 'order_invoice_add'){
        var message = '<span class="title_chat_proposal">********** PEDIDO ATUALIZADO **********</span><br/>A NOTA FISCAL do pedido número <span class="font-weight-bold">'+this.details.id+'</span> está disponível :)<br/>';
      } else if (type == 'order_invoice_delete'){
        var message = '<span class="title_chat_proposal">********** PEDIDO ATUALIZADO **********</span><br/>A NOTA FISCAL do pedido número <span class="font-weight-bold">'+this.details.id+'</span> foi REMOVIDA<br/>';
      } else if (type == 'order_bank_slip_add'){
        var message = '<span class="title_chat_proposal">********** PEDIDO ATUALIZADO **********</span><br/>O BOLETO do pedido número <span class="font-weight-bold">'+this.details.id+'</span> está disponível :)<br/>';
      } else if (type == 'order_bank_slip_delete'){
        var message = '<span class="title_chat_proposal">********** PEDIDO ATUALIZADO **********</span><br/>O BOLETO do pedido número <span class="font-weight-bold">'+this.details.id+'</span> foi REMOVIDO<br/>';
      } else if (type == 'order_attachment_add'){
        var message = '<span class="title_chat_proposal">********** PEDIDO ATUALIZADO **********</span><br/>Novo arquivo anexado ao pedido número <span class="font-weight-bold">'+this.details.id+'</span><br/>';
      } else if (type == 'order_attachment_delete'){
        var message = '<span class="title_chat_proposal">********** PEDIDO ATUALIZADO **********</span><br/>Arquivo removido do pedido número <span class="font-weight-bold">'+this.details.id+'</span><br/>';
      } else if (type == 'order_generated'){
        var message = '<span class="title_chat_proposal">********** PEDIDO GERADO **********</span><br/>O pedido <span class="font-weight-bold">'+this.details.id+'</span> acaba de ser gerado<br/>';
      }

      var send_id = '';
      var receive_id = '';
      if (!this.company){ // MANZANAS ESTÁ ENVIANDO
        send_id = 1;
        receive_id = this.details.user_id;
      } else { // VENDEDOR ESTÁ ENVIANDO
        send_id = this.user;
        receive_id = this.details.user_id;
      }

      this.$emit('attChatChild', { 'message': message, 'type': type, 'control': this.details.id});

      // const data = {
      //   method: 'POST',
      //   endpoint: '/chat/new_message',
        
      //   content: {
      //     send_id: send_id,
      //     receive_id: receive_id,
      //     archive: null,
      //     message: message,
      //     created_at: moment().format('YYYY-MM-DD HH:mm'),
      //     type: type,
      //     control: this.details.id,
      //   }
      // }
      // console.log("data");
      // console.log(data);

      // this.socket.emit('event', data);
    },

    updateOrder(e){
      if (this.$refs.form2.validate()) {
        e.preventDefault();
        this.loadingUpdate = true;
        this.details.shipment_total = this.details.total_boxes * this.details.value_shipment;
        var dados = this.details;
        axios.post("/api/order/generate/" + this.details.id, dados)
          .then(response => {
            console.log("updateOrder");
            console.log(response.data);
            this.getOrder(this.order, true);
            this.$toast.success("Pedido gerado com sucesso! :)");
            this.dialog_order_generate = false;
            this.enviar_mensagem('order_generated');
            this.generatePdfOrder();
          })
          .catch(e => {
            this.$swal("Oops!", 'Houve um erro ao gerar o pedido! Por favor, tente novamente.', "error")
            console.error(e)
          })
          .finally(() => {
            // this.loadingUpdate = false;
          })
      } else {
        e.preventDefault();
        return false;
      }
    },

    generatePdfOrder(){
      this.loadingGenerate = true;
      let file = "/api/order/pdf-generate?order=" + this.details.id;
      let link = document.createElement("a");
      link.href = file;
      link.download = "Pedido_"+this.details.id+".pdf";
      link.target = "_blank";
      link.click();
      var self = this;
      setTimeout(function(){
        self.loadingGenerate = false;
        self.loadingUpdate = false;
      }, 1000);
      return null;
      axios.get("/api/order/pdf-generate?order=" + this.details.id)
        .then(response => {
          console.log("response");
          console.log(response);
          let file = response.data;
          let link = document.createElement("a");
          link.href = file;
          link.download = "Pedido_"+this.details.id+".pdf";
          link.target = "_blank";
          link.click();
          // const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', "Pedido_"+this.details.id+".pdf")
          // link.target = '_blank'
          // link.click();
        })
        .catch(e => {
          this.$swal("Oops!", 'Houve um erro ao gerar o PDF! Por favor, tente novamente.', "error")
          console.error(e)
        })
        .finally(() => {
          this.loadingGenerate = false;
          this.loadingUpdate = false;
        })
    },

  }
};
</script>