<template>
  <div>
    <v-row align="center" justify="center" v-if="partners.length">
      <!-- <v-col sm="12" md="3" class="px-0 py-0"></v-col> -->
      <v-col sm="12" md="6" class="px-0 py-0 mt-2">
        <v-carousel
          cycle
          interval="3000"
          height="300"
          hide-delimiters
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="(item, index) in partners"
            :key="index"
            :src="item.image"
            :lazy-src="item.image"
            :href="item.url"
            target="_blank"
            >
              <!-- <v-row
                class="fill-height"
                align="end"
                justify="center"
              >
                <div class="display-1">
                  {{ item.name }}
                </div>
              </v-row> -->
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: [
    "partners",
  ],
  data: function() {
    return {
      
    };
  },
  created() {
    console.log("this.partners");
    console.log(this.partners);
    
  },
  methods: {
    
  }
};
</script>