<template>
    <div>
        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="status" class="col-sm-2 control-label">Variações</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control pull-left" style="width: 300px;" placeholder="Nome da variação" v-model="nameVariation">
                    <button type="button" class="btn btn-light btn-flat pull-left ml-2" :disabled="!nameVariation" @click="addField()"><i class="fa fa-plus"></i> Adicionar</button>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <label for="file_name" class="col-sm-2 control-label"></label>
            <div class="col-sm-10">
                <div class="form-group row">
                    <span v-if="listVariations.length == 0" style="margin-left: 15px; color: #ccc;">Nenhuma variação adicionada.</span>
                    <div class="col-sm-12" v-for="(item, index) in listVariations" :key="index">
                        <div class="row mx-0 my-1">
                            <label class="col-sm-3" style="border-bottom: 1px solid #ccc;">{{item.value}}</label>
                            <input type="hidden" name="variations[]" class="col-sm-3 form-control" v-model="item.value">
                            <div class="col-sm-1 px-0 d-flex align-items-center">
                                <button class="btn btn-light btn-sm ml-2" type="button" @click="deleteLine(index)" style="margin-bottom: 4px;">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import axios from "axios";

export default {
    props: [
        'variationsSelected',
    ],
    data() {        
        return {
            nameVariation: '',
            listVariations: []
        };
    },
    created() {
        if (this.variationsSelected){
            if (this.variationsSelected.variations.length > 0){
                this.variationsSelected.variations.forEach(value => {
                    this.listVariations.push({
                        "value": value.name,
                    });
                });
            }
        }
    },

    methods: {
        addField(){
            this.listVariations.push({
                "value": this.nameVariation,
            });
            this.nameVariation = '';
        },
        
        deleteLine(index) {
            this.listVariations.splice(index, 1);
        }
    },
        
}
</script>
<style lang="scss" scoped>

</style>