<template>
    <div class="box box-widget">
		<div class="box-header with-border">
			<h3 class="box-title">{{title}}</h3>
		</div>
		
		<form :class="formClass" :action="url" @submit="save" :method="method == 'GET' ? method : 'POST'" enctype="multipart/form-data" id="form">
			<input type="hidden" name="_token" :value="token" />
			<input type="hidden" name="_method" :value="method" />
			<div class="box-body">
				
				<slot></slot>

                <div class="form-group row">
                    <div class="col-sm-12">
                    <div class="row">
                        <label for="status" class="col-sm-2 control-label">Ativo</label>
                        <div class="col-sm-10">
                        <input type="checkbox" name="status" id="status" value="1" v-model="status">
                        </div>
                    </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-12">
                    <div class="row">
                        <label for="name" class="col-sm-2 control-label">Nome *</label>
                        <div class="col-sm-10">
                        <input type="text" name="name" class="form-control" id="name" maxlength="255" required v-model="name">
                        </div>
                    </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-12">
                    <div class="row">
                        <label for="state" class="col-sm-2 control-label">Tipo *</label>
                        <div class="col-sm-2">
                            <select class="form-control" name="type" id="type" v-model="type" required>
                                <option value="" selected>Selecione</option>
                                <option value="1">Texto</option>
                                <option value="2">Seleção</option>
                            </select>
                        </div>
                    </div>
                    </div>
                </div>

                <div v-if="type == 2">
                    <div class="form-group row">
                        <div class="col-sm-12">
                        <div class="row">
                            <label for="status" class="col-sm-2 control-label">Adicione um Item</label>
                            <div class="col-sm-10 d-flex align-items-center">
                                <input type="text" class="form-control pull-left" style="width: 300px;" placeholder="Item" v-model="nameItem">
                                <button type="button" class="btn btn-light btn-flat pull-left ml-2" :disabled="!nameItem" @click="addField()"><i class="fa fa-plus"></i> Adicionar</button>
                                <span v-if="validItems" style="margin-left: 15px; color: red;">Adicione ao menos um item.</span>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="file_name" class="col-sm-2 control-label"></label>
                        <div class="col-sm-10">
                            <div class="form-group row">
                                <span v-if="listItems.length == 0" style="margin-left: 15px; color: #ccc;">Nenhuma item adicionado.</span>
                                <div class="col-sm-12" v-for="(item, index) in listItems" :key="index">
                                    <div class="row mx-0 my-1">
                                        <label class="col-sm-3" style="border-bottom: 1px solid #ccc;">{{item.value}}</label>
                                        <input type="hidden" name="items[]" class="col-sm-3 form-control" v-model="item.value">
                                        <div class="col-sm-1 px-0 d-flex align-items-center">
                                            <button class="btn btn-light btn-sm ml-2" type="button" @click="deleteLine(index)" style="margin-bottom: 4px;">
                                                <i class="fa fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				
			</div>
			<div class="box-footer">
				<button type="submit" class="btn btn-success btn-flat ctaButton" :disabled="btnGravar.disabled"><i :class="btnGravar.icon"></i> {{ btnGravar.text }}</button>
				<a v-if="cancelUrl" :href="cancelUrl" class="btn btn-default btn-flat">Cancelar</a>
			</div>
			
		</form>
	</div>

</template>
<script>
import axios from "axios";

export default {
    props: [
        'edit',
        'title',
        'url',
        'cancelUrl',
        'token',
        'method',
        'formClass'
    ],
    data() {        
        return {
            name: '',
            status: 1,
            type: '',
            nameItem: '',
            listItems: [],
            validItems: false,
            btnGravar : {
                'icon' : 'fa fa-check',
                'text' : 'Gravar',
                'disabled' : false
            },
        };
    },
    created() {
        if (this.edit){
            this.status = this.edit.status;
            this.name = this.edit.name;
            this.type = this.edit.type;
            if (this.edit.values.length > 0){
                this.edit.values.forEach(value => {
                    this.listItems.push({
                        "value": value.name,
                    });
                });
            }
        }
    },

    methods: {
        addField(){
            this.listItems.push({
                "value": this.nameItem,
            });
            this.nameItem = '';
            this.validItems = false;
        },
        
        deleteLine(index) {
            this.listItems.splice(index, 1);
        },

        save(e){
            if (this.type == 2 && this.listItems.length == 0){
                this.validItems = true;
                e.preventDefault();
                return false;
            }

            this.btnGravar.icon = 'fa fa-spinner fa-pulse';
            this.btnGravar.text = 'Gravando';
            this.btnGravar.disabled = true;
        }
    },
        
}
</script>
<style lang="scss" scoped>

</style>