<template>
    <div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="state" class="col-sm-2 control-label">Produto *</label>
                <div class="col-sm-4">
                    <select class="form-control" name="product_id" id="product_id" v-model="product_id" @change="selectProduct(true)" required>
                        <option value="" selected>Selecione</option>
                        <option :value="item.id" v-for="(item, index) in products" :key="index">{{item.name}}</option>
                    </select>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="state" class="col-sm-2 control-label">Tipo da Embalagem *</label>
                <div class="col-sm-4">
                    <select class="form-control" name="package_id" id="package_id" v-model="package_id" required :disabled="!product_id">
                        <option value="" selected>Selecione</option>
                        <option :value="item.id" v-for="(item, index) in packages" :key="index">{{item.name}}</option>
                    </select>
                </div>
              </div>
            </div>
        </div>

    </div>
</template>
<script>
import axios from "axios";

export default {
    props: [
        'products',
        'edit',
    ],
    data() {        
        return {
            product_id: '',
            package_id: '',
            loadingPackages: false,
            packages: [],
        };
    },
    computed: {
      
    },
    created() {
        console.log("this.edit");
        console.log(this.edit);

        if (this.edit){
          this.product_id = this.edit.product_id;
          this.package_id = this.edit.package_id;
          this.selectProduct();
        }
    },

    methods: {
      selectProduct(){
        this.getPackages();
      },

      getPackages(){
        this.loadingPackages = true;
        this.packages = [];
        axios
          .get('/api/product-package?product_id='+this.product_id)
          .then((response) => {
            console.log("getPackages");
            console.log(response.data);
            this.packages = response.data;
            this.loadingPackages = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar as embalagens.");
            this.loadingPackages = false;
            console.log(err)
          })
      },
    },
        
}
</script>
<style lang="scss" scoped>

</style>