<template>
    <div>
      <div class="box_create" @click="openDialog()">Criar<br/> Conta</div>

      <!-- DIALOG CADASTRAR/EDITAR -->
      <v-dialog v-model="dialog" persistent max-width="1100">
        <v-card flat class="borda">
          <v-card-title class="ma-2 title_modal" align-items="center">
            Criar Conta
            <img src="img/logo-manzanas.png" class="logo_manzanas" alt="logo" height="55">
            <v-spacer></v-spacer>
            <v-btn icon text @click="close()">
              <v-icon>mdi-close-thick</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-stepper v-model="step" flat>
              
                <v-stepper-header flat>
                  <v-stepper-step :complete="step > 1" step="1" color="#D9043D"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="step > 2" step="2" color="#D9043D"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="step > 3" step="3" color="#D9043D"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="step > 4" step="4" color="#D9043D"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="5" color="#D9043D" icon="list"></v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>

                  <v-stepper-content step="1">
                    <v-form ref="formStep1" v-model="valid1" @submit.prevent="completeStep1()">
                      <div class="h5">Dados da Empresa</div>
                      
                      <v-row>
                        <v-col cols="12" class="mb-n5" :class="formData.company.type.value == 3 || formData.company.type.value == 4 ? 'col-md-6 col-sm-12' : 'col-md-12 col-sm-12'">
                          <v-combobox
                            label="Qual a sua atuação? *"
                            v-model="formData.company.type"
                            :items="types"
                            chips
                            outlined
                            color="#858585"
                            item-value="value"
                            item-text="text"
                            required
                            :rules="requiredRules"
                          >
                            <template v-slot:selection="{ attrs, item, select, selected }">
                              <v-chip
                                v-bind="attrs"
                                :input-value="selected"
                                @click="select"
                                color="#95D904"
                                style="color: #FFF !important"
                              >
                                <strong>{{ item.text }}</strong>                                
                              </v-chip>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="mb-n5" v-if="formData.company.type.value == 3 || formData.company.type.value == 4">
                          <v-select
                            label="Pólo de atuação *"
                            outlined
                            color="#858585"
                            v-model="formData.company.pole_id"
                            :items="poles"
                            item-value="id"
                            item-text="name"
                            required
                            :rules="requiredRules"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="mb-n5">
                          <v-text-field
                            label="CNPJ *"
                            outlined
                            color="#858585"
                            v-model="formData.company.cnpj"
                            v-mask="cnpj"
                            required
                            :rules="rulesAdicionalCNPJ"
                            @input="consultaReceita()"
                            @blur="consultaReceita()"
                            :disabled="loadingReceita"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="mb-n5">
                          <v-text-field
                            label="Nome comercial *"
                            outlined
                            color="#858585"
                            required
                            :rules="requiredRules"
                            v-model="formData.company.fantasy_name"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row justify="center" class="mb-2">
                        <v-btn
                          color="#95D904"
                          style="color: #FFF !important"
                          :disabled="loadingReceita"
                          type="submit"
                          >
                          AVANÇAR
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <v-form ref="formStep2" v-model="valid2" @submit.prevent="completeStep2()">
                      <div class="h5">Dados da Empresa</div>
                      
                      <v-row>
                        <v-col cols="12" sm="12" md="12" class="mb-n5">
                          <v-text-field
                            label="Razão Social *"
                            outlined
                            color="#858585"
                            v-model="formData.company.corporate_name"
                            required
                            :rules="requiredRules"
                            autofocus
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="mb-n5">
                          <v-text-field
                            label="Inscrição Estadual"
                            outlined
                            color="#858585"
                            v-model="formData.company.ie"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="mb-n5">
                          <v-text-field
                            label="Telefone *"
                            outlined
                            color="#858585"
                            v-model="formData.company.phone"
                            v-mask="telefone"
                            required
                            :rules="rulesAdicionalTelefone"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="mb-n5">
                          <v-text-field
                            label="E-mail *"
                            outlined
                            color="#858585"
                            v-model="formData.company.email"
                            required
                            :rules="rulesAdicionalEmail"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row justify="center" class="mb-2">
                        <v-btn
                          @click="step = 1"
                          color="secondary"
                          class="mr-2"
                          outlined
                          >
                          VOLTAR
                        </v-btn>
                        <v-btn
                          type="submit"
                          color="#95D904"
                          style="color: #FFF !important"
                          >
                          AVANÇAR
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-form ref="formStep3" v-model="valid3" @submit.prevent="completeStep3()">
                      <div class="h5">Endereço</div>
                      
                      <v-row>
                        <v-col cols="12" sm="12" md="4" class="mb-n5">
                          <v-text-field
                            label="CEP *"
                            outlined
                            color="#858585"
                            v-model="formData.address.cep"
                            @input="viaCep()"
                            v-mask="cep"
                            :disabled="loadingViaCep"
                            :loading="loadingViaCep"
                            required
                            :rules="requiredRules"
                            autofocus
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="8" class="mb-n5">
                          <v-text-field
                            label="Rua *"
                            outlined
                            color="#858585"
                            v-model="formData.address.street"
                            required
                            :rules="requiredRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" class="mb-n5">
                          <v-text-field
                            label="Número *"
                            outlined
                            color="#858585"
                            v-model="formData.address.number"
                            required
                            :rules="requiredRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="8" class="mb-n5">
                          <v-text-field
                            label="Complemento"
                            outlined
                            color="#858585"
                            v-model="formData.address.complement"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="mb-n5">
                          <v-autocomplete
                            label="País *"
                            outlined
                            color="#858585"
                            v-model="formData.address.country"
                            :items="countries"
                            :disabled="disableLocals"
                            no-data-text="Nenhum registro"
                            item-value="nome_pais"
                            item-text="nome_pais"
                            @change="selectedCountry"
                            required
                            :rules="requiredRules"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="mb-n5">
                          <v-autocomplete
                            label="Estado *"
                            outlined
                            color="#858585"
                            v-model="formData.address.state"
                            :items="states"
                            :disabled="disableLocals"
                            item-value="sigla"
                            item-text="sigla"
                            no-data-text="Nenhum registro"
                            @change="selectedState"
                            required
                            :rules="requiredRules"
                            v-if="brazil"
                          ></v-autocomplete>
                          <v-text-field
                            label="Estado *"
                            outlined
                            color="#858585"
                            v-model="formData.address.state"
                            required
                            :rules="requiredRules"
                            v-if="!brazil"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="mb-n5">
                          <v-autocomplete
                            label="Cidade *"
                            outlined
                            color="#858585"
                            v-model="formData.address.city"
                            :items="cities"
                            :disabled="disableLocals"
                            no-data-text="Selecine um Estado"
                            required
                            :rules="requiredRules"
                            v-if="brazil"
                          ></v-autocomplete>
                          <v-text-field
                            label="Cidade *"
                            outlined
                            color="#858585"
                            v-model="formData.address.city"
                            required
                            :rules="requiredRules"
                            v-if="!brazil"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="mb-n5">
                          <v-text-field
                            label="Bairro *"
                            outlined
                            color="#858585"
                            v-model="formData.address.district"
                            required
                            :rules="requiredRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row justify="center" class="mb-2">
                        <v-btn
                          @click="step = 2"
                          color="secondary"
                          class="mr-2"
                          outlined
                          >
                          VOLTAR
                        </v-btn>
                        <v-btn
                          type="submit"
                          color="#95D904"
                          style="color: #FFF !important"
                          >
                          AVANÇAR
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-stepper-content>

                  <v-stepper-content step="4">
                    <v-form ref="formStep4" v-model="valid4" @submit.prevent="save()">
                      <div class="h5">Contato Principal</div>
                      <div class="subtitle">Este contato irá receber por e-mail todos os dados referente aos acontecimentos da plataforma.</div>
                      
                      <v-row>
                        <v-col cols="12" sm="12" md="8" class="mb-n5">
                          <v-text-field
                            label="Nome *"
                            outlined
                            color="#858585"
                            v-model="formData.contact.name"
                            required
                            :rules="requiredRules"
                            autofocus
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" class="mb-n5">
                          <v-select
                            label="Cargo *"
                            outlined
                            color="#858585"
                            v-model="formData.contact.office_id"
                            :items="offices"
                            item-value="id"
                            item-text="name"
                            required
                            :rules="requiredRules"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="mb-n5">
                          <v-text-field
                            label="Telefone *"
                            outlined
                            color="#858585"
                            v-model="formData.contact.phone"
                            v-mask="telefone2"
                            required
                            :rules="rulesAdicionalTelefone"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="mb-n5">
                          <v-text-field
                            label="CPF *"
                            outlined
                            color="#858585"
                            v-model="formData.contact.cpf"
                            v-mask="cpf"
                            required
                            :rules="rulesAdicionalCPF"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="mb-n5">
                          <v-text-field
                            label="E-mail *"
                            outlined
                            color="#858585"
                            v-model="formData.contact.email"
                            required
                            :rules="rulesAdicionalEmail"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row justify="center" class="mb-2">
                        <v-btn
                          @click="step = 3"
                          color="secondary"
                          class="mr-2"
                          outlined
                          :disabled="loadingSave"
                          >
                          VOLTAR
                        </v-btn>
                        <v-btn
                          color="#95D904"
                          style="color: #FFF !important"
                          :loading="loadingSave"
                          :disabled="loadingSave"
                          type="submit"
                        >
                          <v-icon>mdi-rocket-launch</v-icon>
                          FINALIZAR
                        </v-btn>
                      </v-row>
                    </v-form>
                  </v-stepper-content>

                  <v-stepper-content step="5">
                    <v-row justify="center" class="mb-5 mt-5">
                      <v-col cols="12" sm="12" md="12" class="text-center py-0">
                        <v-icon style="font-size: 60px;">mdi-emoticon-outline</v-icon>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="text-center">
                        <label class="headline">Bem-vindo</label>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="text-center line_company">
                        <label class="headline font-weight-bold" v-if="formData.company.fantasy_name">{{formData.company.fantasy_name}}</label>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="text-center">
                        <p class="h5">Seu cadastro foi efetuado com <span class="font-weight-bold">sucesso</span> e nossa equipe irá validá-lo.</p>
                        <p class="h5">Em breve você receberá um e-mail com os <span class="font-weight-bold">dados de acesso</span> a plataforma.</p>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="text-center py-0" style="margin-top: 70px;">
                        <label class="h5">Dúvidas?</label>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="text-center py-0">
                        <p class="h6">Sem problemas, nossa equipe está a disposição em nossas redes sociais e e-mail.</p>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="text-center py-0">
                        <img src="/img/facebook.png" width="40">
                        <img src="/img/whatsapp.png" width="40" class="mx-5">
                        <img src="/img/email.png" width="40">
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- DIALOG LOADING -->
      <v-dialog
        v-model="dialogLoading"
        hide-overlay
        persistent
        width="450"
      >
        <v-card
          class="d-flex align-center py-5"
        >
          <v-card-text class="text-center">
            Aguarde, estamos buscando os dados do seu CNPJ :)
            <v-progress-linear
              indeterminate
              color="#D9043D"
              class="mb-0 mt-2"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
</template>
<script>
import axios from "axios";
import { cnpj, cpf } from "cpf-cnpj-validator";

export default {
    props: [
        
    ],
    data() {
        return {
            dialog: false,
            loadingSave: false,
            loadingViaCep: false,
            loadingReceita: false,
            dialogLoading: false,
            valid1: true,
            valid2: true,
            valid3: true,
            valid4: true,
            requiredRules: [v => !!v || "Campo Obrigatório."],
            formData: '',
            types: [
              { value: 1, text: 'Atacadista'},
              { value: 2, text: 'Supermercadista'},
              { value: 3, text: 'Embalador'},
              { value: 4, text: 'Produtor'},
              { value: 5, text: 'Representante'},
              { value: 6, text: 'Transportador'},
            ],
            offices: [],
            poles: [],
            chips: [],
            step: 1,
            countries: [],
            states: [],
            cities: [],
            brazil: true,
            cpf: "###.###.###-##",
            cnpj: "##.###.###/####-##",
            cep: "#####-###",
            disableLocals: false,
            rulesAdicionalCPF: [
              v => !!v || "Campo Obrigatório.",
              v => cpf.isValid(v) || "CPF inválido"
            ],
            rulesAdicionalCNPJ: [
              v => !!v || "Campo Obrigatório.",
              v => cnpj.isValid(v) || "CNPJ inválido"
            ],
            rulesAdicionalEmail: [
              v => !!v || "Campo Obrigatório.",
              v => /^(([^<>()\\.,;:ç~\s@"]+(\.[^<>()\\.,;:ç~\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v) || "E-mail inválido"
            ],
            rulesAdicionalTelefone: [
              v => !!v || "Campo Obrigatório.",
              v => (v && v.length >= 14) || "Telefone inválido"
            ],
        };
    },
    created() {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('register')){
        this.openDialog();
      }
      if (this.stateselected){
        this.state = this.stateselected;
      }
      if (this.cityselected){
        this.city = this.cityselected;
      }
      this.initFormData();
      this.getCountries();
      this.getStates();
      this.getOffices();
      this.getPoles();
    },

    computed: {
      telefone() {
        let telefone = "";
        if (this.formData.company.phone.length > 14) {
          telefone = "(##) # ####-####";
        } else {
          telefone = "(##) ####-####";
        }
        return telefone;
      },
      telefone2() {
        let telefone = "";
        if (this.formData.contact.phone.length > 14) {
          telefone = "(##) # ####-####";
        } else {
          telefone = "(##) ####-####";
        }
        return telefone;
      },
    },

    methods: {
        initFormData(){
          this.formData = {};
          this.formData = {
            company: {
              type: '',
              pole_id: '',
              fantasy_name: '',
              corporate_name: '',
              cnpj: '',
              ie: '',
              phone: '',
              email: '',
            },
            address: {
              cep: '',
              street: '',
              number: '',
              complement: '',
              country: 'Brasil',
              state: '',
              city: '',
              district: '',
            },
            contact: {
              office_id: '',
              name: '',
              email: '',
              phone: '',
              cpf: '',
              main: 1,
            }
          }
        },

        getCountries(){
          axios
            .get('/json/paises.json')
            .then((response) => {
              if (response.data){
                this.countries = response.data;
              }
            })
            .catch(error => console.log(error))
            .finally(() => {
                
            });
        },

        getStates(){
          axios
            .get('/json/cidades.json')
            .then((response) => {
              if (response.data.estados){
                this.states = response.data.estados;
                if (this.stateselected){
                  this.selectedState(true);
                }
              }
            })
            .catch(error => console.log(error))
            .finally(() => {
                
            });
        },

        selectedCountry(){
          if (this.formData.address.country == 'Brasil'){
            this.brazil = true;
          } else {
            this.brazil = false;
            this.formData.address.state = '';
            this.formData.address.city = '';
          }
        },

        selectedState(init){
            this.cities = [];
            if (init != true){
                this.formData.address.city = '';
            }
            this.states.forEach(value => {
                if (this.formData.address.state == value.sigla){
                    this.cities = value.cidades;
                }
            });
        },

        viaCep() {
          if (this.formData.address.cep) {
            if (this.formData.address.cep.length != 9) {
              this.brazil = true;
              this.disableLocals = false;
            } else {
              this.loadingViaCep = true;
              axios
                .get(
                  "https://viacep.com.br/ws/" +
                    this.formData.address.cep +
                    "/json"
                )
                .then(response => {
                  console.log("viaCep");
                  console.log(response);
                  this.loadingViaCep = false;
                  if (response.data.erro == true) {
                    this.cep_errado = "CEP não encontrado";
                    this.brazil = true;
                    this.disableLocals = false;
                  } else {
                    this.brazil = false;
                    this.disableLocals = true;

                    this.formData.address.street = response.data.logradouro;
                    this.formData.address.city = response.data.localidade;
                    this.formData.address.district = response.data.bairro;
                    this.formData.address.state = response.data.uf;
                    this.formData.address.country = "Brasil";
                    this.cep_errado = "";
                    this.selectedCountry();
                    this.selectedState(true);
                  }
                });
            }
          } else {
            this.brazil = true;
            this.disableLocals = false;
          }
        },

        consultaReceita() {
          if (this.formData.company.cnpj) {
            if (this.formData.company.cnpj.length == 18 && !this.loadingReceita) {
              this.loadingReceita = true;
              this.dialogLoading = true;
              axios
                .get("/api/company/consulta-receita?cnpj=" + this.formData.company.cnpj)
                .then(response => {
                  console.log("consultaReceita");
                  console.log(response.data);
                  this.loadingReceita = false;
                  this.dialogLoading = false;
                  if (response.data.error) {
                    this.$toast.error(response.data.error);
                  } else {
                    this.formData.company.fantasy_name = response.data.fantasia;
                    this.formData.company.corporate_name = response.data.nome;
                    this.formData.company.phone = response.data.telefone;

                    if (response.data.cep){
                      this.formData.address.cep = response.data.cep;
                      this.formData.address.number = response.data.numero;
                      this.viaCep();
                    }
                  }
                });
            }
          }
        },

        openDialog(){
          this.dialog = true;
        },

        getOffices(){
          axios
            .get('/api/office')
            .then((response) => {
              // console.log("getOffices");
              // console.log(response.data);
              this.offices = response.data;
            })
            .catch(err => {
              console.log(err)
            })
        },
        
        getPoles(){
          axios
            .get('/api/pole')
            .then((response) => {
              // console.log("getPoles");
              // console.log(response.data);
              this.poles = response.data;
            })
            .catch(err => {
              console.log(err)
            })
        },

        close() {
          this.dialog = false;
          if (this.$refs.formStep1) {
              this.$refs.formStep1.resetValidation();
          }
          if (this.$refs.formStep2) {
              this.$refs.formStep2.resetValidation();
          }
          if (this.$refs.formStep3) {
              this.$refs.formStep3.resetValidation();
          }
          if (this.$refs.formStep4) {
              this.$refs.formStep4.resetValidation();
          }
          this.step = 1;
          this.brazil = true;
          this.cities = [];
          this.initFormData();
        },

        removeChip (item) {
          this.chips.splice(this.chips.indexOf(item), 1)
          this.chips = [...this.chips]
        },

        completeStep1 (){
          if (this.$refs.formStep1.validate()) {
            this.step = 2;
          }
        },

        completeStep2 (){
          if (this.$refs.formStep2.validate()) {
            this.step = 3;
          }
        },

        completeStep3 (){
          if (this.$refs.formStep3.validate()) {
            this.step = 4;
          }
        },

        save (){
          if (this.$refs.formStep4.validate()) {
            this.loadingSave = true;
            
            this.formData.company.type = this.formData.company.type.value;
            if (this.formData.company.type != 3 && this.formData.company.type != 4){
              console.log("zzzzzzzzzzzzzzzzzzzzzz");
              this.formData.company.pole_id = '';
            }

            axios
              .post('/api/company', this.formData)
              .then((response) => {
                console.log("save");
                console.log(response.data);
                if (!response.data.errors) {
                  this.step = 5;
                } else {
                  this.$toast.warning(response.data.errors);
                }
              })
              .catch(err => {
                this.$toast.error("Ocorreu um erro.");
                console.log(err)
              })
              .finally(() => {
                  this.loadingSave = false;
              });
          }
        }
    },
        
}
</script>
<style lang="scss" scoped>
  .box_create {
    cursor: pointer;
  }
  .v-stepper, .v-stepper__header {
    box-shadow: none !important;
  }
  .v-stepper__content {
    padding-top: 0 !important;
  }
  .v-card__text {
    padding-top: 0 !important;
  }
  .line_company{
    background-color: #23232F;
    color: #95D904;
  }
  .logo_manzanas {
    @media (max-width: 768px) {
      height: 30px;
      margin-left: 10px;
    }
  }
  .title_modal{
    @media (max-width: 768px) {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
</style>