/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Popper = require('popper.js');

window.$ = window.jQuery = require('jquery');

require('admin-lte');
require("bootstrap");

window.Vue = require("vue");

import Vue from "vue";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import money from "v-money";
Vue.use(money, { precision: 4 });

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-selectt', vSelect)

import Vuetify from 'vuetify';
Vue.use(Vuetify);

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast, {
  position: 'bottom',
  duration: 6000,
})

import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.component("content-header", require("./cms/ContentHeader.vue").default);
Vue.component("data-table", require("./cms/DataTable.vue").default);
Vue.component("tabs", require("./cms/Tabs.vue").default);
Vue.component("ui-form", require("./cms/UIForm.vue").default);
Vue.component("ui-select", require("./cms/UISelect.vue").default);
Vue.component("ui-textarea", require("./cms/UITextarea.vue").default);
Vue.component("ui-money", require("./cms/UIMoney.vue").default);
Vue.component("ui-phone", require("./cms/UIPhone.vue").default);
Vue.component("ui-mask-input", require("./cms/UIMaskInput.vue").default);
Vue.component("alert", require("./cms/Alert.vue").default);
Vue.component("checkboxes", require("./cms/Checkboxes.vue").default);
Vue.component("radios", require("./cms/Radios.vue").default);
Vue.component("dropdown-list", require("./cms/DropdownList.vue").default);
Vue.component("dropdown-events", require("./cms/DropdownEvents.vue").default);
Vue.component("cidade-bairro", require("./cms/Cidade-bairro.vue").default);
Vue.component("cities-select", require("./cms/CitiesSelect.vue").default);
Vue.component("city-uf-select", require("./cms/CityUFSelect.vue").default);
Vue.component("product-form", require("./cms/ProductForm.vue").default);
Vue.component("type-variations", require("./cms/TypeVariations.vue").default);
Vue.component("product-custom-fields", require("./cms/ProductCustomFields.vue").default);
Vue.component("data-table-companies", require("./cms/DataTableCompanies.vue").default);
Vue.component("data-table-offers", require("./cms/DataTableOffers.vue").default);
Vue.component("data-table-orders", require("./cms/DataTableOrders.vue").default);
Vue.component("data-table-products", require("./front/DataTableProducts.vue").default);
Vue.component("data-table-proposals", require("./cms/DataTableProposals.vue").default);
Vue.component("chat-cms", require("./cms/ChatCMS.vue").default);
Vue.component("form-manzanas-table", require("./cms/FormManzanasTable.vue").default);
Vue.component("select-product-package", require("./cms/SelectProductPackage.vue").default);
Vue.component("details-company-product", require("./cms/DetailsCompanyProduct.vue").default);
Vue.component("details-order", require("./cms/DetailsOrder.vue").default);
Vue.component("details-proposal", require("./cms/DetailsProposal.vue").default);
Vue.component("select-type-company", require("./cms/SelectTypeCompany.vue").default);

Vue.component("the-gallery", require("./front/TheGallery.vue").default);
Vue.component("create-account", require("./front/CreateAccount.vue").default);
Vue.component("product-form-web", require("./front/ProductFormWeb.vue").default);
Vue.component("product-form-search", require("./front/ProductFormSearch.vue").default);
Vue.component("product-results", require("./front/ProductResults.vue").default);
Vue.component("chat-proposal", require("./front/ChatProposal.vue").default);
Vue.component("carousel-partners", require("./front/CarouselPartners.vue").default);
Vue.component("config-company", require("./front/ConfigCompany.vue").default);
Vue.component("link-offer-product", require("./front/LinkOfferProduct.vue").default);
Vue.component("forgot-password", require("./front/ForgotPassword.vue").default);

const app = new Vue({
  el: "#app",
  vuetify: new Vuetify(),
});
