<template>
    <div>
        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="state" class="col-sm-2 control-label">Estado *</label>
                <div class="col-sm-4">
                    <v-selectt
                        label="sigla"
                        :reduce="label => label.sigla.toString()"
                        :options="states"
                        v-model="state"
                        @input="selectedState"
                        >
                        <template #search="{attributes, events}">
                            <input
                            class="vs__search"
                            :required="!state"
                            v-bind="attributes"
                            v-on="events"
                            />
                        </template>
                    </v-selectt>
                    <input type="hidden" v-model="state" name="state"/>
                </div>
              </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="state" class="col-sm-2 control-label">Cidade *</label>
                <div class="col-sm-4">
                    <v-selectt
                        :options="cities"
                        v-model="city"
                        >
                        <template #search="{attributes, events}">
                            <input
                            class="vs__search"
                            :required="!city"
                            v-bind="attributes"
                            v-on="events"
                            />
                        </template>
                    </v-selectt>
                    <input type="hidden" v-model="city" name="city"/>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
    props: [
        'stateselected',
        'cityselected',
    ],
    data() {        
        return {
            states: [],
            state: '',
            cities: [],
            city: '',
        };
    },
    created() {
        if (this.stateselected){
            this.state = this.stateselected;
        }
        if (this.cityselected){
            this.city = this.cityselected;
        }
        this.getStates();
    },

    methods: {
        getStates(){
            axios
                .get('/json/cidades.json')
                .then((response) => {
                    console.log("getStates");
                    console.log(response.data);
                    if (response.data.estados){
                        this.states = response.data.estados;
                        if (this.stateselected){
                            this.selectedState(true);
                        }
                    }
                })
                .catch(error => console.log(error))
                .finally(() => {
                    
                });
        },
        selectedState(init){
            this.cities = [];
            if (init != true){
                this.city = '';
            }
            this.states.forEach(value => {
                if (this.state == value.sigla){
                    this.cities = value.cidades;
                }
            });
        },
    },
        
}
</script>
<style lang="scss" scoped>

</style>