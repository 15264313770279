<template>
  <div>
    <v-card class="borda" flat min-height="400" :loading="loadingDetails">
      <div v-if="details">
        <v-col cols="12" sm="12" md="12" class="">
          <v-col sm="12" md="12" class="px-0">
            <span class="font-weight-bold">{{details.product.name}}</span>
          </v-col>
          <v-col sm="12" md="12" class="px-0">
            <v-list-item-title class="headline" style="white-space: normal;">
              <span v-if="details.type">{{details.type.name}}</span>
              <span v-if="details.category">{{details.category.name}}</span>
              <span v-if="details.caliber">Cal.{{details.caliber.value}}</span>
              <span v-if="details.weight">{{details.weight.value}}kg</span>
              <span v-if="details.brand">- {{details.brand.name}}</span>
            </v-list-item-title>
          </v-col>
        </v-col>

        <!-- CUSTOM FIELDS -->
        <v-col cols="12" sm="12" md="12" class="" v-for="(item, index) in details.custom_fields" :key="index">
          <div class="headline" style="">{{item.field.name}}:</div>
          <div class="subtitle" v-if="item.field.type == 1">{{item.value}}</div>
          <div class="subtitle" v-if="item.field.type == 2">
            <span v-for="(item2, index2) in item.field.values" :key="index2">
              <span v-if="item2.id == item.value">{{item2.name}}</span>
            </span>
            </div>
        </v-col>

        <!-- GALERIA DE IMAGENS -->
        <v-container fluid class="pt-0">
          <v-row>
            <v-col
              v-for="(item, index) in details.images"
              :key="index"
              class="d-flex child-flex"
              cols="2"
            >
              <v-card flat tile class="d-flex">
                <img
                  :src="item.url"
                  class="rounded_img"
                  style="cursor: pointer;"
                  @click="modalPreview(item.url)"
                >
                <!-- <v-img
                  :src="item.url"
                  :lazy-src="item.url"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  @click="modalPreview(item.url)"
                  style="cursor: pointer;"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img> -->
              </v-card>
            </v-col>
              <v-col
              v-if="details.images.length == 0"
              cols="12"
            >
              Nenhuma imagem na galeria.
              </v-col>
          </v-row>
        </v-container>

        <!-- GALERIA DE VÍDEOS -->
        <v-container fluid class="pt-0">
          <v-row>
            <v-col
              v-for="(item, index) in details.videos"
              :key="index"
              class="d-flex child-flex"
              cols="2"
            >
              <v-card flat tile class="d-flex">
                <v-img
                  :src="item.url"
                  :lazy-src="item.url"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  @click="modalPreviewVideo(item.url)"
                  style="cursor: pointer;"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-icon>mdi-play-box-outline</v-icon>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </v-col>
            <v-col
              v-if="details.videos.length == 0"
              cols="12"
            >
              Nenhuma vídeo na galeria.
            </v-col>
          </v-row>
        </v-container>

        <hr/>

        <v-row class="mt-2">
          <v-col sm="4" md="4" class="px-0 py-0 text-center">
            <div style="color:#4C4C4F;font-size:15px;">Disponível</div>
            <div class="headline" style="">{{details.available}}</div>
          </v-col>
          <v-col sm="4" md="4" class="px-0 py-0 text-center">
            <div style="color:#4C4C4F;font-size:15px;">Projeção na classificação em {{details.date_validate}}</div>
            <div class="headline" style="">{{details.projection}}</div>
          </v-col>
          <v-col sm="4" md="4" class="px-0 py-0 text-center">
            <div style="color:#4C4C4F;font-size:15px;">Estoque</div>
            <div class="headline" style="">{{details.available + details.projection}}</div>
          </v-col>
          <v-col sm="12" md="12" class="px-0 text-center" v-if="details.days_load">
            Carrega em <span class="font-weight-bold">{{details.days_load}} dia(s)</span>
          </v-col>
        </v-row>
        
        <v-row class="mt-5">
          <v-col sm="6" md="6" class="px-5 py-3 text-right" v-if="details.min_atacado != '0,00'" :class="details.min_varejo == '0,00' ? 'col-md-12 col-sm-12' : 'col-md-6 col-sm-12'">
            <div style="color:#4C4C4F;font-size:15px;">Valor Atacado</div>
            <div class="headline">
              <span class="" style="color:#5FA348;font-size:15px;">R$</span>
              <span class="headline font-weight-bold" style="color:#5FA348;">{{details.min_atacado}}</span>
              <div style="margin-top: -3px;"><small class="text-help" style="color:#4C4C4F;font-size:15px;">por caixa</small></div>
            </div>
          </v-col>
          <v-col sm="6" md="6" class="px-5 py-3 text-left" v-if="details.min_varejo != '0,00'" :class="details.min_atacado == '0,00' ? 'col-md-12 col-sm-12' : 'col-md-6 col-sm-12'">
            <div style="color:#4C4C4F;font-size:15px;">Valor Varejo</div>
            <div class="headline">
              <span class="" style="color:#5FA348;font-size:15px;">R$</span>
              <span class="headline font-weight-bold" style="color:#5FA348;">{{details.min_varejo}}</span>
              <div style="margin-top: -3px;"><small class="text-help" style="color:#4C4C4F;font-size:15px;">por caixa</small></div>
            </div>
          </v-col>
          <v-col class="px-0 py-0 text-center" md="12" v-if="details.min_atacado == '0,00' && details.min_varejo == '0,00'">
            <span class="text-help" style="color:#4C4C4F;font-size:14px;font-style:italic;">Preço sob consulta</span>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- DIALOG PREVIEW IMAGENS -->
    <v-dialog v-model="dialog_preview" max-width="600">
      <v-card class="borda" flat min-height="400">
        <v-card-title class="ma-2">
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_preview = false; imageModalPreview = ''">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" sm="12" md="12" class="pa-0">
            <v-img
              :src="imageModalPreview"
              :alt="imageModalPreview"
              :lazy-src="imageModalPreview"
              aspect-ratio="1"
              class=""
              max-width="100%"
              contain
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG PREVIEW VIDEO -->
    <v-dialog v-model="dialog_video" max-width="600">
      <v-card class="borda" flat min-height="400">
        <v-card-title class="ma-2">
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_video = false; videoModalPreview = ''">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" sm="12" md="12" class="pa-0 d-flex justify-content-center">
            <video width="500" controls v-if="videoModalPreview">
              <source :src="videoModalPreview">
            </video>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}
.v-middle {
  vertical-align: middle !important;
}
</style>

<script>
import axios from "axios";

export default {
  props: [
    "product",
    "user",
  ],
  data: function() {
    return {
      details: '',
      loadingDetails: false,
      dialog_preview: false,
      dialog_video: false,
      imageModalPreview: '',
      videoModalPreview: '',
    };
  },
  created() {
    if (this.product) {
      this.getCompanyProduct(this.product);
    }
  },
  methods: {

    getCompanyProduct(id) {
      this.loadingDetails = true;
      this.details = '';

      axios.get('/api/company-product/' + id)
      .then(response => {
        console.log('getCompanyProduct');
        console.log(response.data);
        if (response.data){
          this.details = response.data;
        }
      })
      .catch(e => {
        var msg = 'Houve um erro ao buscar os detalhes da oferta! Por favor, tente novamente.'
        this.$swal("Oops!", msg, "error")
        console.error(e)
      })
      .finally(() => {
        this.loadingDetails = false;
      })
    },

    modalPreview: function(image) {
      this.imageModalPreview = image;
      this.dialog_preview = true;
    },

    modalPreviewVideo: function(video) {
      this.videoModalPreview = video;
      this.dialog_video = true;
    },
  }
};
</script>