<template>
    <div>
        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="status" class="col-sm-2 control-label">Ativo</label>
                <div class="col-sm-10">
                  <input type="checkbox" name="status" id="status" value="1" checked v-model="form.status" :disabled="inactivateStatus">
                  <span v-if="inactivateStatus" class="badge badge-warning ml-2">
                    Atenção! Já existe outro formulário ativo para o produto selecionado.
                  </span>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="name" class="col-sm-2 control-label">Nome *</label>
                <div class="col-sm-10">
                  <input type="text" name="name" class="form-control" id="name" maxlength="255" required v-model="form.name">
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="state" class="col-sm-2 control-label">Produto *</label>
                <div class="col-sm-4">
                    <select class="form-control" name="product_id" id="product_id" v-model="form.product_id" @change="selectProduct(true)" required>
                        <option value="" selected>Selecione</option>
                        <option :value="item.id" v-for="(item, index) in products" :key="index">{{item.name}}</option>
                    </select>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="type" class="col-sm-2 control-label">Tipo</label>
                <div class="col-sm-1 d-flex align-items-center justify-content-between pr-0">
                    <input class="pull-left" type="checkbox" name="type" id="type" value="1" v-model="form.type">
                    <label class="control-label pull-right" style="font-size: 12px;">Título:</label>
                </div>
                <div class="col-sm-3">
                    <input type="text" name="type_title" class="form-control pull-left" maxlength="255" placeholder="Título do campo" :disabled="!form.type" v-model="form.type_title" :required="form.type">
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                    <input class="pull-left" type="checkbox" name="type_required" id="type_required" value="1" v-model="form.type_required" :disabled="!form.type"> 
                    <label for="type_required" class="control-label ml-2 mb-0" style="font-size: 14px;">Obrigatório</label>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="category" class="col-sm-2 control-label">Categoria</label>
                <div class="col-sm-1 d-flex align-items-center justify-content-between pr-0">
                    <input class="pull-left" type="checkbox" name="category" id="category" value="1" v-model="form.category">
                    <label class="control-label pull-right" style="font-size: 12px;">Título:</label>
                </div>
                <div class="col-sm-3">
                    <input type="text" name="category_title" class="form-control pull-left" maxlength="255" placeholder="Título do campo" :disabled="!form.category" v-model="form.category_title" :required="form.category">
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                    <input class="pull-left" type="checkbox" name="category_required" id="category_required" value="1" v-model="form.category_required" :disabled="!form.category"> 
                    <label for="category_required" class="control-label ml-2 mb-0" style="font-size: 14px;">Obrigatório</label>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="caliber" class="col-sm-2 control-label">Calibre</label>
                <div class="col-sm-1 d-flex align-items-center justify-content-between pr-0">
                    <input class="pull-left" type="checkbox" name="caliber" id="caliber" value="1" v-model="form.caliber">
                    <label class="control-label pull-right" style="font-size: 12px;">Título:</label>
                </div>
                <div class="col-sm-3">
                    <input type="text" name="caliber_title" class="form-control pull-left" maxlength="255" placeholder="Título do campo" :disabled="!form.caliber" v-model="form.caliber_title" :required="form.caliber">
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                    <input class="pull-left" type="checkbox" name="caliber_required" id="caliber_required" value="1" v-model="form.caliber_required" :disabled="!form.caliber"> 
                    <label for="caliber_required" class="control-label ml-2 mb-0" style="font-size: 14px;">Obrigatório</label>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="picture" class="col-sm-2 control-label">Foto</label>
                <div class="col-sm-1 d-flex align-items-center justify-content-between pr-0">
                    <input class="pull-left" type="checkbox" name="picture" id="picture" value="1" v-model="form.picture">
                    <label class="control-label pull-right" style="font-size: 12px;">Título:</label>
                </div>
                <div class="col-sm-3">
                    <input type="text" name="picture_title" class="form-control pull-left" maxlength="255" placeholder="Título do campo" :disabled="!form.picture" v-model="form.picture_title" :required="form.picture">
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                    <input class="pull-left" type="checkbox" name="picture_required" id="picture_required" value="1" v-model="form.picture_required" :disabled="!form.picture"> 
                    <label for="picture_required" class="control-label ml-2 mb-0" style="font-size: 14px;">Obrigatório</label>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="gallery" class="col-sm-2 control-label">Galeria</label>
                <div class="col-sm-1 d-flex align-items-center justify-content-between pr-0">
                    <input class="pull-left" type="checkbox" name="gallery" id="gallery" value="1" v-model="form.gallery">
                    <label class="control-label pull-right" style="font-size: 12px;">Título:</label>
                </div>
                <div class="col-sm-3">
                    <input type="text" name="gallery_title" class="form-control pull-left" maxlength="255" placeholder="Título do campo" :disabled="!form.gallery" v-model="form.gallery_title" :required="form.gallery">
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                    <input class="pull-left" type="checkbox" name="gallery_required" id="gallery_required" value="1" v-model="form.gallery_required" :disabled="!form.gallery"> 
                    <label for="gallery_required" class="control-label ml-2 mb-0" style="font-size: 14px;">Obrigatório</label>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="video" class="col-sm-2 control-label">Vídeo</label>
                <div class="col-sm-1 d-flex align-items-center justify-content-between pr-0">
                    <input class="pull-left" type="checkbox" name="video" id="video" value="1" v-model="form.video">
                    <label class="control-label pull-right" style="font-size: 12px;">Título:</label>
                </div>
                <div class="col-sm-3">
                    <input type="text" name="video_title" class="form-control pull-left" maxlength="255" placeholder="Título do campo" :disabled="!form.video" v-model="form.video_title" :required="form.video">
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                    <input class="pull-left" type="checkbox" name="video_required" id="video_required" value="1" v-model="form.video_required" :disabled="!form.video"> 
                    <label for="video_required" class="control-label ml-2 mb-0" style="font-size: 14px;">Obrigatório</label>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="lote" class="col-sm-2 control-label">Lote</label>
                <div class="col-sm-1 d-flex align-items-center justify-content-between pr-0">
                    <input class="pull-left" type="checkbox" name="lote" id="lote" value="1" v-model="form.lote">
                    <label class="control-label pull-right" style="font-size: 12px;">Título:</label>
                </div>
                <div class="col-sm-3">
                    <input type="text" name="lote_title" class="form-control pull-left" maxlength="255" placeholder="Título do campo" :disabled="!form.lote" v-model="form.lote_title" :required="form.lote">
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                    <input class="pull-left" type="checkbox" name="lote_required" id="lote_required" value="1" v-model="form.lote_required" :disabled="!form.lote"> 
                    <label for="lote_required" class="control-label ml-2 mb-0" style="font-size: 14px;">Obrigatório</label>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="brand" class="col-sm-2 control-label">Marca</label>
                <div class="col-sm-1 d-flex align-items-center justify-content-between pr-0">
                    <input class="pull-left" type="checkbox" name="brand" id="brand" value="1" v-model="form.brand">
                    <label class="control-label pull-right" style="font-size: 12px;">Título:</label>
                </div>
                <div class="col-sm-3">
                    <input type="text" name="brand_title" class="form-control pull-left" maxlength="255" placeholder="Título do campo" :disabled="!form.brand" v-model="form.brand_title" :required="form.brand">
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                    <input class="pull-left" type="checkbox" name="brand_required" id="brand_required" value="1" v-model="form.brand_required" :disabled="!form.brand"> 
                    <label for="brand_required" class="control-label ml-2 mb-0" style="font-size: 14px;">Obrigatório</label>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="state" class="col-sm-2 control-label">Campos Personalizados:</label>
                <div class="col-sm-10">
                    <!-- <v-selectt
                        multiple
                        label="name"
                        :reduce="label => label.id.toString()"
                        :options="customFields"
                        v-model="selectedFields"
                        >
                    </v-selectt>
                    <input type="hidden" v-model="selectedFields" name="customFields"/> -->
                </div>
              </div>
            </div>
        </div>

        <input name="customFields" type="hidden" :value="JSON.stringify(customFieldss)">
        <div class="form-group row" v-for="(item, index) in customFieldss" :key="index">
            <div class="col-sm-12">
              <div class="row">
                <input name="custom_fields_ids[]" type="hidden" v-model="item.id" v-if="item.check">
                <input name="custom_fields_indexes[]" type="hidden" :value="index">
                <label :for="item.id" class="col-sm-2 control-label">{{item.name}}</label>
                <div class="col-sm-1 d-flex align-items-center justify-content-between pr-0">
                    <input class="pull-left" name="custom_fields_checks[]" type="checkbox" :id="item.id" value="1" v-model="item.check">
                    <label class="control-label pull-right" style="font-size: 12px;">Título:</label>
                </div>
                <div class="col-sm-3">
                    <input type="text" name="custom_fields_titles[]" class="form-control pull-left" maxlength="255" placeholder="Título do campo" :disabled="!item.check" v-model="item.title" :required="item.check">
                </div>
                <div class="col-sm-1 d-flex align-items-center">
                    <input class="pull-left" type="checkbox" name="custom_fields_requireds[]" :id="index" value="1" v-model="item.required" :disabled="!item.check"> 
                    <label :for="index" class="control-label ml-2 mb-0" style="font-size: 14px;">Obrigatório</label>
                </div>
              </div>
            </div>
        </div>

    </div>
</template>
<script>
import axios from "axios";

export default {
    props: [
        'products',
        'customFields',
        'edit',
        'items',
    ],
    data() {        
        return {
            selectedFields: '',
            customFieldss: [],
            inactivateStatus: false,
            form: {
                name: '',
                product_id: '',
                type: 0,
                type_title: 'Tipo',
                type_required: 0,
                category: 0,
                category_title: 'Categoria',
                category_required: 0,
                caliber: 0,
                caliber_title: 'Calibre',
                caliber_required: 0,
                picture: 0,
                picture_title: 'Foto',
                picture_required: 0,
                gallery: 0,
                gallery_title: 'Galeria',
                gallery_required: 0,
                video: 0,
                video_title: 'Vídeo',
                video_required: 0,
                lote: 0,
                lote_title: 'Lote',
                lote_required: 0,
                brand: 0,
                brand_title: 'Marca',
                brand_required: 0,
                status: 1,
            }
        };
    },
    computed: {
      
    },
    created() {
        console.log("this.edit");
        console.log(this.edit);

        if (this.edit){
          this.form = this.edit;
          this.selectProduct();
        }

        if (this.customFields){
          this.customFields.forEach(value => {
              value.check = 0;
              value.title = value.name;
              value.required = 0;

              if (this.edit){
                if (this.edit.custom_fields){
                  this.edit.custom_fields.forEach(value2 => {
                    if (value.id == value2.field_id){
                      value.check = 1;
                      value.title = value2.title;
                      value.required = value2.required;
                    }
                  });
                }
              }
            this.customFieldss.push(value);
          });
        }
    },

    methods: {
        selectProduct(select){
            if (!this.edit){
              this.inactivateStatus = false;
              this.form.status = 1;
              if (this.form.product_id && this.items.data.length > 0){
                this.items.data.forEach(value => {
                  if (value.product_id.id == this.form.product_id && value.status.text == "Ativo"){
                    this.inactivateStatus = true;
                    this.form.status = 0;
                  }
                });
              }
            } else {
              if (select){
                this.inactivateStatus = false;
                this.form.status = 1;
              }
              if (this.items.length > 0){
                this.items.forEach(value => {
                  if (value.product_id == this.form.product_id && value.status == 1 && value.id != this.form.id){
                    this.inactivateStatus = true;
                    this.form.status = 0;
                  }
                });
              }
            }
        },
    },
        
}
</script>
<style lang="scss" scoped>

</style>