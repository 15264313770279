<template>
  <div>
    <div class="box box-widget">
      <div class="box-header">
        <div class="row">
          <div class="col-md-8 col-sm-6">
            <h3 class="box-title">{{title}}</h3>
          </div>
          <div v-if="busca != 'false'" class="col-md-4 col-sm-6">
            <form method="GET" class="form-horizontal" :action="url">
              <div class="input-group">
                <input
                  type="text"
                  name="busca"
                  class="form-control"
                  placeholder="Buscar por"
                  :value="busca"
                />
                <span class="input-group-btn">
                  <button type="submit" class="btn btn-primary btn-flat">
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    :href="url"
                    class="btn btn-default btn-flat"
                    data-toggle="tooltip"
                    title="Limpar Busca"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="box-body">
        <slot></slot>

        <v-data-table
          no-data-text="Nenhuma oferta cadastrada"
          hide-default-footer
          calculate-widths
          :headers="header"
          :items="items.data"
          :items-per-page="items_per_page"
          :search="search"
          no-results-text="Nenhum registro encontrado"
          class="elevation-0 table-striped"
          loading-text="Carregando itens..."
          :header-props="{ sortByText: 'Ordenar por' }"
        >
          <template v-slot:item.company_id="{ item }">
            <v-chip small class="" text-color="white" color="#0d6efd">{{item.company_id.text}}</v-chip>
          </template>
          <template v-slot:item.user_id="{ item }">
            <v-chip small class="" text-color="white" color="#17a2b8">{{item.user_id.text}}</v-chip>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip small class="" text-color="white" :color="item.status.color">{{item.status.text}}</v-chip>
          </template>
          <template v-slot:item.seller_id="{ item }">
            <v-chip small class="" :color="item.seller_id.status">{{item.seller_id.text}}</v-chip>
          </template>
          <template v-slot:item.acao="{ item }">
            <button
              class="btn btn-flat btn-info"
              data-toggle="tooltip"
              title="Detalhes da oferta"
              @click="detailOffer(item)"
              style="color:#fff !important;"
            >
              <i class="fa fa-eye"></i>
            </button>
            <a
              class="btn btn-flat btn-warning"
              data-toggle="tooltip"
              title="Link da oferta"
              :href="'/public-offer/' + item.offer_hash"
              target="_blank"
              style="color:#fff !important;"
              v-if="item.offer_hash"
            >
              <i class="fa fa-link"></i>
            </a>
            <button
              class="btn btn-flat btn-primary"
              data-toggle="tooltip"
              title="Aprovar oferta"
              @click="approveOffer(item)"
              v-if="item.status.text != 'Aprovada'"
              style="color:#fff !important;"
              :class="'approve_'+item.id"
            >
              <i class="fa fa-check"></i>
            </button>
            <button
              class="btn btn-flat btn-danger"
              data-toggle="tooltip"
              title="Reprovar oferta"
              @click="disapproveOffer(item)"
              v-if="item.status.text != 'Reprovada'"
              style="color:#fff !important;"
              :class="'disapprove_'+item.id"
            >
              <i class="fa fa-ban"></i>
            </button>
          </template>

          <template v-slot:footer>
            <v-row justify="center" class="mb-2">
              <v-btn
                v-if="items_per_page > 10"
                @click="items_per_page -= 10"
                text
              >Mostrar menos</v-btn>
              <v-btn
                v-if="items.data.length > items_per_page"
                @click="items_per_page += 10"
                text
              >Carregar mais</v-btn>
            </v-row>
          </template>
        </v-data-table>

        <!-- <table id="example1" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th v-for="(title, index) in titles" :key="index">{{title}}</th>
                <th v-if="actions != 'false'">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items.data" :key="index">
                <td v-for="(field, index) in item" :key="index" class="v-middle">
                  <span v-if="field === null">{{field}}</span>
                  <span v-else-if="!field.type && !field.color">{{field}}</span>

                  <span v-else-if="field.type == 'img'">
                    <img :src="field.src" style="max-width:150px; max-height:150px;" />
                  </span>

                  <span
                    v-else-if="field.type == 'badge'"
                    class="badge"
                    :class="'badge-' + field.status"
                  >{{ field.text }}</span>

                  <v-chip v-else-if="field.color" small :color="field.color" text-color="white">{{field.text}}</v-chip>

                </td>
                <td v-if="!item.actions && actions != 'false'" class="v-middle">
                  <button
                    class="btn btn-flat btn-info"
                    data-toggle="tooltip"
                    title="Detalhes da oferta"
                    @click="detailOffer(item)"
                    style="color:#fff !important;"
                  >
                    <i class="fa fa-eye"></i>
                  </button>
                  <button
                    class="btn btn-flat btn-primary"
                    data-toggle="tooltip"
                    title="Aprovar oferta"
                    @click="approveOffer(item)"
                    v-if="item.status.text != 'Aprovada'"
                    style="color:#fff !important;"
                    :class="'approve_'+item.id"
                  >
                    <i class="fa fa-check"></i>
                  </button>
                  <button
                    class="btn btn-flat btn-danger"
                    data-toggle="tooltip"
                    title="Reprovar oferta"
                    @click="disapproveOffer(item)"
                    v-if="item.status.text != 'Reprovada'"
                    style="color:#fff !important;"
                    :class="'disapprove_'+item.id"
                  >
                    <i class="fa fa-ban"></i>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot v-if="!notDeletable">
              <tr>
                <td :colspan="(titles.length + 2)" align="left" vertical-align="center">
                  <slot name="pagination"></slot>
                </td>
              </tr>
            </tfoot>
          </table>

        <div v-if="items.data.length == 0" class="callout callout-default">
          <h4>Nenhum registro encontrado!</h4>
        </div> -->
      </div>
    </div>

    <v-dialog v-model="dialogOffer" max-width="700">
      <v-card class="borda" flat min-height="400">
        <v-card-title class="ma-2" v-if="offer">
          {{offer.company_id.text}}
          <v-chip small class="ml-2" color="#17a2b8" text-color="white">Oferta {{offer.id}}</v-chip>
          <v-spacer></v-spacer>
          <span style="font-size: 13px;" v-if="offer.date_validate_offer">
            Disponível até
            <span class="font-weight-bold">{{offer.date_validate_offer}}</span>
          </span>
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialogOffer = false;">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="offer">

          <details-company-product :product="offer.id" v-if="dialogOffer"></details-company-product>

          <v-row class="mt-2">
            <v-col sm="12" md="12" class="px-0 text-center">
              <button
                class="btn btn-flat btn-primary"
                data-toggle="tooltip"
                title="Aprovar oferta"
                @click="approveOffer(offer)"
                v-if="offer.status.text != 'Aprovada'"
                style="color:#fff !important;"
                :class="'approve_'+offer.id"
              >
                <i class="fa fa-check"></i> Aprovar
              </button>
              <button
                class="btn btn-flat btn-danger ml-2"
                data-toggle="tooltip"
                title="Reprovar oferta"
                @click="disapproveOffer(offer, index)"
                v-if="offer.status.text != 'Reprovada'"
                style="color:#fff !important;"
                :class="'disapprove_'+offer.id"
              >
                <i class="fa fa-ban"></i> Reprovar
              </button>
              <button
                class="btn btn-flat btn-warning ml-2"
                data-toggle="tooltip"
                title="Gerar link"
                @click="generateLink(offer)"
                v-if="!offer.offer_hash"
                style="color:#fff !important;"
                :class="'link_'+offer.id"
              >
                <i class="fa fa-link"></i> Gerar link
              </button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}
.v-middle {
  vertical-align: middle !important;
}
</style>

<script>
import axios from "axios";
import io from 'socket.io-client';
import moment from 'moment';

export default {
  props: [
    "title",
    "titles",
    "items",
    "busca",
    "url",
    "token",
    "formClass",
    "notDeletable",
    "actions"
  ],
  data: function() {
    var tunnel = 'https://tunnel.d3t.com.br';
    var api = 'https://manzanas.app/api';

    if (window.location.hostname == 'localhost'){
      tunnel = 'http://172.20.0.1:5555';
      api = 'http://172.24.0.1/api';
    }
    return {
      deleteItems: [],
      check_delete: [],
      offer: '',
      loadingOffer: false,
      dialogOffer: false,
      socket: io(tunnel, {
        query: `domain=${api}&authorization=${this.token}`
      }),
      header: [
        { text: "#", align: "left", value: "id", },
        { text: "Empresa", align: "left", value: "company_id", },
        { text: "Vendedor", align: "left", value: "user_id", },
        { text: "Produto", align: "left", value: "product_id", },
        { text: "Cadastrado em", align: "left", value: "created_at", },
        { text: "Status", align: "left", value: "status", },
        { text: "Ações", align: "left", sortable: false, value: "acao", },
      ],
      items_per_page: 10,
      search: '',
    };
  },
  created() {
    if (!this.items.data) {
      this.items.data = this.items;
    }
  },
  methods: {
    detailOffer: function(item){
      // this.getCompanyProduct(id);
      this.dialogOffer = true;
      this.offer = [];
      this.offer = item;
    },

    getCompanyProduct(id) {
        this.loadingOffer = true;
        this.offer = '';

        axios.get('/api/company-product/' + id)
        .then(response => {
          console.log('getCompanyProduct');
          console.log(response.data);
          if (response.data){
            this.offer = response.data;
          }
        })
        .catch(e => {
          var msg = 'Houve um erro ao buscar os detalhes da oferta! Por favor, tente novamente.'
          this.$swal("Oops!", msg, "error")
          console.error(e)
        })
        .finally(() => {
          this.loadingOffer = false;
        })
      },

    approveOffer: function(item) {
      this.offer = item;
      $('.approve_'+item.id).html('<div class="spinner-grow spinner-grow-sm" role="status"></div>');
      $('.approve_'+item.id).prop( "disabled", true );
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente aprovar esta oferta?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, aprovar!",
        cancelButtonText: "Não!",
      }).then(result => {
        if (result.value) {
          var dados = {
            'id' : item.id
          }
          axios
            .post("/api/company-product/approve", dados)
            .then((response) => {
              console.log("approve");
              console.log(response.data);
              if (!response.data.errors) {
                this.$toast.success("Aprovado!");
                //window.location.href = "/cms/offers";
                //window.location.reload(true);
                this.enviar_mensagem ('approve');
              } else {
                this.$toast.warning(response.data.errors);
              }
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao aprovar a oferta.");
              console.log(err)
            })
            .finally(() => {
              $('.approve_'+item.id).html('<i class="fa fa-check"></i>');
              $('.approve_'+item.id).prop( "disabled", false );
            });
        } else {
          $('.approve_'+item.id).html('<i class="fa fa-check"></i>');
          $('.approve_'+item.id).prop( "disabled", false );
        }
      });
    },

    disapproveOffer: function(item) {
      this.offer = item;
      $('.disapprove_'+item.id).html('<div class="spinner-grow spinner-grow-sm" role="status"></div>');
      $('.disapprove_'+item.id).prop( "disabled", true );
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente reprovar esta proposta?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, reprovar!",
        cancelButtonText: "Não!",
        input: 'text',
        inputPlaceholder: 'Informe o motivo',
        inputValidator: (value) => {
          if (!value) {
            return 'Informe o motivo!'
          }
        }
      }).then(result => {
        if (result.value) {
          var dados = {
            'id' : item.id,
            'reason': result.value
          }
          axios
            .post("/api/company-product/disapprove", dados)
            .then((response) => {
              console.log("disapprove");
              console.log(response.data);
              if (!response.data.errors) {
                this.$toast.success("Reprovado!");
                //window.location.reload(true);
                this.enviar_mensagem ('disapprove', result.value);
              } else {
                this.$toast.warning(response.data.errors);
              }
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao reprovar o cadastro.");
              console.log(err)
            })
            .finally(() => {
              $('.disapprove_'+item.id).html('<i class="fa fa-ban"></i>');
              $('.disapprove_'+item.id).prop( "disabled", false );
            });
        } else {
          $('.disapprove_'+item.id).html('<i class="fa fa-ban"></i>');
          $('.disapprove_'+item.id).prop( "disabled", false );
        }
      });
    },

    generateLink: function(item) {
      $('.link_'+item.id).html('<div class="spinner-grow spinner-grow-sm" role="status"></div>');
      $('.link_'+item.id).prop( "disabled", true );
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente gerar o link desta oferta?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, gerar!",
        cancelButtonText: "Não!",
      }).then(result => {
        if (result.value) {
          var dados = {
            'id' : item.id
          }
          axios
            .post("/api/company-product/generate-link", dados)
            .then((response) => {
              console.log("generateLink");
              console.log(response.data);
              if (!response.data.errors) {
                this.$toast.success("Link gerado!");
                window.location.reload(true);
              } else {
                this.$toast.warning(response.data.errors);
              }
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao gerar o link da oferta.");
              console.log(err)
            })
            .finally(() => {
              $('.link_'+item.id).html('<i class="fa fa-check"></i>');
              $('.link_'+item.id).prop( "disabled", false );
            });
        } else {
          $('.link_'+item.id).html('<i class="fa fa-check"></i>');
          $('.link_'+item.id).prop( "disabled", false );
        }
      });
    },

    enviar_mensagem (status, reason = null) {
      if (status == 'approve'){
        var message = '<span class="title_chat_proposal">********** OFERTA **********</span><br/>Sua solicitação de cadastro da oferta <span class="font-weight-bold">'+this.offer.id+'</span> foi APROVADA! :)<br/>';
      } else if (status == 'disapprove'){
        var message = '<span class="title_chat_proposal">********** OFERTA **********</span><br/>Sua solicitação de cadastro da oferta <span class="font-weight-bold">'+this.offer.id+'</span> foi REPROVADA! :(<br/> Motivo: '+reason;
      }

      const data = {
        method: 'POST',
        endpoint: '/chat/new_message',
        
        content: {
          send_id: 1,
          receive_id: this.offer.user_id.id,
          archive: null,
          message: message,
          created_at: moment().format('YYYY-MM-DD HH:mm'),
        }
      }
      console.log("data");
      console.log(data);

      this.socket.emit('event', data);

      window.location.reload(true);
    },
    
  }
};
</script>