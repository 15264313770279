<template>
  <div>
    <div class="box box-widget" style="box-shadow:none;">
      <div class="box-header">
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <v-progress-circular
              :width="2"
              :size="25"
              class="mt-1 mr-5 pull-right"
              indeterminate
              color="primary"
              v-if="loadingFilter"
            ></v-progress-circular>
          </div>
          <div v-if="busca != 'false'" class="col-md-8 col-sm-12">
            <form ref="formFilters" method="GET" class="form-horizontal row" :action="url">
              <div class="input-group">
                <v-radio-group name="filter" v-if="company" v-model="filterList" row class="p-0 mr-5" @change="changeFilter()" style="margin-top: -7px;" :readonly="loadingFilter">
                  <v-radio label="Propostas de Compra" value="compra" class="pr-5"></v-radio>
                  <v-radio label="Propostas de Venda" value="venda"></v-radio>
                  <v-badge color="#95d904" left dot v-if="proposalsOpened" offset-x="-8" offset-y="3"></v-badge>
                </v-radio-group>
                <input
                  type="text"
                  name="busca"
                  class="form-control"
                  placeholder="Buscar por"
                  v-model="search"
                />
                <span class="input-group-btn">
                  <button type="submit" class="btn btn-primary btn-flat">
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    :href="url"
                    class="btn btn-default btn-flat"
                    data-toggle="tooltip"
                    title="Limpar Busca"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="box-body">
        <slot></slot>

        <v-data-table
          no-data-text="Nenhuma proposta cadastrada"
          hide-default-footer
          calculate-widths
          :headers="header"
          :items="items.data"
          :items-per-page="items_per_page"
          :search="search"
          no-results-text="Nenhum registro encontrado"
          class="elevation-0 table-striped"
          loading-text="Carregando itens..."
          :header-props="{ sortByText: 'Ordenar por' }"
        >
          <template v-slot:item.status="{ item }">
            <v-chip small class="" text-color="white" :color="item.status.color">{{item.status.text}}</v-chip>
          </template>
          <template v-slot:item.seller_id="{ item }">
            <v-chip small class="" :color="item.seller_id.status">{{item.seller_id.text}}</v-chip>
          </template>
          <template v-slot:item.acao="{ item }">
          <button
              class="btn btn-flat btn-info"
              data-toggle="tooltip"
              title="Detalhes da proposta"
              @click="detailProposal(item)"
              style="color:#fff !important;"
            >
              <i class="fa fa-eye"></i>
            </button>
          </template>

          <template v-slot:footer>
            <v-row justify="center" class="mb-2">
              <v-btn
                v-if="items_per_page > 10"
                @click="items_per_page -= 10"
                text
              >Mostrar menos</v-btn>
              <v-btn
                v-if="items.data.length > items_per_page"
                @click="items_per_page += 10"
                text
              >Carregar mais</v-btn>
            </v-row>
          </template>
        </v-data-table>
      </div>
    </div>

    <v-dialog v-model="dialogProposal" max-width="800">
      <v-card class="borda" flat min-height="400">
        <v-card-title class="ma-2" v-if="proposal">
          <div style="flex: 0 1 auto;" class="mr-3">
            Proposta {{proposal.id}}
          </div>
          <div style="flex: 0 1 auto;" class="mr-3" v-if="proposal.status">
            <v-chip small :color="proposal.status.color" text-color="white">{{proposal.status.text}}</v-chip>
          </div>
          <v-spacer></v-spacer>
          <span style="font-size: 13px;" v-if="proposal.created_at">
            Criada em
            <span class="font-weight-bold">{{proposal.created_at}}</span>
          </span>
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialogProposal = false;">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="proposal">

          <details-proposal :proposal="proposal.id" v-if="dialogProposal" :user="user" :company="company" @attProposalChild="attProposal"></details-proposal>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}
.v-middle {
  vertical-align: middle !important;
}
</style>

<script>
import axios from "axios";

export default {
  props: [
    "title",
    "titles",
    "items",
    "busca",
    "url",
    "token",
    "formClass",
    "notDeletable",
    "actions",
    "filter",
    "user",
    "company",
  ],
  data: function() {
    return {
      header: [
        { text: "#", align: "left", value: "id", },
        { text: this.filter == 'venda' ? 'Comprador' : 'Vendedor', align: "left", value: "seller_id", },
        { text: "Produtos", align: "left", value: "products", },
        { text: "Valor Total", align: "left", value: "value", },
        { text: "Total Caixas", align: "left", value: "boxes", },
        { text: "Data/Hora", align: "left", value: "created_at", },
        { text: "Status", align: "left", value: "status", },
        { text: "Ações", align: "left", sortable: false, value: "acao", },
      ],
      items_per_page: 10,
      search: '',
      loadingFilter: false,
      filterList: 'compra',
      proposalFilter: 0,
      loadingProposal: false,
      dialogProposal: false,
      proposal: [],
      proposalsOpened: 0,
    };
  },
  created() {
    if (!this.items.data) {
      this.items.data = this.items;
    }
    if (this.filter){
      this.filterList = this.filter;
    }
    if (this.company){
      this.getProposalsOpened();
    }
  },
  methods: {
    changeFilter(){
      this.loadingFilter = true;
      var self = this;
      setTimeout(function(){
        self.$refs.formFilters.submit();
      }, 100)
    },

    detailProposal: function(item){
      this.dialogProposal = true;
      this.proposal = '';
      this.proposal = item;
    },

    attProposal: function(response) {
      if (response.proposal){
        this.items.data.forEach(element => {
          if (element.id == response.proposal.id){
            element.status = response.proposal.status;
          }
        });
      }
    },

    getProposalsOpened: function(){
      axios
        .get('/api/proposal/opened/'+this.company)
        .then((response) => {
          console.log("getProposalsOpened");
          console.log(response.data);
          this.proposalsOpened = response.data;
        })
        .catch(err => {
          this.$toast.error("Ocorreu um erro ao buscar as propostas abertas.");
          console.log(err)
        })
    },
    
  }
};
</script>