<template>
  <div>
    <v-card flat max-width="1000">
      <v-card flat class="borda">
        <v-card-text>
          <v-row align-content="center">
            <span class="mb-3" v-if="formData.pole && poles">
              <v-icon color="#D9043D">mdi-map-marker-radius-outline</v-icon>
              {{textFilter(poles, formData.pole)}}</span>
          </v-row>
          <v-row>
            <v-chip class="mr-1 mb-1 px-3" color="green" text-color="white" v-if="formData.product_id && products">{{textFilter(products, formData.product_id)}}</v-chip>
            <v-chip class="mr-1 mb-1 px-3" color="green" text-color="white" v-if="formData.type_id && types">{{textFilter(types, formData.type_id)}}</v-chip>
            <v-chip class="mr-1 mb-1 px-3" color="green" text-color="white" v-if="formData.category_id && categories">{{textFilter(categories, formData.category_id)}}</v-chip>
            <v-chip class="mr-1 mb-1 px-3" color="green" text-color="white" v-if="formData.caliber_id && calibers">{{textFilter(calibers, formData.caliber_id)}}</v-chip>
            <v-chip class="mr-1 mb-1 px-3" color="green" text-color="white" v-if="formData.weight_id && calibers">{{textFilter(weights, formData.weight_id)}}</v-chip>
            <v-chip class="mr-1 mb-1 px-3" color="green" text-color="white" v-if="formData.brand_id && brands">{{textFilter(brands, formData.brand_id)}}</v-chip>
            <v-chip class="mr-1 mb-1 px-3" color="green" text-color="white" v-if="formData.company && companies">
              <i class="fa fa-building-o mr-2"></i>
              {{textFilter(companies, formData.company)}}
            </v-chip>
          </v-row>
          <v-row align-content="center">
            <span align-content="center" style="cursor:pointer;" class="mt-3 mb-3" @click="expandFilters()" v-if="!offers">
              <v-icon>mdi-filter-outline</v-icon>
              Editar filtros
              <v-icon v-if="!panelFilter">mdi-menu-down</v-icon>
              <v-icon v-if="panelFilter">mdi-menu-up</v-icon>
            </span>
            <v-spacer></v-spacer>
            <v-checkbox v-model="multipleProposals" color="primary" hide-details class="mt-0 pt-0" v-if="filteredResults.length > 0">
              <template v-slot:label>
                <div>
                  Múltiplas propostas
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <i class="fa fa-info-circle ml-1" v-on="on"></i>
                    </template>
                    <span>Selecione está opção para comprar vários itens do mesmo fornecedor</span>
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
            <v-spacer></v-spacer>
            <v-switch v-model="myProducts" label="Meus produtos" inset color="success" hide-details class="mt-0 pt-0"></v-switch>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- EDITAR FILTROS -->
      <v-expand-transition>
        <div v-show="panelFilter" class="mb-4">
          <v-card flat class="px-0">
            <v-card-text class="px-0">
              <v-form ref="form" :action="url" v-model="valid" v-on:submit="search($event)" method="POST" enctype="multipart/form-data" id="form">
                <input type="hidden" name="_token" :value="token" />
                <input type="hidden" name="_method" value="POST" />
                <v-row justify="center">
                  <v-col cols="12" sm="12" md="4" class="mb-n5">
                    <span class="font-weight-bold black--text">Produto *</span>
                    <v-select
                      no-data-text="Nenhum produto cadastrado"
                      label="Selecione"
                      solo
                      dense
                      flat
                      outlined
                      color="#858585"
                      v-model="formData.product_id"
                      :items="products"
                      item-value="id"
                      item-text="name"
                      @change="selectProduct()"
                      :loading="loadingProducts"
                      :disabled="loadingProducts"
                      required
                      :rules="requiredRules"
                      name="product_id"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" class="mb-n5">
                    <span class="font-weight-bold black--text">Categoria</span>
                    <v-select
                      no-data-text="Nenhuma categoria cadastrada"
                      label="Selecione"
                      solo
                      dense
                      flat
                      outlined
                      color="#858585"
                      v-model="formData.category_id"
                      :items="categories"
                      item-value="id"
                      item-text="name"
                      :loading="loadingCategories"
                      :disabled="loadingCategories || !formData.product_id"
                      name="category_id"
                      clearable
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" class="mb-n5">
                    <span class="font-weight-bold black--text">Tipo</span>
                    <v-select
                      no-data-text="Nenhum tipo cadastrado"
                      label="Selecione"
                      solo
                      dense
                      flat
                      outlined
                      color="#858585"
                      v-model="formData.type_id"
                      :items="types"
                      item-value="id"
                      item-text="name"
                      :loading="loadingTypes"
                      :disabled="loadingTypes || !formData.product_id"
                      name="type_id"
                      clearable
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" class="mb-n5">
                    <span class="font-weight-bold black--text">Calibre</span>
                    <v-select
                      no-data-text="Nenhuma calibre cadastrado"
                      label="Selecione"
                      solo
                      dense
                      flat
                      outlined
                      color="#858585"
                      v-model="formData.caliber_id"
                      :items="calibers"
                      item-value="id"
                      item-text="value"
                      :loading="loadingCalibers"
                      :disabled="loadingCalibers || !formData.product_id"
                      name="caliber_id"
                      clearable
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="mb-n5">
                    <span class="font-weight-bold black--text">Peso</span>
                    <v-select
                      no-data-text="Nenhum peso cadastrado"
                      label="Selecione"
                      solo
                      dense
                      flat
                      outlined
                      color="#858585"
                      v-model="formData.weight_id"
                      :items="weights"
                      item-value="id"
                      item-text="value"
                      :loading="loadingWeights"
                      :disabled="loadingWeights || !formData.product_id"
                      name="weight_id"
                      clearable
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" class="mb-n5">
                    <span class="font-weight-bold black--text">Marca</span>
                    <v-select
                      no-data-text="Nenhuma marca cadastrada"
                      label="Selecione"
                      solo
                      dense
                      flat
                      outlined
                      color="#858585"
                      v-model="formData.brand_id"
                      :items="brands"
                      item-value="id"
                      item-text="name"
                      :loading="loadingBrands"
                      :disabled="loadingBrands || !formData.product_id"
                      name="brand_id"
                      clearable
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="8" md="4" class="mb-n5">
                    <span class="font-weight-bold black--text">Pólo</span>
                    <v-select
                      no-data-text="Nenhum pólo cadastrado"
                      label="Selecione"
                      solo
                      dense
                      flat
                      outlined
                      color="#858585"
                      v-model="formData.pole"
                      :items="poles"
                      item-value="id"
                      item-text="name"
                      :loading="loadingPoles"
                      :disabled="loadingPoles"
                      name="pole"
                      clearable
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="8" md="4" class="mb-n5">
                    <span class="font-weight-bold black--text">Parceiro / Produtor</span>
                    <v-autocomplete
                      no-data-text="Nenhuma empresa cadastrada"
                      label="Selecione"
                      solo
                      dense
                      flat
                      outlined
                      color="#858585"
                      v-model="formData.company"
                      :items="companies"
                      item-value="id"
                      item-text="name"
                      :loading="loadingCompanies"
                      :disabled="loadingCompanies"
                      name="company"
                      clearable
                    >
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{data.item.name}}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-chip x-small class="font-weight-bold" text-color="white" :color="data.item.type.color">{{data.item.type.text}}</v-chip>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-col cols="12" sm="12" md="12" class="">
                  <v-row justify="center" class="mb-2 mt-5">
                    <v-btn
                      color="#95D904"
                      style="color: #FFF !important"
                      type="submit"
                      :loading="loadingSearch"
                      :disabled="loadingSearch"
                      >
                      BUSCAR
                    </v-btn>
                  </v-row>
                </v-col>
              </v-form>
            </v-card-text>
          </v-card>
        </div>
      </v-expand-transition>

      <!-- LISTAGEM DE RESULTADOS -->
      <div class="mt-3 mb-3 text-center">
        <span class="font-weight-bold" v-if="filteredResults.length > 0">{{filteredResults.length}} {{filteredResults.length == 1 ? 'resultado' : 'resultados'}}</span>
        <span class="font-weight-bold" v-if="filteredResults.length == 0">Nenhum resultado encontrado</span>
      </div>
      <div class="pull-left w-100 position-relative" v-for="(item, index) in filteredResults" :key="index">
        <v-card class="mx-auto mb-2 card_product_result" outlined @click="dialogDetails(item)" v-bind:class="{ 'special_offer': item.special_offer }">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mt-1">
                <!-- <span style="font-size: .75rem!important; font-weight: 500;">{{item.id}}</span> -->
                <v-chip class="mt-0 mb-1 pull-right" small color="#d9043d" text-color="white" v-if="item.special_offer">
                  <i class="fa fa-star mr-1"></i>
                  Oferta especial
                </v-chip>
              </div>
              <v-list-item-title class="headline mb-1" style="white-space: normal;display: inline-flex;">
                <v-avatar tile size="100" class="mr-2" v-if="item.picture" style="border-radius: 10px !important;">
                  <v-img
                    :src="item.picture"
                    :lazy-src="item.picture"
                    aspect-ratio="1"
                    class="grey lighten-2 mt-2 mb-1 rounded-3"
                    >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
                <div>
                  <div style="font-size: .75rem!important; font-weight: 500;">{{item.id}}</div>
                  <span v-if="item.type">{{item.type.name}}</span>
                  <span v-if="item.category">{{item.category.name}}</span>
                  <span v-if="item.caliber">Cal.{{item.caliber.value}}</span>
                  <span v-if="item.weight">{{item.weight.value}}kg</span>
                  <span v-if="item.brand">- {{item.brand.name}}</span>

                  <div class="title_items mt-2" style="color:#4C4C4F;font-size:14px;line-height: 12px;">Disponível:</div>
                  <div class="headline" style="line-height: 27px;">{{item.available}}</div>
                </div>
              </v-list-item-title>
              <!-- <v-col sm="6" md="2" class="px-0 py-0">
                <div class="title_items" style="color:#4C4C4F;font-size:14px;">Disponível:</div>
                <div class="headline" style="">{{item.available}}</div>
              </v-col> -->
              <v-row cols="12" class="ma-0">
                <v-col sm="4" md="4" class="px-0 py-0">
                  <div class="title_items" style="color:#4C4C4F;font-size:14px;line-height: 12px;">Projeção na classificação em <span class="font-weight-bold">{{item.date_validate}}</span></div>
                  <div class="headline" style="">{{item.projection}}</div>
                </v-col>
                <v-col sm="8" md="8" class="px-0 py-0 text-right">
                  <v-row class="mr-0">
                    <v-col class="px-0 py-0" v-if="item.min_atacado != '0,00'" :class="item.min_varejo == '0,00' ? 'col-md-12 col-sm-12' : 'col-md-6 col-sm-12'">
                      <div class="title_items" style="color:#4C4C4F;font-size:14px;">Valor Atacado</div>
                      <div>
                        <span class="" style="color:#5FA348;font-size:15px;">R$</span>
                        <span class="headline font-weight-bold" style="color:#5FA348;">{{item.min_atacado}}</span>
                        <div style="margin-top:-7px;"><small class="text-help" style="color:#4C4C4F;font-size:12px;">por caixa</small></div>
                      </div>
                    </v-col>
                    <v-col class="px-0 py-0" v-if="item.min_varejo != '0,00'" :class="item.min_atacado == '0,00' ? 'col-md-12 col-sm-12' : 'col-md-6 col-sm-12'">
                      <div class="title_items" style="color:#4C4C4F;font-size:14px;">Valor Varejo</div>
                      <div>
                        <span class="" style="color:#5FA348;font-size:15px;">R$</span>
                        <span class="headline font-weight-bold" style="color:#5FA348;">{{item.min_varejo}}</span>
                        <div style="margin-top:-7px;"><small class="text-help" style="color:#4C4C4F;font-size:12px;">por caixa</small></div>
                      </div>
                    </v-col>
                    <v-col class="px-0 py-0" md="12" v-if="item.min_atacado == '0,00' && item.min_varejo == '0,00'">
                      <span class="text-help" style="color:#4C4C4F;font-size:14px;font-style:italic;">Preço sob consulta</span>
                    </v-col>
                  </v-row>
                  <v-row v-if="item.proposal" class="mt-2 mr-0">
                    <v-col sm="12" md="12" class="px-0 py-0">
                      <div style="color:#4C4C4F;font-size:14px;">Minha Proposta</div>
                      <div>
                        <span class="" style="color:#0057FF;font-size:15px;">R$</span>
                        <!-- <span class="headline font-weight-bold" style="color:#0057FF;">{{item.proposal.value}}</span> -->
                        <span class="headline font-weight-bold value_proposal" style="color:#0057FF;">{{item.proposal.value | currency('', 2, { thousandsSeparator: '.', decimalSeparator: ',' })}}</span>
                        <div style="margin-top:-7px;"><small class="text-help" style="color:#4C4C4F;font-size:12px;">por caixa</small></div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-col sm="12" md="12" class="pt-0">
            <v-chip class="mt-1" small color="#d9043d" outlined v-if="item.company_id == company">
              Meu produto
            </v-chip>
            <v-chip class="mt-1" small color="primary" text-color="white">
              <i class="fa fa-building-o mr-2"></i>
              {{item.company.fantasy_name}}
            </v-chip>
            <v-chip class="mt-1" small color="teal" text-color="white" v-if="item.company.pole_id">
              <i class="fa fa-map-marker mr-2"></i>
              {{item.company.pole.name}}
            </v-chip>
            <v-chip class="mt-1" small color="warning" text-color="white" v-if="offers">
              Disponível até {{item.date_validate_offer}}
            </v-chip>
          </v-col>
        </v-card>
        <v-slide-y-transition>
          <v-checkbox :disabled="listProposals.length > 0 && listProposals[0].company_id != item.company_id" v-if="multipleProposals && item.company_id != company" v-model="item.multipleProposal" color="primary" hide-details class="mt-0 pt-0 pull-right" style="position: absolute; top: -5px; right: 3px;" @change="changeMultipleProposal(item)"></v-checkbox>
        </v-slide-y-transition>
      </div>

    </v-card>

    <!-- BOTÃO CESTA DE PROPOSTAS -->
    <v-slide-x-transition>
      <div style="position:fixed;bottom:20px;right:105px;z-index: 9999;" v-if="multipleProposals">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-badge :content="listProposals.length" :value="listProposals.length" color="#D9043D" overlap left bordered>
                <v-btn color="#95d904" elevation="2" fab x-large dark v-on="on" @click="showListProposals()">
                  <v-icon>mdi-basket-fill</v-icon>
                </v-btn>
              </v-badge>
            </template>
            <span>Lista de propostas</span>
          </v-tooltip>
      </div>
    </v-slide-x-transition>

    <!-- DIALOG DETALHES -->
    <v-dialog v-model="dialog_details" persistent max-width="800">
      <v-card class="borda" flat min-height="400">
        <v-card-title class="ma-2" v-if="details">
          <v-avatar tile size="50" class="mr-3" color="grey" style="border-radius: 10px !important;">
            <img
              :src="details.picture"
              :alt="details.picture"
              v-if="details.picture"
              @click="modalPreview(details.picture)"
              style="cursor: pointer;"
            >
            <v-icon dark v-else>
              mdi-food-apple
            </v-icon>
          </v-avatar>
          <div>
            <v-list-item-title style="font-size: 20px;font-weight:500;margin-top: 6px;">Item {{details.id}}</v-list-item-title>
            <v-list-item-subtitle class="font-weight-light" style="font-size:12px;">{{details.created_at}}</v-list-item-subtitle>
          </div>
          <!-- Item {{details.id}} -->
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_details = false; details = ''">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="details">
          <v-col cols="12" sm="12" md="6" class="">
            <v-card class="mx-auto" v-if="details.company">
              <v-card-text class="pa-3">
                <p class="headline text--primary mb-1">{{details.company.fantasy_name}}</p>
                <div class="text--primary">
                  CNPJ: {{details.company.cnpj}}<br>
                  Telefone: {{details.company.phone}}<br>
                  E-mail: {{details.company.email}}<br>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- CUSTOM FIELDS -->
          <v-col cols="12" sm="12" md="12" class="" v-for="(item, index) in details.custom_fields" :key="index">
            <div class="headline" style="">{{item.field.name}}</div>
            <div class="subtitle" v-if="item.field.type == 1">{{item.value}}</div>
            <div class="subtitle" v-if="item.field.type == 2">
              <span v-for="(item2, index2) in item.field.values" :key="index2">
                <span v-if="item2.id == item.value">{{item2.name}}</span>
              </span>
            </div>
          </v-col>

          <!-- GALERIA DE IMAGENS -->
          <!-- <v-container fluid class="pt-0">
            <v-row>
              <v-col v-for="(item, index) in details.images" :key="index" sm="12" md="2">
                <v-card flat tile class="d-flex">
                  <img
                    :src="item.url"
                    class="rounded_img"
                    style="cursor: pointer;max-height: 400px;"
                    @click="modalPreview(item.url)"
                  >
                </v-card>
              </v-col>
            </v-row>
          </v-container> -->

          <v-row justify="center" class="mb-0" v-if="details.images.length > 0">
            <v-carousel
              hide-delimiters
              height="200"
              v-bind:class="{ 'col-6': !$vuetify.breakpoint.mobile }"
              >
              <v-carousel-item
                v-for="(item,i) in details.images"
                :key="i"
                @click="modalPreview(item.url);"
                reverse-transition="fade-transition"
                transition="fade-transition"
                class="text-center"
              >
                <img
                  :src="item.url"
                  class="rounded_img"
                  style="cursor: pointer; height: 185px;"
                >
              </v-carousel-item>
            </v-carousel>
          </v-row>

          <!-- GALERIA DE VÍDEOS -->
          <!-- <v-container fluid class="pt-0">
            <v-row>
              <v-col v-for="(item, index) in details.videos" :key="index" sm="3" md="2">
                <v-card flat tile class="d-flex">
                  <v-img
                    :src="item.url"
                    :lazy-src="item.url"
                    aspect-ratio="1"
                    class="grey lighten-2 rounded_img"
                    @click="modalPreviewVideo(item.url)"
                    style="cursor: pointer;"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0 rounded_img"
                        align="center"
                        justify="center"
                      >
                        <v-icon>mdi-play-box-outline</v-icon>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-container> -->
          <v-row justify="center" class="mb-0" v-if="details.videos.length > 0">
            <v-col v-for="(item, index) in details.videos" :key="index" sm="3" md="2">
              <v-card flat tile class="d-flex align-center justify-center">
                <v-icon size="120" @click="modalPreviewVideo(item.url)">mdi-video-box</v-icon>
                <!-- <video width="100%" preload="metadata" @click="modalPreviewVideo(item.url)">
                  <source :src="item.url">
                </video> -->
              </v-card>
            </v-col>
          </v-row>

          <hr/>

          <v-row class="mt-2 mr-0 ml-0">
            <v-col sm="4" md="4" class="px-0 py-0 text-center">
              <div style="color:#4C4C4F;font-size:15px;">Disponível:</div>
              <div class="headline" style="">{{details.available}}</div>
              <div style="color:#4C4C4F;font-size:15px;margin-top: -3px;">caixas</div>
            </v-col>
            <v-col sm="4" md="4" class="px-0 py-0 text-center">
              <div style="color:#4C4C4F;font-size:15px;">Projeção na classificação em {{details.date_validate}}</div>
              <div class="headline" style="">{{details.projection}}</div>
              <div style="color:#4C4C4F;font-size:15px;margin-top: -3px;">caixas</div>
            </v-col>
            <v-col sm="4" md="4" class="px-0 py-0 text-center">
              <div style="color:#4C4C4F;font-size:15px;">Estoque:</div>
              <div class="headline" style="">{{details.available + details.projection}}</div>
              <div style="color:#4C4C4F;font-size:15px;margin-top: -3px;">caixas</div>
            </v-col>
          </v-row>
          <v-col sm="12" md="12" class="px-0 text-center" v-if="details.days_load">
            Carrega em <span class="font-weight-bold">{{details.days_load}} dia(s)</span>
          </v-col>
          
          <v-row class="mt-2 mr-0 ml-0" justify="center">
            <v-col sm="12" md="6" class="px-0 text-center" v-if="details.min_atacado != '0,00'" :class="details.min_varejo == '0,00' ? 'col-md-12 col-sm-12' : 'col-md-6 col-sm-12'">
              <div class="title_items" style="color:#4C4C4F;font-size:14px;">Valor Atacado</div>
              <div>
                <span class="" style="color:#5FA348;font-size:15px;">R$</span>
                <span class="headline font-weight-bold" style="color:#5FA348;">{{details.min_atacado}}</span>
                <div style="margin-top: -3px;"><small class="text-help" style="color:#4C4C4F;font-size:15px;">por caixa</small></div>
              </div>
            </v-col>
            <v-col sm="12" md="6" class="px-0 text-center" v-if="details.min_varejo != '0,00'" :class="details.min_atacado == '0,00' ? 'col-md-12 col-sm-12' : 'col-md-6 col-sm-12'">
              <div class="title_items" style="color:#4C4C4F;font-size:14px;">Valor Varejo</div>
              <div>
                <span class="" style="color:#5FA348;font-size:15px;">R$</span>
                <span class="headline font-weight-bold" style="color:#5FA348;">{{details.min_varejo}}</span>
                <div style="margin-top: -3px;"><small class="text-help" style="color:#4C4C4F;font-size:15px;">por caixa</small></div>
              </div>
            </v-col>
            <v-col class="px-0 py-0 text-center" md="12" v-if="details.min_atacado == '0,00' && details.min_varejo == '0,00'">
              <span class="text-help" style="color:#4C4C4F;font-size:14px;font-style:italic;">Preço sob consulta</span>
            </v-col>
          </v-row>

          <v-row class="mt-3 mb-3 mr-0 ml-0" justify="center">
            <v-col cols="12" sm="12" md="12" class="text-center py-0 text-warning" v-if="details.company_id != company && multipleProposals">
              <span>Múltiplas propostas só poderão ser feitas para produtos da mesma empresa</span>
            </v-col>
          </v-row>

          <v-form ref="formProposal" v-model="valid" @submit.prevent="proposal()" justify="center" v-if="details.company_id != company && !multipleProposals">
            <v-row justify="center" class="mr-0 ml-0">
              <v-col cols="12" sm="12" md="12" class="text-center py-0 mt-3">
                <span class="" style="font-size:15px;">Minha proposta</span>
                <v-chip small :color="details.proposal.status.color" text-color="white" v-if="details.proposal.status" class="ml-1">{{details.proposal.status.text}}</v-chip>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="">
                <v-row justify="center" class="mb-2">
                  <v-col cols="12" sm="12" md="5" class="mb-n5 w-100">
                    <v-text-field
                      label="Caixas"
                      solo
                      flat
                      dense
                      outlined
                      color="#858585"
                      v-model="details.proposal.boxes"
                      type="number"
                      required
                      :max="details.available + details.projection"
                      :rules="requiredRules"
                      :disabled="details.proposal.status && (details.proposal.status.status != 1 && details.proposal.status.status != 3)"
                      hide-details
                      min="0"
                    ></v-text-field>
                    <small class="text-help">Informe quantas caixas deseja comprar</small>
                  </v-col>
                  <v-col cols="12" sm="12" md="5" class="mb-n5">
                    <money
                      class="input"
                      v-model.lazy="details.proposal.value"
                      v-bind="moneyFormat"
                      required
                      :rules="requiredRules"
                      :disabled="details.proposal.status && (details.proposal.status.status != 1 && details.proposal.status.status != 3)"
                    ></money>
                    <small class="text-help">Informe o valor por caixa</small>
                  </v-col>
                </v-row>
                <div style="flex: 0 1 auto;" class="text-center mt-4" v-if="details.proposal.value && details.proposal.boxes">
                  Total: <span class="font-weight-bold">{{(details.proposal.value * details.proposal.boxes) | currency('R$ ', 2, { thousandsSeparator: '.', decimalSeparator: ',' })}}</span>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pt-0" v-if="!details.proposal.status || (details.proposal.status && (details.proposal.status.status == 1 || details.proposal.status.status == 3))">
                <v-row justify="center" class="mb-2">
                  <v-btn
                    color="#D9043D"
                    style="color: #FFF !important"
                    type="button"
                    v-if="details.proposal.id"
                    :loading="loadingCancelProposal"
                    :disabled="loadingCancelProposal"
                    class="mr-2"
                    @click="cancelProposal()"
                    >
                    CANCELAR PROPOSTA
                  </v-btn>
                  <v-btn
                    color="#95D904"
                    style="color: #FFF !important"
                    type="submit"
                    :loading="loadingProposal"
                    :disabled="loadingProposal || loadingCancelProposal || !details.proposal.value"
                    >
                    {{details.proposal.id ? 'ALTERAR' : 'ENVIAR'}}
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG PREVIEW IMAGENS -->
    <v-dialog v-model="dialog_preview" max-width="600">
      <v-card class="borda" flat>
        <v-card-title class="ma-2">
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_preview = false; imageModalPreview = ''">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" sm="12" md="12" class="pa-0 text-center">
            <img
              :src="imageModalPreview"
              class="rounded_img"
              style="max-height: 400px;"
            >
            <!-- <v-img
              :src="imageModalPreview"
              :alt="imageModalPreview"
              :lazy-src="imageModalPreview"
              aspect-ratio="1"
              class=""
              max-width="100%"
              contain
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img> -->
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG PREVIEW VIDEO -->
    <v-dialog v-model="dialog_video" max-width="600">
      <v-card class="borda" flat>
        <v-card-title class="ma-2">
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_video = false; videoModalPreview = ''">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" sm="12" md="12" class="pa-0 d-flex justify-content-center">
            <video width="100%" controls v-if="videoModalPreview">
              <source :src="videoModalPreview">
            </video>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG MULTIPLE OFFERS -->
    <v-dialog v-model="dialog_multiple_proposals" max-width="800">
      <v-card class="borda" flat min-height="250">
        <v-card-title class="ma-2">
          Múltiplas Propostas
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_multiple_proposals = false;">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list-item three-line v-for="(item, index) in listProposals" :key="index" style="border-bottom:1px solid #ccc;">
            <v-list-item-content>
              <v-list-item-title class="subtitle mb-1" style="white-space: normal;cursor:pointer;" @click="dialogDetails(item)">
                #{{item.id}}
                <span v-if="item.type">{{item.type.name}}</span>
                <span v-if="item.category">{{item.category.name}}</span>
                <span v-if="item.caliber">Cal.{{item.caliber.value}}</span>
                <span v-if="item.weight">{{item.weight.value}}kg</span>
                <span v-if="item.brand">- {{item.brand.name}}</span>
              </v-list-item-title>
              <v-row class="mr-0 ml-0">
                <v-col cols="12" sm="12" md="6" class="px-0 py-0">
                  <v-row class="mr-0 ml-0">
                    <v-col sm="12" md="6" class="px-0 py-0">
                      <div class="title_items" style="color:#4C4C4F;font-size:14px;">Disponível:</div>
                      <div class="subtitle" style="">{{item.available}}</div>
                    </v-col>
                    <v-col sm="12" md="6" class="px-0 py-0">
                      <div class="title_items" style="color:#4C4C4F;font-size:14px;">Projeção na classificação em <span class="font-weight-bold">{{item.date_validate}}</span></div>
                      <div class="subtitle" style="">{{item.projection}}</div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="6" class="px-0 py-0 text-right">
                  <v-row justify="center" class="mr-0 ml-0">
                    <v-col class="px-0 py-0 text-center" v-if="item.min_atacado != '0,00'" :class="item.min_varejo == '0,00' ? 'col-md-12 col-sm-12' : 'col-md-6 col-sm-12'">
                      <div class="title_items" style="color:#4C4C4F;font-size:14px;">Valor Atacado</div>
                      <div>
                        <span class="" style="color:#5FA348;font-size:15px;">R$</span>
                        <span class="subtitle font-weight-bold" style="color:#5FA348;">{{item.min_atacado}}</span>
                      </div>
                    </v-col>
                    <v-col class="px-0 py-0 text-center" v-if="item.min_varejo != '0,00'" :class="item.min_atacado == '0,00' ? 'col-md-12 col-sm-12' : 'col-md-6 col-sm-12'">
                      <div class="title_items" style="color:#4C4C4F;font-size:14px;">Valor Varejo</div>
                      <div>
                        <span class="" style="color:#5FA348;font-size:15px;">R$</span>
                        <span class="subtitle font-weight-bold" style="color:#5FA348;">{{item.min_varejo}}</span>
                      </div>
                    </v-col>
                    <v-col class="px-0 py-0 text-center" md="12" v-if="item.min_atacado == '0,00' && item.min_varejo == '0,00'">
                      <span class="text-help" style="color:#4C4C4F;font-size:14px;font-style:italic;">Preço sob consulta</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-col sm="12" md="12" class="px-0 py-0 mt-2">
                <v-row justify="center" class="ml-0 mr-0">
                  <v-col cols="12" sm="12" md="5" class="mb-n5 pt-0 pl-0 input_boxes">
                    <v-text-field
                      label="Caixas"
                      outlined
                      color="#858585"
                      v-model="item.boxes_proposal"
                      type="number"
                      required
                      :max="item.available + item.projection"
                      hide-details
                      min="0"
                    ></v-text-field>
                    <small class="text-help mb-2" style="color:#4C4C4F;">Informe quantas caixas deseja comprar</small>
                  </v-col>
                  <v-col cols="12" sm="12" md="5" class="mb-n5 pt-0 pl-0">
                    <money
                      class="input"
                      v-model.lazy="item.value_proposal"
                      v-bind="moneyFormat"
                      required
                    ></money>
                    <small class="text-help">Informe o valor por caixa</small>
                  </v-col>
                </v-row>
                <div class="text-center mt-4" v-if="item.value_proposal && item.boxes_proposal">
                  Subtotal: <span class="font-weight-bold">{{(item.value_proposal * item.boxes_proposal) | currency('R$ ', 2, { thousandsSeparator: '.', decimalSeparator: ',' })}}</span>
                </div>
              </v-col>
            </v-list-item-content>
          </v-list-item>
          <v-col cols="12" sm="12" md="12" class="mt-3">
            <div class="text-center mb-3">
              TOTAL:
              <span class="font-weight-bold">{{totalValue(listProposals) | currency('R$ ', 2, { thousandsSeparator: '.', decimalSeparator: ',' })}}</span>
            </div>
            <v-row justify="center" class="mb-2">
              <v-btn
                color="#95D904"
                style="color: #FFF !important"
                type="button"
                :loading="loadingProposal"
                :disabled="loadingProposal || loadingCancelProposal"
                @click="proposalMultiple()"
                >
                ENVIAR
              </v-btn>
            </v-row>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG OFERTA EXPIRADA -->
    <v-dialog v-model="dialog_offer_expired" max-width="800">
      <v-card flat min-height="200">
        <v-card-title class="ma-2">
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_offer_expired = false;">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-col cols="12" sm="12" md="12" class="pa-0 d-flex justify-content-center align-center" style="height:100%;">
            <div class="font-weight-bold text-danger mt-3" style="font-size:30px;">Esta oferta expirou ou não está mais disponível :(</div>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    
  </div>
</template>
<script>
import axios from "axios";
import moment from 'moment';
import io from 'socket.io-client';

export default {
    props: [
      'edit',
      'company',
      'user',
      'url',
      'token',
      'filters',
      'items',
      'offers'
    ],
    data() {     
      var tunnel = 'https://tunnel.d3t.com.br';
			var api = 'https://manzanas.app/api';

			if (window.location.hostname == 'localhost'){
				tunnel = 'http://172.20.0.1:5555';
				api = 'http://172.24.0.1/api';
			}
      return {
        requiredRules: [v => !!v || "Campo Obrigatório."],
        ruleType: [],
        ruleCategory: [],
        ruleCaliber: [],
        ruleLote: [],
        ruleBrand: [],
        rulePicture: [],
        valid: true,
        productForms: [],
        formSelected: '',
        products: [],
        types: [],
        categories: [],
        calibers: [],
        brands: [],
        weights: [],
        poles: [],
        companies: [],
        loadingSearch: false,
        loadingProducts: false,
        loadingTypes: false,
        loadingCategories: false,
        loadingCalibers: false,
        loadingBrands: false,
        loadingWeights: false,
        loadingPoles: false,
        loadingCompanies: false,
        loadingProposal: false,
        loadingCancelProposal: false,
        dialog_details: false,
        dialog_preview: false,
        dialog_video: false,
        details: '',
        itemsGallery: [],
        dialog_video: false,
        itemsVideo: [],
        imageModalPreview: '',
        videoModalPreview: '',
        formData: {
          id: '',
          company_id: this.company,
          user_id: '',
          product_id: '',
          type_id: '',
          category_id: '',
          caliber_id: '',
          lote: '',
          brand_id: '',
          weight_id: '',
        },
        moneyFormat: {
          decimal: ",",
          thousands: ".",
          prefix: "R$ ",
          precision: 2,
          masked: false,
        },
        menu1: false,
        dateFormatted: "",
        data_mask: "##/##/####",
        date: "",
        panelFilter: false,
        socket: io(tunnel, {
					query: `domain=${api}&authorization=${this.user.app_token}`
        }),
        myProducts: false,
        multipleProposals: false,
        listProposals: [],
        dialog_multiple_proposals: false,
        offerExpired: false,
        dialog_offer_expired: false,
      };
    },

    created() {
      console.log("this.items");
      console.log(this.items);
      this.getPoles();
      this.getProducts();
      this.getCompanies();
      if (this.filters.product_id){
        this.formData.product_id = parseInt(this.filters.product_id);
        this.formData.type_id = parseInt(this.filters.type_id);
        this.formData.category_id = this.filters.category_id;
        this.formData.caliber_id = parseInt(this.filters.caliber_id);
        this.formData.brand_id = parseInt(this.filters.brand_id);
        this.formData.weight_id = parseInt(this.filters.weight_id);
        this.formData.pole = parseInt(this.filters.pole);
        this.formData.company = parseInt(this.filters.company);
        this.selectProduct();
      }
    },

    mounted() {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('hashOffer')){
        let item = this.items.filter(item => {
          return item.offer_hash == urlParams.get('hashOffer');
        });
        console.log("dialogDetails");
        console.log(item);
        if (item.length){
          this.dialogDetails(item[0]);
        } else {
          this.offerExpired = true;
          this.dialog_offer_expired = true;
        }
      }
    },

    computed: {
      filteredResults() {
        return this.items.filter(item => {
          
          if (this.myProducts) return this.items;

          return item.company_id != this.company;
        })
      },
    },

    methods: {

      textFilter(arr, id) {
        var text = '';
        if (arr){
          arr.forEach(element => {
            if (id.toString().includes(",")){
              id.split(",").forEach(element2 => {
                if (element.id == element2){
                  text += element.name ? element.name+' | ' : element.value+' | ';
                }
              });
            } else {
              if (element.id == id){
                text = element.name ? element.name : element.value;
              }
            }
          });
        }
        return text;
      },

      clear(){
        this.formData = {
          id: '',
          company_id: this.company,
          user_id: '',
          product_id: '',
          type_id: '',
          category_id: '',
          caliber_id: '',
          lote: '',
          brand_id: '',
          weight_id: '',
        }
        this.formSelected = '';
        this.$refs.form.resetValidation();
      },

      getProducts(edit){
        this.loadingProducts = true;
        axios
          .get('/api/product')
          .then((response) => {
            // console.log("getProducts");
            // console.log(response.data);
            this.products = response.data;
            this.loadingProducts = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os produtos.");
            this.loadingProducts = false;
            console.log(err)
          })
      },

      getTypes(){
        this.loadingTypes = true;
        axios
          .get('/api/product-type?product_id='+this.formData.product_id)
          .then((response) => {
            // console.log("getTypes");
            // console.log(response.data);
            this.types = response.data;
            this.loadingTypes = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os tipos.");
            this.loadingTypes = false;
            console.log(err)
          })
      },

      getCategories(){
        this.loadingCategories = true;
        axios
          .get('/api/product-category?product_id='+this.formData.product_id)
          .then((response) => {
            // console.log("getCategories");
            // console.log(response.data);
            this.categories = response.data;
            this.loadingCategories = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar as categorias.");
            this.loadingCategories = false;
            console.log(err)
          })
      },

      getCalibers(){
        this.loadingCalibers = true;
        axios
          .get('/api/product-caliber?product_id='+this.formData.product_id)
          .then((response) => {
            // console.log("getCalibers");
            // console.log(response.data);
            this.calibers = response.data;
            this.loadingCalibers = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os calibres.");
            this.loadingCalibers = false;
            console.log(err)
          })
      },

      getBrands(){
        this.loadingBrands = true;
        axios
          .get('/api/product-brand')
          .then((response) => {
            // console.log("getBrands");
            // console.log(response.data);
            this.brands = response.data;
            this.loadingBrands = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar as marcas.");
            this.loadingBrands = false;
            console.log(err)
          })
      },

      getWeights(){
        this.loadingWeights = true;
        axios
          .get('/api/product-weight?product_id='+this.formData.product_id)
          .then((response) => {
            // console.log("getWeights");
            // console.log(response.data);
            this.weights = response.data;
            this.loadingWeights = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os pesos.");
            this.loadingWeights = false;
            console.log(err)
          })
      },

      getPoles(){
        this.loadingPoles = true;
        axios
          .get('/api/pole')
          .then((response) => {
            // console.log("getPoles");
            // console.log(response.data);
            this.poles = response.data;
            this.loadingPoles = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os polos.");
            this.loadingPoles = false;
            console.log(err)
          })
      },

      getCompanies(){
        this.loadingCompanies = true;
        axios
          .get('/api/company/active')
          .then((response) => {
            console.log("getCompanies");
            console.log(response.data);
            this.companies = response.data;
            this.loadingCompanies = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os polos.");
            this.loadingCompanies = false;
            console.log(err)
          })
      },

      selectProduct(){
        if (this.formData.product_id){
          this.getTypes();
          this.getCategories();
          this.getCalibers();
          this.getBrands();
          this.getWeights();
        }
      },

      search (e){
        this.loadingSearch = true;
        if (!this.$refs.form.validate()) {
          this.loadingSearch = false;
          e.preventDefault();
        }
      },

      expandFilters(){
        this.panelFilter = !this.panelFilter;
      },

      dialogDetails(item){
        this.dialog_details = true;
        //this.details = Object.assign({}, item);
        this.details = JSON.parse(JSON.stringify(item));
        if (!item.proposal){
          this.details.proposal = [];
        }
        console.log("this.details");
        console.log(this.details);
      },

      modalPreview: function(image) {
        this.imageModalPreview = image;
        this.dialog_preview = true;
      },

      modalPreviewVideo: function(video) {
        this.videoModalPreview = video;
        this.dialog_video = true;
      },

      proposal (e){
        this.loadingProposal = true;
        if (this.$refs.formProposal.validate()) {
          this.$swal({
            title: "Atenção!",
            text: "Deseja realmente enviar esta proposta?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#008d4c",
            cancelButtonColor: "#d4d4d4",
            confirmButtonText: "Sim, enviar!",
            cancelButtonText: "Não!",
          }).then(result => {
            if (result.value) {
              var dados = [{
                'company_product_id': this.details.id,
                'company_id': this.company,
                'user_id': this.user,
                'seller_id': this.details.company_id,
                'value': this.details.proposal.value,
                'boxes': this.details.proposal.boxes,
              }]
              if (this.details.proposal){
                dados[0].id = this.details.proposal.id;
              }
              axios
                .post("/api/proposal", dados)
                .then((response) => {
                  console.log("proposal");
                  console.log(response.data);
                  if (!response.data.errors) {
                    var self = this;
                    this.items.forEach((value, index) => {
                      if (value.id == self.details.id){
                        self.items[index].proposal = response.data;
                      }
                    })
                    this.$toast.success("Proposta enviada!");
                    this.dialog_details = false;
                    // ENVIAR MENSAGEM NO CHAT
                    this.enviar_mensagem(response.data.id);
                    this.details = '';
                  } else {
                    this.$toast.warning(response.data.errors);
                  }
                })
                .catch(err => {
                  this.$toast.error("Ocorreu um erro ao enviar a proposta.");
                  console.log(err)
                })
                .finally(() => {
                  this.loadingProposal = false;
                });
            } else {
              this.loadingProposal = false;
            }
          });
        } else {
          this.loadingProposal = false;
        }
      },

      proposalMultiple (){
        console.log("this.listProposals");
        console.log(this.listProposals);
        this.loadingProposal = true;
        this.$swal({
          title: "Atenção!",
          text: "Deseja realmente enviar estas propostas?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#008d4c",
          cancelButtonColor: "#d4d4d4",
          confirmButtonText: "Sim, enviar!",
          cancelButtonText: "Não!",
        }).then(result => {
          if (result.value) {
            if (this.details.proposal){
              dados.id = this.details.proposal.id;
            }
            var dados = [];
            this.listProposals.forEach((value) =>{
              dados.push({
                'company_product_id': value.id,
                'company_id': this.company,
                'user_id': this.user,
                'seller_id': value.company_id,
                'value': value.value_proposal,
                'boxes': value.boxes_proposal,
              });
            })
            axios
              // .post("/api/proposal/multiple", dados)
              .post("/api/proposal", dados)
              .then((response) => {
                console.log("proposalMultiple");
                console.log(response.data);
                // this.loadingProposal = false;
                // return null;
                if (!response.data.errors) {
                  // var self = this;
                  // this.items.forEach((value, index) => {
                  //   if (value.id == self.details.id){
                  //     self.items[index].proposal = response.data;
                  //   }
                  // })
                  this.$toast.success("Proposta enviada!");
                  this.dialog_multiple_proposals = false;
                  // ENVIAR MENSAGEM NO CHAT
                  this.enviar_mensagem(response.data.id);
                  this.listProposals = [];
                } else {
                  this.$toast.warning(response.data.errors);
                }
              })
              .catch(err => {
                this.$toast.error("Ocorreu um erro ao enviar a proposta.");
                console.log(err)
              })
              .finally(() => {
                this.loadingProposal = false;
              });
          } else {
            this.loadingProposal = false;
          }
        });
      },

      cancelProposal (){
        this.loadingCancelProposal = true;
        if (this.$refs.formProposal.validate()) {
          this.$swal({
            title: "Atenção!",
            text: "Deseja realmente CANCELAR esta proposta?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#008d4c",
            cancelButtonColor: "#d4d4d4",
            confirmButtonText: "Sim, cancelar!",
            cancelButtonText: "Não!",
          }).then(result => {
            if (result.value) {
              var dados = {
                'id': this.details.proposal.id,
              }
              axios
                .post("/api/proposal/cancel", dados)
                .then((response) => {
                  console.log("cancelProposal");
                  console.log(response.data);
                  if (!response.data.errors) {
                    var self = this;
                    this.items.forEach((value, index) => {
                      if (value.id == self.details.id){
                        delete self.items[index].proposal;
                      }
                    })
                    this.$toast.success("Proposta cancelada!");
                    this.dialog_details = false;
                    this.details = '';
                  } else {
                    this.$toast.warning(response.data.errors);
                  }
                })
                .catch(err => {
                  this.$toast.error("Ocorreu um erro ao cancelar a proposta.");
                  console.log(err)
                })
                .finally(() => {
                  this.loadingCancelProposal = false;
                });
            } else {
              this.loadingCancelProposal = false;
            }
          });
        } else {
          this.loadingCancelProposal = false;
        }
      },

      enviar_mensagem (proposal) {
        
        var message = '<span class="title_chat_proposal">********** NOVA PROPOSTA **********</span><br/>Proposta <span class="font-weight-bold">'+proposal+'</span> enviada, aguardo retorno! :)<br/>';

        const data = {
          method: 'POST',
          endpoint: '/chat/new_message',
          
          content: {
            send_id: this.user,
            //receive_id: contact.id,
            receive_id: 1,
            archive: null,
            message: message,
            created_at: moment().format('YYYY-MM-DD HH:mm'),
            type: 'proposal_new',
            control: proposal,
          }
        }

        this.socket.emit('event', data);
      },

      changeMultipleProposal(value){
        const exists = this.listProposals.filter((a) => a.id !== value.id);

        if(exists.length === this.listProposals.length) {
          if (exists.length){
            this.listProposals = [];
            exists.forEach((value, index) => {
              this.listProposals.push(exists[index]);
            })
            this.listProposals.push(value);
            return false;
          } else {
            return this.listProposals = [value];
          }
        }

        return this.listProposals = exists;
      },

      showListProposals(){
        this.dialog_multiple_proposals = true;
      },

      totalValue(list){
        var self = this;
        var total = 0;
        list.forEach(element => {
          if (element.value_proposal && element.boxes_proposal){
            total += element.value_proposal * element.boxes_proposal;
          }
        });
        return total;
      }

    },
        
}
</script>
<style lang="scss" scoped>
  .input_boxes{
    @media (max-width: 768px) {
      margin-bottom: 0 !important;
    }
  }
</style>