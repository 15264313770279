<template>
  <div>
    <label class="col-12 label_forgot" @click="openDialog()">
      Esqueci minha senha
    </label>

    <!-- DIALOG RECUPERAR SENHA -->
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card flat>
        <v-card-title class="ma-2 title_modal" align-items="center">
          Recuperar senha
          <v-spacer></v-spacer>
          <v-btn icon text @click="close()">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-3">
          <div class="h5 pl-3">Informe seu e-mail</div>
          <v-form ref="formForgot" v-model="valid" @submit.prevent="send()">
            <v-row class="ma-0">
              <v-col cols="12" sm="12" md="12" class="mb-n5">
                <v-text-field
                  label="E-mail *"
                  outlined
                  color="#858585"
                  v-model="email"
                  required
                  :rules="rulesAdicionalEmail"
                  autofocus
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" class="mb-2">
              <v-btn
                type="submit"
                color="#95D904"
                style="color: #FFF !important"
                :loading="loading"
                :disabled="loading"
                >
                ENVIAR
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data(){
    return {
      email: '',
      loading: false,
      dialog: false,
      valid: true,
      rulesAdicionalEmail: [
        v => !!v || "Campo Obrigatório.",
        v => /^(([^<>()\\.,;:ç~\s@"]+(\.[^<>()\\.,;:ç~\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v) || "E-mail inválido"
      ],
    }
  },
  methods: {
    
    openDialog(){
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      if (this.$refs.formForgot) {
        this.$refs.formForgot.resetValidation();
      }
      this.email = '';
    },

    send (){
      if (this.$refs.formForgot.validate()) {
        this.loading = true;
        var dados = {
          email: this.email
        }
        axios
          .post('/api/company/forgot-password', dados)
          .then((response) => {
            console.log("save");
            console.log(response.data);
            if (!response.data.errors) {
              this.$toast.success("Enviado com sucesso! Em breve você receberá o e-mail para recuperação.");
              this.close();
            } else {
              this.$toast.warning(response.data.errors);
            }
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro.");
            console.log(err)
          })
          .finally(() => {
              this.loading = false;
          });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .label_forgot{
    font-size:13px;
    line-height: 13px;
    padding: 0;
    cursor: pointer;
  }
  .label_forgot:hover{
    text-decoration: underline;
  }
</style>