<template>
	<div class="col-12 chat_solicitation">
    <!-- <button @click="listContacts = !listContacts" class="btn" v-if="$vuetify.breakpoint.mobile"></button> -->
    <div class="text-center">
      <v-btn
        color="#95D904"
        style="color: #FFF !important"
        type="button"
        class="mb-1"
        @click="listContacts = !listContacts"
        v-if="$vuetify.breakpoint.mobile"
      >
        <i class="fa fa-list mr-1" v-if="!listContacts"></i>
        <i class="fa fa-times mr-1" v-if="listContacts"></i>
        Lista de contatos
      </v-btn>
    </div>
		<div class="row align-items-center">

      <!-- LISTA DE CONTATOS -->
      <div class="col-3 box_lista" v-bind:class="{ 'expand_list': listContacts }">
        <div class="col-12 box_search pr-0">
          <v-row class="">
            <v-col sm="11" md="11" class="px-0 py-0">
              <!-- <input type="search" v-model="search" class="form-control pull-left" placeholder="Pesquisar..."> -->
              <v-text-field
                placeholder="Pesquisar..."
                flat
                outlined
                color="#858585"
                hide-details
                v-model="search"
              ></v-text-field>
            </v-col>
            <v-col sm="1" md="1" class="px-0 py-0 text-center">
              <v-menu offset-x class="mt-1">
                <template v-slot:activator="{ on }">
                  <v-btn icon text v-on="on" class="">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="dialogAllContacts()">
                    <v-list-item-title>Iniciar uma nova conversa</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <div class="col-12 box_lines">
          <div v-if="loadingChats" class="d-flex justify-content-center box_loading">
            <div class="spinner-grow text-secondary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <v-list>
            <v-list-item v-for="(item, index) in filteredChats" :key="index" class="mb-1" @click="init_chat(item)" :class="{'line_active': verifLineActive(item), 'new_solicitation': item.new}">
              <v-avatar size="50" color="grey" class="mr-2">
                <!-- <v-img :alt="`${item.name} avatar`" :src="item.image" v-if="item.image"></v-img> -->
                <v-img
                  :src="item.image"
                  :alt="item.image"
                  :lazy-src="item.image"
                  v-if="item.image"
                  >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular indeterminate color="grey lighten-5" size="20" width="3"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-icon dark v-else>
                  mdi-account
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title><strong>{{item.name}}</strong></v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip x-small color="grey" text-color="white">{{item.company.fantasy_name}}</v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="item.new_messages > 0">
                <v-chip class="pull-right" small color="#D9043D" text-color="white">{{item.new_messages}}</v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <div class="col-12 box_sem_registro" v-if="!chats.length && !loadingChats">
            Nenhuma conversa iniciada
          </div>
        </div>
      </div>

      <!-- CHAT -->
			<div class="col-9 box_chat" v-bind:class="{ 'col-12': !listContacts && $vuetify.breakpoint.mobile }" v-show="!listContacts">

        <!-- CABEÇALHO CHAT -->
				<div class="col-12 box_header" v-if="chat.user">
          <v-row class="ml-0 mr-0">
            <v-col cols="12" sm="12" md="6" class="pb-0 pt-1 box_title">
              <div class="d-flex pull-left">
                <v-avatar size="35" class="mr-3 pull-left" color="grey">
                  <v-img
                    :src="contact.image"
                    :alt="contact.image"
                    :lazy-src="contact.image"
                    v-if="contact.image"
                    aspect-ratio="1"
                    class=""
                    max-width="100%"
                    contain
                    >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="grey lighten-5" size="20" width="3"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-icon dark v-if="!contact.image">
                    mdi-account
                  </v-icon>
                </v-avatar>
                <div style="display: grid; align-items: center;">
                  <div class="box_nome">{{contact.name}}</div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="pb-0 pt-0 box_buttons">
              <v-btn
                color="#95D904"
                style="color: #FFF !important"
                type="button"
                class="pull-right mt-1 mr-2"
                @click="dialogOrders()"
              >pedidos</v-btn>
              <v-btn
                color="primary"
                type="button"
                class="pull-right mt-1 mr-4"
                @click="dialogProposals()"
              >
                <!-- {{contact.proposals.length}} {{contact.proposals.length > 1 ? 'propostas' : 'proposta'}} -->
                propostas
              </v-btn>
            </v-col>
          </v-row>
				</div>

        <!-- CONVERSA CHAT -->
				<div class="col-12 box_interno_chat">
					<div v-if="loadingChat" class="d-flex justify-content-center box_loading" style="padding: 32vh 0;">
						<div class="spinner-grow text-secondary" style="width: 5rem; height: 5rem;" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
					<div class="col-12 box_aviso" v-if="!chat.user && !loadingChat">
						<i class="fa fa-info-circle" aria-hidden="true"></i><br/>
						<span>Clique em um item do lado esquerdo para inicar uma interação.</span>
					</div>
					<div v-if="chat.user" class="row align-items-end box_conversa w-100" v-bind:class="{ 'w-100': $vuetify.breakpoint.mobile }">
						<transition-group name="list" style="width:100%;">
							<div class="col-12 box_line_msg" v-for="(c, index) in chat.messages" :key="index">
								<div class="box_msg" :class="c.send_id == user.id ? 'msg1' : 'msg2'">
                  <v-avatar size="35" class="mr-3 pull-left" color="grey" v-if="c.send_id != user.id">
                    <v-img
                      :src="contact.image"
                      :alt="contact.image"
                      :lazy-src="contact.image"
                      v-if="contact.image"
                      >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular indeterminate color="grey lighten-5" size="20" width="3"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-icon dark v-if="!contact.image">
                      mdi-account
                    </v-icon>
                  </v-avatar>

									<div class="box_text" :class="c.send_id == user.id ? 'cor1' : 'cor2'">
										<div class="box_interno_text" v-if="c.message && !c.type" v-html="c.message"></div>
                    <!-- MENSAGENS DINAMICAS -->
										<div class="box_interno_text" v-if="c.message && c.type">
                      <!-- EXIBIR MENSAGEM -->
                      <div v-html="c.message"></div>
                      <!-- PROPOSTA -->
                      <div v-if="c.type.includes('proposal')">
                        <a v-if="c.control" class="text-underline" @click="dialogProposals(contact, c.control)">Acessar proposta</a>
                      </div>
                      <!-- PEDIDO -->
                      <div v-if="c.type.includes('order')">
                        <a v-if="c.control" class="text-underline" @click="dialogOrders(contact, c.control)">Acessar pedido</a>
                      </div>
                    </div>
										<div class="box_interno_text" style="text-align: center;" v-if="c.archive">
											<img class="img_enviada" :src="c.archive" data-toggle="modal" data-target="#previewImageModal" @click="imagePreview = c.archive">
										</div>
										<div class="box_time">
											{{c.created_at}}
										</div>
									</div>
                  <v-avatar size="35" class="ml-3 pull-left" color="grey" v-if="c.send_id == user.id">
                    <v-img
                      :src="userimage"
                      :alt="userimage"
                      :lazy-src="userimage"
                      v-if="userimage"
                      >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular indeterminate color="grey lighten-5" size="20" width="3"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-icon dark v-if="!userimage">
                      mdi-account
                    </v-icon>
                  </v-avatar>
								</div>
							</div>
						</transition-group>
						<!-- <div class="col-12 box_finish_chat" v-if="chat.solicitation.finished == 1">
							<div class="msg">Chat finalizado. Você não pode enviar mensagens.</div>
						</div> -->
					</div>
					<div v-if="chat.user" class="col-12 box_footer">
						<button class="box_anexo" @click="clickSelectFiles()">
							<i class="fa fa-paperclip" aria-hidden="true"></i>
							<input id="files-upload" name="file" type="file" accept="image/*" @change="selected_images(chat.solicitation, contact, user)" style="display: none;" multiple/>
						</button>
						<div class="box_message">
							<input type="search" autofocus v-model="message" id="input_message" class="form-control pull-left" placeholder="Digite uma mensagem e pressione ENTER para enviar" v-on:keyup.enter="enviar_mensagem(message)">
						</div>
						<button class="box_send d-flex justify-content-center align-items-center" @click="enviar_mensagem(message)">
              <v-icon>
                mdi-send
              </v-icon>
						</button>
					</div>
				</div>
			</div>
		</div>

    <!-- DIALOG PROPOSALS -->
    <v-dialog persistent v-model="dialog_proposals" max-width="800">
      <v-card class="borda" flat min-height="250" :loading="loadingProposals">
        <v-card-title class="ma-2" v-if="contact">
          Propostas disponíveis de <strong>{{contact.name}}</strong>
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_proposals = false;">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="contact">
          <v-col cols="12" sm="12" md="12" class="pa-0">
            <v-expansion-panels v-model="panel">
              <v-expansion-panel v-for="(item, index) in contact.proposals" :key="index">
                <v-expansion-panel-header>
                  <div style="flex: 0 1 auto;" class="mr-2">
                    #{{item.id}}
                  </div>
                  <div style="flex: 0 1 auto;" class="mr-3">
                    <span class="font-weight-bold">{{item.proposal_product[0].company_product.product.name}}</span>
                  </div>
                  <div style="flex: 0 1 auto;" class="mr-3" v-if="!$vuetify.breakpoint.mobile">
                    <span>{{item.proposal_product.length}} {{item.proposal_product.length > 1 ? 'produtos' : 'produto'}}</span>
                  </div>
                  <div style="flex: 0 1 auto;" class="mr-3">
                    <span class="font-weight-bold">R$ {{item.total_value}}</span>
                  </div>
                  <div style="flex: 0 1 auto;" class="mr-3">
                    <v-chip small :color="item.status.color" text-color="white" v-if="!$vuetify.breakpoint.mobile">{{item.status.text}}</v-chip>
                    <v-chip x-small :color="item.status.color" text-color="white" v-if="$vuetify.breakpoint.mobile">{{item.status.text}}</v-chip>
                  </div>
                  <v-spacer></v-spacer>
                  <div style="flex: 0 1 auto;" class="mr-3" v-if="!$vuetify.breakpoint.mobile">
                    <span style="font-size:11px;">publicado por</span>
                    <span class="font-weight-bold">{{item.proposal_product[0].company_product.company.fantasy_name}}</span>
                  </div>
                  <template v-slot:actions>
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <details-proposal :proposal="item.id" v-if="panel == index" :user="user"></details-proposal>

                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="text-center mt-5" v-if="contact.proposals && contact.proposals.length == 0 && !loadingProposals">Nenhuma proposta encontrada</div>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG LOADING -->
    <v-dialog
      v-model="dialogLoading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
        class="d-flex align-center"
      >
        <v-card-text class="py-3 text-center">
          Aguarde...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG ORDERS -->
    <v-dialog persistent v-model="dialog_orders" max-width="800">
      <v-card class="borda" flat min-height="250" :loading="loadingOrders">
        <v-card-title class="ma-2">
          Pedidos de <strong>{{contact.name}}</strong>
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_orders = false;">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" sm="12" md="12" class="pa-0">
            <v-expansion-panels
              v-model="panelOrder"
            >
              <v-expansion-panel v-for="(item, index) in orders" :key="index">
                <v-expansion-panel-header>
                  <div style="flex: 0 1 auto;" class="mr-2">
                    #{{item.id}}
                  </div>
                  <div style="flex: 0 1 auto;" class="mr-3">
                    <span class="font-weight-bold">{{item.proposal.proposal_product[0].company_product.product.name}}</span>
                  </div>
                  <div style="flex: 0 1 auto;" class="mr-3" v-if="!$vuetify.breakpoint.mobile">
                    <span>{{item.proposal.proposal_product.length}} {{item.proposal.proposal_product.length > 1 ? 'produtos' : 'produto'}}</span>
                  </div>
                  <div style="flex: 0 1 auto;" class="mr-3">
                    <span class="font-weight-bold">R$ {{item.total_value}}</span>
                  </div>
                  <div style="flex: 0 1 auto;" class="mr-3">
                    <v-chip small :color="item.status.color" text-color="white" v-if="!$vuetify.breakpoint.mobile">{{item.status.text}}</v-chip>
                    <v-chip x-small :color="item.status.color" text-color="white" v-if="$vuetify.breakpoint.mobile">{{item.status.text}}</v-chip>
                  </div>
                  <v-spacer></v-spacer>
                  <div style="flex: 0 1 auto;" class="mr-3" v-if="!$vuetify.breakpoint.mobile">
                    <span style="font-size:11px;">vendido por</span>
                    <span class="font-weight-bold">{{item.seller.fantasy_name}}</span>
                  </div>
                  <template v-slot:actions>
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <details-order :order="item.id" v-if="panelOrder == index" :user="user" :company="user.company_id" @attChatChild="attChat" @openProposalChild="accessProposal"></details-order>

                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="text-center mt-5" v-if="orders.length == 0 && !loadingOrders">Nenhum pedido encontrado</div>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG ALL CONTACTS -->
    <v-dialog v-model="dialog_all_contacts" max-width="600">
      <v-card class="borda" flat min-height="300" :loading="loadingAllContacts">
        <v-card-title class="ma-2">
          Selecione um contato
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_all_contacts = false;">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-text-field
            placeholder="Buscar..."
            flat
            outlined
            color="#858585"
            hide-details
            v-model="searchContact"
            type="search"
            autofocus
          ></v-text-field>
          <v-divider></v-divider>
          <v-list>
            <v-list-item v-for="(item, index) in filteredContacts" :key="index" class="" @click="newChat(item)">
              <v-avatar size="40" color="grey" class="mr-2">
                <v-img :alt="`${item.name} avatar`" :src="item.image" v-if="item.image"></v-img>
                <v-icon dark v-else>
                  mdi-account
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title><strong>{{item.name}}</strong></v-list-item-title>
                <v-list-item-subtitle v-text="item.company.fantasy_name"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
	</div>
</template>

<script>
	import axios from 'axios';
	import moment from 'moment';
	import io from 'socket.io-client';

	export default {
		props: ['user', 'authorization', 'solicitation_id', 'userimage'],
		data: function() {
			var tunnel = 'https://tunnel.d3t.com.br';
			var api = 'https://manzanas.app/api';

			if (window.location.hostname == 'localhost'){
				tunnel = 'http://172.20.0.1:5555';
				api = 'http://172.24.0.1/api';
			}

			return {
				loadingChats: true,
				loadingProposals: true,
				loadingChat: false,
				loadingFinalizar: false,
				imagePreview: '',
				detailsSolicitation: '',
				search: '',
				chats: [],
        proposals: [],
        orders: [],
				contact: '',
				chat: [],
				message: '',
				file: '',
				selectedFiles: [],
				socket: io(tunnel, {
					query: `domain=${api}&authorization=${this.user.app_token}`
				}),
				response: '',
				responseCustom: '',
        responsesDefault: [],
        panel: [],
        panelOrder: [],
        dialog_proposals: false,
        dialog_orders: false,
        dialog_all_contacts: false,
        loadingStatus: false,
        dialogLoading: false,
        loadingProposals: false,
        loadingOrders: false,
        loadingAllContacts: false,
        allContacts: [],
        searchContact: '',
        listContacts: false,
			};
		},

		created() {
      this.get_chats();
      this.timeoutChats();
      // this.init_chat({id: 7});
      this.socket.on('observer', async (data) => {
        console.log(data, 'observer')
        const content = data.content.content;
        console.log("this.chat");
        console.log(this.chat);
        if (this.chat.user){
          //if (this.chat.user.id == content.receive_id){
          if (this.chat.user.id == content.send_id){
            this.chat.messages.push(content);
            this.scrollChat(200);
            this.getProposals();
            this.getOrders();
            this.setViewedMessage(this.user.id, content.send_id);

          } else {
            var index = this.chats.map((el) => el.id).indexOf(content.send_id);
            this.chats[index].new_messages++;
            this.updateChats();
          }
        } else {
          var index = this.chats.map((el) => el.id).indexOf(content.send_id);
          console.log("this.chats[index]");
          console.log(this.chats[index]);
          this.chats[index].new_messages++;
          this.updateChats();
        }
      });
	  },

	mounted() {

	},

	computed: {
	  filteredChats() {
      return this.chats.filter(item => {
        return item.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    filteredContacts() {
      if (this.allContacts){
        return this.allContacts.filter(item => {
          return item.name.toLowerCase().includes(this.searchContact.toLowerCase())
        })
      }
	  },
	},

	methods: {

	  scrollChat (time) {
      setTimeout(function() {
        $(".box_conversa").scrollTop($(".box_conversa").prop("scrollHeight"));
      }, time);
	  },

	  verifLineActive (data) {
      if (this.contact){
        if (data.id == this.contact.id){
          return true;
        }
      }
      return false;
	  },

	  clickSelectFiles () {
      $('#files-upload').val('');
      $('#files-upload').trigger('click');
	  },

	  get_chats () {
      this.loadingChats = true
      this.chats = [];

      axios.get('/api/chat/chats')
      .then(response => {
        console.log('get_chats');
        console.log(response.data);
        this.chats = response.data;
      })
      .catch(e => {
        var msg = 'Houve um erro ao buscar os chats! Por favor, tente novamente.'
        this.$swal("Oops!", msg, "error")
        console.error(e)
      })
      .finally(() => {
        this.loadingChats = false
      })
	  },

	  updateChats () {
      axios.get('/api/chat/chats')
      .then(response => {
        this.chats = response.data;
        console.log('update');
        this.timeoutChats();
      })
      .catch(e => {
        console.error(e)
      })
	  },

	  timeoutChats () {
      var self = this;
      setTimeout(function() {
        self.updateChats();
      }, 30000);
	  },

	  setViewedMessage (receive, send) {
      var dados = {
        'receive_id' : receive,
        'send_id' : send,
      }
      axios.post(`/api/chat/messages/viewed`, dados)
      .then(response => {
        console.log("setViewedMessage");
        console.log(response.data);
      })
      .catch(e => {
        console.error(e)
      })
	  },

	  init_chat (data) {
      this.loadingChat = true
      this.chat = [];
      this.contact = '';
      this.category = {};
      if (this.$vuetify.breakpoint.mobile){
        this.listContacts = !this.listContacts;
      }

      this.setViewedMessage(this.user.id, data.id);

      axios.get(`/api/chat/messages/${data.id}`)
      .then(response => {
        console.log('init_chat');
        console.log(response.data);

        this.chat = response.data;
        this.contact = data;

        var index = this.chats.map((el) => el.id).indexOf(data.id);
        this.chats[index].new_messages = '';
        $("#input_message").focus();
      })
      .catch(e => {
        var msg = 'Houve um erro ao iniciar o chat! Por favor, tente novamente.'
        this.$swal("Oops!", msg, "error")
        console.error(e)
      })
      .finally(() => {
        this.loadingChat = false;
        this.scrollChat(200);
      })
	  },

	  enviar_mensagem (message, proposal = null, order = null) {
      const data = {
        method: 'POST',
        endpoint: '/chat/new_message',
        
        content: {
          //solicitation_id: solicitation.id,
          send_id: this.user.id,
          receive_id: this.contact.id,
          archive: null,
          message: message,
          created_at: moment().format('YYYY-MM-DD HH:mm'),
          // type: proposal ? 'proposal_att' : null,
          // control: proposal ? proposal : null,
          type: proposal ? 'proposal_att' : order ? 'order_generated' : null,
          control: proposal ? proposal : order ? order : null,
        }
      }
      
      this.chat.messages.push(data.content);
      this.socket.emit('event', data);

      this.message = '';
      this.scrollChat(200);
	  },

	  getExtension(path) {
		  var basename = path.split(/[\\/]/).pop(),
			  pos = basename.lastIndexOf(".");

		  if (basename === "" || pos < 1)
			  return "";

		  return basename.slice(pos + 1);
	  },

	  async send_files (message, files) {
      let formData = new FormData();
        Object.keys(message).map((key) => {
          if(key === 'archive') {
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                formData.append(`archive_${i}`,file, `${i}_${new Date().getTime()}.${this.getExtension(files[i].name)}`);
              }
          } else {
            if(message[key]) {
            formData.append(key, message[key])
            }
          }
        });

      const { data } = await axios.post(`/api/chat/new_image_message`, formData, { headers: { Authorization: this.authorization }})
      .catch(e => {
        var msg = 'Houve um erro ao enviar arquivos! Por favor, tente novamente.'
        this.$swal("Oops!", msg, "error")
        console.error(e)
      });
      const { content } = data;

		  return {
        success: true,
        content: content
		  };
	  },

	  async selected_images (solicitation, contact, user) {
      if (!event.target.files.length){
        return false;
      }

      var message = {
        //solicitation_id: solicitation.id,
        send_id: this.user.id,
        receive_id: this.contact.id,
        archive: null,
        message: null,
        created_at: moment().format('DD/MM/YYYY [às] HH:mm'),
      }

      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        message.archive = URL.createObjectURL(file);
        this.chat.messages.push(Vue.util.extend({}, message));                    
        this.scrollChat(200);
      }

      let { success, content } = await this.send_files(message, files);

      if (!success) {
        return Alert.alert('Oops!', content);
      }

      let send = [];
      content.map((a) => {
      if (a.archive.indexOf('storage/') != -1) {
        const split = a.archive.split('storage/');
        a.archive = `storage/${split[1]}`;
      }
      send.push({
        "method": 'POST',
        "endpoint": '/chat/new_message',
        "content": a,
      })
      });

      for (let i = 0; i < send.length; i++) {
        this.socket.emit('event', send[i]);
      }

      this.selectedFiles = '';
      $('#files-upload').val('');
      this.scrollChat(200);
	  },

	  get_phrases_chat (id, solicitation) {				
		this.responsesDefault = [];

		axios.get('/api/phrase-chats/'+id+'/solicitation/'+solicitation, { headers: { Authorization: this.authorization } })
		.then(response => {
		  console.log('get_phrases_chat');
		  console.log(response.data.content);
		  this.responsesDefault = response.data.content;
		  this.responsesDefault.push({'text': 'Personalizada'});
		})
		.catch(e => {
		  var msg = 'Houve um erro ao buscar as frases do chat! Por favor, tente novamente.'
		  this.$swal("Oops!", msg, "error")
		  console.error(e)
		})
		.finally(() => {
		  
		})
	  },
  
    dialogProposals(item, id = null){
      this.dialog_proposals = true;
      this.getProposals(id);
    },

    dialogOrders(item, id = null){
      // this.panelOrder = [];
      this.dialog_orders = true;
      this.getOrders(id);
    },

    accessOrder(proposal){
      this.dialog_proposals = false;
      this.dialog_orders = true;
      this.getOrders(null, proposal);
    },

    changeStatus: function(proposal, status) {
      this.dialogLoading = true;
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente alterar o status desta proposta?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim!",
        cancelButtonText: "Não!",
      }).then(result => {
        if (result.value) {
          this.loadingStatus = true;
          var dados = {
            'id' : proposal.id,
            'status': status,
            // 'qual': qual
          }
          axios
            .post("/api/proposal/status", dados)
            .then((response) => {
              console.log("status");
              console.log(response.data);
              if (!response.data.errors) {
                this.$toast.success("Status alterado!");

                this.getProposals();
                // ENVIAR MENSAGEM
                if (response.data.message != ''){
                  // this.enviar_mensagem(response.data.message, proposal.id);
                  this.enviar_mensagem(response.data.message, response.data.proposal_id);
                }
              } else {
                this.$toast.warning(response.data.errors);
              }
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao alterar o status.");
              console.log(err)
            })
            .finally(() => {
              this.loadingStatus = false;
              this.dialogLoading = false;
            });
        } else {
          this.dialogLoading = false;
        }
      });
    },

    getProposals(id){
      if (this.contact.id){
        this.loadingProposals = true;
        axios
          .get('/api/proposal/user/'+this.contact.id)
          .then((response) => {
            console.log("getProposals");
            console.log(response.data);
            this.contact.proposals = response.data;
            this.loadingProposals = false;
            if (id){ // ABRIR PROPOSTA ESPECÍFICA
              var self = this;
              response.data.forEach((element, index) => {
                if (element.id == id){
                  this.panel = index;
                }
              });
            }
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar as propostas.");
            this.loadingProposals = false;
            console.log(err)
          })
      }
    },

    getOrders (id = null, proposal = null) {
      this.loadingOrders = true
      axios.get('/api/order/company/'+this.contact.company_id)
      .then(response => {
        console.log('getOrders');
        console.log(response.data.content);
        this.orders = response.data.content;
        if (id || proposal){ // ABRIR PEDIDO ESPECÍFICO
          var self = this;
          response.data.content.forEach((element, index) => {
            if (proposal){
              if (element.proposal_id == proposal){
                this.panelOrder = index;
              }
            } else {
              if (element.id == id){
                this.panelOrder = index;
              }
            }
          });
        }
      })
      .catch(e => {
        var msg = 'Houve um erro ao buscar os pedidos! Por favor, tente novamente.'
        this.$swal("Oops!", msg, "error")
        console.error(e)
      })
      .finally(() => {
        this.loadingOrders = false
      })
    },

    getAllContacts(){
      this.loadingAllContacts = true;
      axios
        .get('/api/chat/all-contacts')
        .then((response) => {
          console.log("getAllContacts");
          console.log(response.data);
          this.allContacts = response.data;
        })
        .catch(e => {
          this.$swal("Oops!", 'Houve um erro ao buscar todos os contatos! Por favor, tente novamente.', "error");
          console.error(e);
        })
        .finally(() => {
          this.loadingAllContacts = false
        })
    },

    dialogAllContacts(){
      this.dialog_all_contacts = true;
      this.getAllContacts();
    },

    attChat(response){
      if (response.message){
        this.enviar_mensagem(response.message, null, response.control, response.type );
      }
    },

    accessProposal(response){
      if (response.proposal){
        this.dialogProposals(null, response.proposal);
      }
    },

    newChat(user){
      this.dialog_all_contacts = false;
      var existe = false;
      this.chats.forEach((element, index) => {
        if (element.id == user.id){
          existe = true;
        }
      });
      if (!existe){
        this.chats.unshift(user);
      }
      this.init_chat(user);
    },
  }
}
</script>

<style lang="scss" scoped>
	.chat_solicitation {
		width: 100%;
		background-color: #fff;
		padding: 0;

		.padding_none {
		  padding-left: 0 !important;
		  padding-right: 0 !important;
		}

		.btn_menu_acoes{
			position: absolute;
			right: 15px;
			top: 22px;
			cursor:pointer;

			i{
				font-size:25px;
			}
		}

		.box-stars{
			i{
				/*cursor: pointer;*/
				padding: 0 2px;
			}

			.fa-star{
				color: #FF8533;
			}
		}

		.form-control{
			border-radius: 20px;
			border: 0;
			padding: 15px;
			font-size: 15px;
		}

		.row{
			margin: 0;
		}

		.box_loading{
			padding: 50px 0;
		}
		
		.box_lista{
			padding: 0;
			min-height: 85vh;
			max-height: 85vh;

			.box_header{
				display: flex;
				align-items: center;
				background-color: #e7e7e7;
				border-left: 1px solid #e0e0e0;
				padding: 0 15px;
				line-height: 55px;
				min-height: 55px;
				max-height: 55px;

				&_text {
					width: 100%;
					background-color: #fff;
					border-top-right-radius: 15px;
					border-bottom-right-radius: 15px;
					padding: 5px 20px;
					margin-left: -15px;
				}
			}

			.box_search{
				// background-color: #f7f7f7;
				// border-left: 1px solid #e0e0e0;
				border-right: 1px solid #e0e0e0;
				// padding: 7px 15px;
				// line-height: 55px;
				// min-height: 55px;
				// max-height: 55px;
			}

			.box_lines{
				border-right: 1px solid #e0e0e0;
				min-height: 69vh;
				max-height: 69vh;
				padding: 0;
				line-height: 0;
				overflow: auto;

				.box_sem_registro{
					text-align: center;
					padding: 12px;
					color: #ccc;
				}

				.line{
					padding: 15px 10px;
					border-bottom: 1px solid #eee;
					display: inline-block;
					overflow:auto;
					cursor: pointer;

					.box_avatar{
						width: 40px;
						height: 40px;
						border-radius: 100%;
						background-color: #ccc;
						margin: 0 5px;
						box-shadow: 1px 1px 3px #ccc;
						float:left;
						margin-right: 10px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
					}

					.box_info{
						float:left;

						.box_nome{
							font-weight: 800;
							float:left;
							width:100%;
						}

						.box_details{
							float:left;
							font-size: 14px;
							width:100%;
						}
					}

					.box_new_messages{
						float:right;
						width: 20px;
						height: 20px;
						background-color: #c56e0d;
						border-radius: 100%;
						text-align:center;
						line-height: 20px;
						padding: 0;
						color:#fff;
						font-size: 14px;
						box-shadow: 1px 1px 3px #ccc;
					}
				}

				.line_active{
					background-color: #ebebeb;
				}

				.new_solicitation{
					animation: pulse 1s infinite;
					z-index: 1;
					border: 1px solid rgba(40, 167, 69, 0.4);
				}
			}
		}
		
		.box_chat{
			padding: 0;
			min-height: 85vh;
			max-height: 85vh;

			.box_avatar_header {
				width: 40px;
				height: 40px;
				border-radius: 100%;
				background-color: #ccc;
				margin: 0 15px 0 0;
				box-shadow: 1px 1px 3px #ccc;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			}

			.box_header{
				background-color: #e7e7e7;
				border-bottom: 1px solid #e0e0e0;
				padding: 7px 15px;
				// min-height: 55px;
				// max-height: 55px;
				// display:flex;
				// justify-content: space-between;

				.box_nome{
					font-weight: 600;
				}

				.box_info{
					font-size: 11px;
				}
			}

			.box_footer{
				background-color: #e7e7e7;
				border-top: 1px solid #e0e0e0;
				padding: 7px 15px;
				line-height: 55px;
				min-height: 55px;
				position:absolute;
				bottom:0;
				display: flex;

				.box_anexo{
					float:left;
					font-size: 22px;
					line-height: 36px;
					margin-right: 15px;
					cursor: pointer;
					background: none;
				border: 0;
				color: #7e7e7e;
				padding: 0;
				}

				.box_message{
					float:left;
					width: 100%;
				}

				.box_send{
					line-height: 32px;
					margin: 0 10px;
					cursor: pointer;
				background: none;
				border: 0;

					img{
						width: 20px;
						vertical-align: middle;
					}
				}
			}

			.box_interno_chat{
				min-height: 80vh;
				max-height: 80vh;
				// background-color: rgba(236, 240, 245, 0.93);
				background-color: #fff;
				padding: 0;
				padding-bottom: 55px;
				// background-image: url('/img/bg_chat.png');
		// background-size: contain;
		// background-position: center;

				.box_header_top{
					position: absolute;
					width: 100%;
					z-index: 1;
					padding-bottom: 10px;
					border-bottom: 1px solid #ccc;
					background-color: #fff;
				}

				.box_header_review{
					margin-top: 30px;
					background-color: #333333;
					border-radius: 35px;
					padding: 10px 15px;
					font-size: 14px;
					text-align: center;
					cursor: pointer;
					color: #fff;

					&_image_info {
						position: absolute;
						top: 6px;
						left: 5px;
						width: 45px;
						height: 45px;
						z-index: 1;
					}

					&_info_text {
						position: absolute;
						top: 20px;
						left: 30px;
						min-width: 100px;
						background-color: #e7e7e7;
						border-radius: 15px;
						color: #333333;
						font-size: 13px;
						font-weight: bold;
						text-transform: uppercase;
						text-align: left;
						padding: 0px 35px;
					}
				}

				.box_aviso{
					text-align:center;
					padding: 32vh 0;

					i{
						font-size: 50px;
					}
					span{
						font-size: 13px;
						margin-top: 15px;
					}
				}

				.box_conversa{
					overflow:auto;
					padding: 15px;
					// padding-top: 100px;
					max-height: 70vh;

					.box_finish_chat{
						display: flex;
						align-items: center;
						justify-content: center;

						.msg{
							box-shadow: 1px 1px 3px #ccc;
							border-radius: 30px;
							font-size: 14px;
							width: 400px;
							text-align: center;
							padding: 10px;
							background-color: #ccc;
							color: #333333;
						}
					}

					.box_line_msg{
						padding: 5px 20px;
						display: inline-block;

						.box_msg{
							width: auto;

							.box_avatar {
								width: 35px;
								height: 35px;
								border-radius: 100%;
								background-color: #ccc;
								margin: 0 10px;
								box-shadow: 1px 1px 3px #ccc;
								background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
							}

							.box_text{
								width: auto;
								float: left;
								border-radius: 30px;
								line-height: 1.3;
								padding: 0;
								box-shadow: 1px 1px 3px #ccc;
								font-size: 14px;
								position:relative;
								min-width: 200px;

								.box_interno_text{
									float: left;
									padding: 10px;
									width: 100%;
									padding-bottom: 3px;
									color: #333333;
								}

								.img_enviada{
									max-width: 250px;
									cursor: pointer;
								}

								.box_time{
									float: left;
									font-size: 10px;
									color: #333333;
									text-align: right;
									width: 100%;
									padding-right: 10px;
								}
							}

							.cor1{
								background-color: #fff;
								border-top-right-radius: 0;
								padding-right: 15px;
							}

							.cor2{
								//background-color: #FF8533;
								//background-color: #d9043d;
				background-color: #eee;
								border-top-left-radius: 0;
								padding-right: 15px;
				// * {
				//   color:#fff !important;
				// }
							}
						}

						.msg1{
							float: right;
						}

						.msg2{
							float: left;
						}
					}
				}
			}
		}

	  @-webkit-keyframes pulse {
		  0% {
			-webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4);
		  }
		  70% {
			  -webkit-box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
		  }
		  100% {
			  -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
		  }
		}

		@keyframes pulse {
		  0% {
			-moz-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4);
			box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4);
		  }
		  70% {
			  -moz-box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
			  box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
		  }
		  100% {
			  -moz-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
			  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
		  }
		}

		.list-enter-active, .list-leave-active {
		  transition: all 0.5s;
		}
		.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
		  opacity: 0;
		  transform: translateY(20px);
		}
  }
  
  .box_lista{
    @media (max-width: 768px) {
      width: 0 !important;
      max-width: 0 !important;
    }
  }
  .expand_list{
    transition: width 0.5s;
    @media (max-width: 768px) {
      width: 90% !important;
      max-width: 90% !important;
      flex: 0 0 90%;
    }
  }

  .box_msg .box_text {
    @media (max-width: 768px) {
      max-width: 85%;
    }
  }

  .box_line_msg{
    @media (max-width: 768px) {
      padding: 5px 0 !important;
    }
  }

  .box_aviso{
    @media (max-width: 768px) {
      padding: 32vh 70px !important;
    }
  }

  .box_header{
    .box_title{
      @media (max-width: 768px) {
        padding-top: 0 !important;
      }
    }
  }
</style>