<template>
  <div>
    <v-card flat class="borda">
      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="save()">
          <v-row justify="center">
            <v-col cols="12" sm="12" md="4" class="mb-n5">
              <span class="font-weight-bold black--text">Produto</span>
              <v-select
                no-data-text="Nenhum produto cadastrado"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="formData.product_id"
                :items="products"
                item-value="id"
                item-text="name"
                @change="selectProduct()"
                :loading="loadingProducts"
                :disabled="loadingProducts || edit != undefined"
                required
                :rules="requiredRules"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="this.formSelected">
            <v-col cols="12" sm="12" md="4" class="mb-n5" v-if="this.formSelected.type">
              <span class="font-weight-bold black--text">{{this.formSelected.type_title}} <span v-if="this.formSelected.type_required">*</span></span>
              <v-select
                no-data-text="Nenhum tipo cadastrado"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="formData.type_id"
                :items="types"
                item-value="id"
                item-text="name"
                :loading="loadingTypes"
                :disabled="loadingTypes || edit != undefined"
                :required="this.formSelected.type_required"
                :rules="ruleType"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="mb-n5" v-if="this.formSelected.category">
              <span class="font-weight-bold black--text">{{this.formSelected.category_title}} <span v-if="this.formSelected.category_required">*</span></span>
              <v-select
                no-data-text="Nenhuma categoria cadastrada"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="formData.category_id"
                :items="categories"
                item-value="id"
                item-text="name"
                :loading="loadingCategories"
                :disabled="loadingCategories || edit != undefined"
                :required="this.formSelected.category_required"
                :rules="ruleCategory"
                @change="getManzanasTable()"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="mb-n5" v-if="this.formSelected.caliber">
              <span class="font-weight-bold black--text">{{this.formSelected.caliber_title}} <span v-if="this.formSelected.caliber_required">*</span></span>
              <v-select
                no-data-text="Nenhuma calibre cadastrado"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="formData.caliber_id"
                :items="calibers"
                item-value="id"
                item-text="value"
                :loading="loadingCalibers"
                :disabled="loadingCalibers || edit != undefined"
                :required="this.formSelected.caliber_required"
                :rules="ruleCaliber"
                @change="getManzanasTable()"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="mb-n5" v-if="this.formSelected.lote">
              <span class="font-weight-bold black--text">{{this.formSelected.lote_title}} <span v-if="this.formSelected.lote_required">*</span></span>
              <v-text-field
                placeholder="Digite o número do lote"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="formData.lote"
                :required="this.formSelected.lote_required"
                :rules="ruleLote"
                :disabled="edit != undefined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="mb-n5" v-if="this.formSelected.brand">
              <span class="font-weight-bold black--text">{{this.formSelected.brand_title}} <span v-if="this.formSelected.brand_required">*</span></span>
              <v-select
                no-data-text="Nenhuma marca cadastrada"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="formData.brand_id"
                :items="brands"
                item-value="id"
                item-text="name"
                :loading="loadingBrands"
                :disabled="loadingBrands || edit != undefined"
                :required="this.formSelected.brand_required"
                :rules="ruleBrand"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="mb-n5" v-for="(item, index) in this.formSelected.customFields" :key="index">
              <span class="font-weight-bold black--text">{{item.title}} <span v-if="item.required">*</span></span>
              <v-select
                v-if="item.type == 2"
                no-data-text="Nenhum registro cadastrado"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="item.value"
                :items="item.values"
                item-value="id"
                item-text="name"
                :required="item.required"
                :disabled="edit != undefined"
              ></v-select>
              <v-text-field
                v-if="item.type == 1"
                placeholder="Digite aqui"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="item.value"
                :required="item.required"
                :disabled="edit != undefined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="mb-n5" v-if="this.formSelected.picture">
              <span class="font-weight-bold black--text">{{this.formSelected.picture_title}} <span v-if="this.formSelected.picture_required">*</span></span>
              <!-- CADASTRAR -->
              <v-file-input
                v-if="!formData.id"
                show-size
                prepend-icon="mdi-camera"
                solo
                v-model="formData.picture"
                outlined
                flat
                dense
                :required="this.formSelected.picture_required"
                :rules="rulePicture"
                @change="previewPicture"
                @click="$event.stopPropagation()"
              ></v-file-input>
              <!-- editar -->
              <v-file-input
                v-if="formData.id"
                show-size
                prepend-icon="mdi-camera"
                solo
                v-model="formData.pictureNew"
                outlined
                flat
                dense
                :required="this.formSelected.picture_required"
                :rules="rulePicture"
                @change="previewPicture"
                @click="$event.stopPropagation()"
              ></v-file-input>
            </v-col>
            <v-col cols="12" sm="12" md="1" class="mb-n5 d-flex align-center" v-if="formData.picture || formData.pictureNew">
              <img
                :src="previewPic"
                class="rounded_img"
                style="cursor: pointer; height: 50px;"
                @click="modalPreview(previewPic)"
              >
              <!-- <v-img
                :src="previewPic"
                :lazy-src="previewPic"
                aspect-ratio="1"
                class=""
                height="50"
                contain
                @click="modalPreview(previewPic)"
                style="cursor: pointer;"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img> -->
              <v-icon
                small
                class="ml-1"
                @click="formData.picture = ''; previewPic = ''; formData.pictureDelete = 1"
              >mdi-delete-outline</v-icon>
            </v-col>
            
            <v-col cols="12" sm="12" md="12" class="mb-n5" justify="center">
              <v-row justify="center">
              <v-tooltip top color="black" v-if="this.formSelected.gallery">
                <template v-slot:activator="{ on, attrs }">
                  <v-badge :content="formData.images.length" :value="formData.images.length" color="#D9043D" overlap left>
                    <v-btn v-bind="attrs" v-on="on" class="mr-3" outlined large fab color="indigo" @click="dialogGallery()">
                      <v-icon>mdi-image-multiple</v-icon>
                    </v-btn>
                  </v-badge>
                </template>
                <span>
                  {{this.formSelected.gallery_title}}
                </span>
              </v-tooltip>
              <v-tooltip top color="black" v-if="this.formSelected.video">
                <template v-slot:activator="{ on, attrs }">
                  <v-badge :content="formData.videos.length" :value="formData.videos.length" color="#D9043D" overlap left>
                    <v-btn v-bind="attrs" v-on="on" class="mr-3" outlined large fab color="indigo" @click="dialogVideo()">
                      <v-icon>mdi-play-box-outline</v-icon>
                    </v-btn>
                  </v-badge>
                </template>
                <span>
                  {{this.formSelected.video_title}}
                </span>
              </v-tooltip>
              </v-row>
            </v-col>

            <v-row class="mt-5">
              <v-col cols="12" sm="12" md="4" class="mb-n5">
                <span class="font-weight-bold black--text">Tipo de Embalagem *</span>
                <v-select
                  no-data-text="Nenhum peso cadastrado"
                  label="Selecione"
                  solo
                  dense
                  flat
                  outlined
                  color="#858585"
                  v-model="formData.package_id"
                  :items="packages"
                  item-value="id"
                  item-text="name"
                  required
                  :rules="requiredRules"
                  :loading="loadingPackages"
                  :disabled="loadingPackages || edit != undefined"
                  @change="getWeights();getManzanasTable()"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="mb-n5">
                <span class="font-weight-bold black--text">Peso da Caixa *</span>
                <v-select
                  no-data-text="Nenhum peso cadastrado"
                  label="Selecione"
                  solo
                  dense
                  flat
                  outlined
                  color="#858585"
                  v-model="formData.weight_id"
                  :items="weights"
                  item-value="id"
                  item-text="value"
                  required
                  :rules="requiredRules"
                  :loading="loadingWeights"
                  :disabled="loadingWeights || edit != undefined"
                  @change="getManzanasTable()"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="mb-n5">
                <span class="font-weight-bold black--text">Preço mínimo ATACADO *</span>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <i class="fa fa-exclamation-circle" v-on="on" style="color: red;font-size: 15px;"></i>
                  </template>
                  <span>Após cadastrado o preço não poderá ser alterado.</span>
                </v-tooltip>
                <span v-if="manzanas_table.atacado" class="pull-right subtitle" style="font-size: 11px;">Tabela Manzanas = <span class="font-weight-bold">R$ {{manzanas_table.atacado_format}}</span></span>
                <money
                  class="input"
                  v-model.lazy="formData.min_atacado"
                  v-bind="moneyFormat"
                  required
                  :rules="requiredRules"
                  :disabled="edit != undefined"
                ></money>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="mb-n5">
                <span class="font-weight-bold black--text">Preço mínimo VAREJO *</span>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <i class="fa fa-exclamation-circle" v-on="on" style="color: red;font-size: 15px;"></i>
                  </template>
                  <span>Após cadastrado o preço não poderá ser alterado.</span>
                </v-tooltip>
                <span v-if="manzanas_table.varejo" class="pull-right subtitle" style="font-size: 11px;">Tabela Manzanas = <span class="font-weight-bold">R$ {{manzanas_table.varejo_format}}</span></span>
                <money
                  class="input"
                  v-model.lazy="formData.min_varejo"
                  v-bind="moneyFormat"
                  required
                  :rules="requiredRules"
                  :disabled="edit != undefined"
                ></money>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="mb-n5">
                <span class="font-weight-bold black--text">Disponível / Embalado *</span>
                <v-text-field
                  placeholder="Digite a quantia de caixas diponíveis"
                  solo
                  dense
                  flat
                  outlined
                  color="#858585"
                  v-model="formData.available"
                  type="number"
                  required
                  :rules="requiredRules"
                  :disabled="edit != undefined"
                  min="0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="mb-n5">
                <span class="font-weight-bold black--text">Projeção à Classificar</span>
                <v-text-field
                  placeholder="Digite a quantia de caixas para projeção"
                  solo
                  dense
                  flat
                  outlined
                  color="#858585"
                  v-model="formData.projection"
                  type="number"
                  :disabled="edit != undefined"
                  min="0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="mb-n5">
                <span class="font-weight-bold black--text">Disponibilidade da Projeção *</span>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <i class="fa fa-question-circle" v-on="on"></i>
                  </template>
                  <span>Refere-se ao dia que a fruta em projeção estará disponível para carregamento (Máx. 7 dias).</span>
                </v-tooltip>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  :disabled="edit != undefined"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      solo
                      flat
                      outlined
                      dense
                      @blur="formData.date_validate = parseDate(dateFormatted)"
                      v-model="dateFormatted"
                      append-icon="mdi-calendar"
                      clearable
                      v-mask="data_mask"
                      v-on="on"
                      @input="menu1 = false"
                      :disabled="edit != undefined"
                      autocomplete="off"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    ref="picker"
                    locale="pt-br"
                    scrollable
                    @input="menu1 = false"
                    :min="minDate"
                    :max="maxDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="mb-n5">
                <span class="font-weight-bold black--text">Dias para carregar *</span>
                <v-text-field
                  placeholder="Digite a quantia de dias para carregamento"
                  solo
                  dense
                  flat
                  outlined
                  color="#858585"
                  v-model="formData.days_load"
                  type="number"
                  required
                  :rules="requiredRules"
                  :disabled="edit != undefined"
                  min="0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="8" class="mb-n5 d-flex align-items-center">
                <v-checkbox v-model="formData.special_offer">
                  <template v-slot:label>
                    <div>
                      Oferta Especial
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <i class="fa fa-question-circle ml-3" v-on="on"></i>
                        </template>
                        <span>A marcar como oferta especial será enviado ao Manzanas para aprovação.</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-checkbox>
                <span class="ml-5" v-if="dateLimitOffer">Sua oferta ficará disponível durante <span class="font-weight-bold">{{setting.days_limit_offer}}</span> dia(s), até <span class="font-weight-bold">{{dateLimitOffer}}</span></span>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="mb-n5">
                <v-switch v-model="formData.status" inset color="success">
                  <template
                    v-slot:label
                  >{{formData.status ? 'Ativo' : 'Inativo'}}</template>
                </v-switch>
              </v-col>
            </v-row>

            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-row justify="center" class="mb-2 mt-5">
                <v-btn
                  color="#95D904"
                  style="color: #FFF !important"
                  type="submit"
                  :loading="loadingSave"
                  :disabled="loadingSave"
                  >
                  SALVAR
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- DIALOG GALERIA DE FOTOS -->
    <v-dialog v-model="dialog_gallery" persistent max-width="800">
      <v-card class="borda" flat min-height="400">
        <v-card-title class="ma-2" v-if="this.formSelected && this.formSelected.gallery">
          {{this.formSelected.gallery_title}}
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_gallery = false">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-file-input
              show-size
              prepend-icon="mdi-camera"
              solo
              v-model="formData.imageSelected"
              outlined
              flat
              dense
              multiple
              accept="image/*"
              @change="previewImage"
              :disabled="formData.images && formData.images.length == 5"
              @click="$event.stopPropagation()"
            ></v-file-input>
          </v-col>
          <v-row class="mt-2 px-5">
            <v-list-item-subtitle v-if="formData.images && formData.images.length > 0" class="p-1">{{formData.images.length}} / 5 limite</v-list-item-subtitle>
            <v-list-item v-for="(item, index) in formData.images" :key="index" class="mb-2 p-2" style="background-color:#eee;">
              <img
                v-if="item.preview"
                :src="item.preview"
                class="rounded_img"
                style="cursor: pointer; max-width: 100px;"
                @click="modalPreview(item.preview)"
              >
              <img
                v-else
                :src="item.url"
                class="rounded_img"
                style="cursor: pointer; max-width: 100px;"
                @click="modalPreview(item.url)"
              >
              <!-- <v-img
                v-if="item.preview"
                :src="item.preview"
                :alt="item.preview"
                :lazy-src="item.preview"
                aspect-ratio="1"
                class=""
                max-width="100"
                contain
                @click="modalPreview(item.preview)"
                style="cursor: pointer;border-radius: 10px !important;"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-img
                v-else
                :src="item.url"
                :alt="item.url"
                :lazy-src="item.url"
                aspect-ratio="1"
                class=""
                max-width="100"
                contain
                @click="modalPreview(item.url)"
                style="cursor: pointer;border-radius: 10px !important;"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img> -->
              <v-list-item-content class="ml-3">
                <v-text-field
                  placeholder="Nome da imagem"
                  color="#858585"
                  v-model="item.name"
                ></v-text-field>
              </v-list-item-content>
              <v-btn icon @click="deleteLineGallery(index)">
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-list-item>
            <v-list-item v-if="!formData.images || formData.images.length == 0">
              <v-list-item-content justify="center">
                <v-list-item-subtitle>Nenhum imagem adicionada</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG GALERIA DE VÍDEOS -->
    <v-dialog v-model="dialog_video" persistent max-width="800">
      <v-card class="borda" flat min-height="400">
        <v-card-title class="ma-2" v-if="this.formSelected && this.formSelected.video">
          {{this.formSelected.video_title}}
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_video = false">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-file-input
              show-size
              prepend-icon="mdi-play-box-outline"
              solo
              v-model="formData.videoSelected"
              outlined
              flat
              dense
              multiple
              accept="video/*"
              @change="previewVideo"
              :disabled="formData.videos && formData.videos.length == 1"
              @click="$event.stopPropagation()"
            ></v-file-input>
          </v-col>
          <v-row class="mt-2 px-5">
            <v-list-item-subtitle class="p-1">Limite: 1 vídeo de até 20 segundos</v-list-item-subtitle>
            <v-list-item v-for="(item, index) in formData.videos" :key="index" class="mb-2 p-2" style="background-color:#eee;">
              <v-icon size="70">mdi-video-box</v-icon>
              <!-- <video height="100" controls v-if="item.video">
                <source :src="item.video">
              </video>
              <video height="100" controls v-if="item.url">
                <source :src="item.url">
              </video> -->
              <v-list-item-content class="ml-3">
                <v-text-field
                  placeholder="Nome do vídeo"
                  color="#858585"
                  v-model="item.name"
                  v-if="item.preview"
                ></v-text-field>
                <!-- {{item.name}} -->
              </v-list-item-content>
              <v-btn icon @click="deleteLineVideo(index)">
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-list-item>
            <v-list-item v-if="!formData.videos || formData.videos.length == 0">
              <v-list-item-content justify="center">
                <v-list-item-subtitle>Nenhum vídeo adicionado</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG PREVIEW IMAGENS -->
    <v-dialog v-model="dialog_preview" persistent max-width="600">
      <v-card class="borda" flat min-height="400">
        <v-card-title class="ma-2">
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_preview = false; imageModalPreview = ''">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" sm="12" md="12" class="">
            <v-img
              :src="imageModalPreview"
              :alt="imageModalPreview"
              :lazy-src="imageModalPreview"
              aspect-ratio="1"
              class=""
              max-width="100%"
              contain
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import moment from 'moment';

export default {
    props: [
      'edit',
      'company',
      'setting',
      'user',
    ],
    data() {        
        return {
          requiredRules: [v => !!v || "Campo Obrigatório."],
          ruleType: [],
          ruleCategory: [],
          ruleCaliber: [],
          ruleLote: [],
          ruleBrand: [],
          rulePicture: [],
          valid: true,
          productForms: [],
          formSelected: '',
          products: [],
          types: [],
          categories: [],
          calibers: [],
          brands: [],
          weights: [],
          packages: [],
          loadingSave: false,
          loadingProducts: false,
          loadingTypes: false,
          loadingCategories: false,
          loadingCalibers: false,
          loadingBrands: false,
          loadingWeights: false,
          loadingPackages: false,
          dialog_gallery: false,
          itemsGallery: [],
          dialog_video: false,
          dialog_preview: false,
          imageModalPreview: '',
          itemsVideo: [],
          formData: {
            id: '',
            company_id: this.company,
            user_id: this.user,
            product_id: '',
            type_id: '',
            category_id: '',
            caliber_id: '',
            lote: '',
            brand_id: '',
            picture: null,
            package_id: '',
            weight_id: '',
            min_atacado: '',
            min_varejo: '',
            available: '',
            projection: '',
            date_validate: '',
            special_offer: '',
            days_load: '',
            date_validate_offer: '',
            images: [],
            videos: [],
            status: 1,
          },
          moneyFormat: {
            decimal: ",",
            thousands: ".",
            prefix: "R$ ",
            precision: 2,
            masked: false,
          },
          menu1: false,
          dateFormatted: "",
          data_mask: "##/##/####",
          date: "",
          previewPic: "",
          manzanas_table: '',
        };
    },
    computed: {
      maxDate(){
        return moment().add(7, 'day').format('YYYY-MM-DD');
      },
      minDate(){
        return moment().format('YYYY-MM-DD');
      },
      dateLimitOffer(){
        if (this.setting){
          if (!this.edit){
            if (this.formData.special_offer && this.setting.days_limit_offer){
              this.formData.date_validate_offer = moment().add(this.setting.days_limit_offer, 'day').format('YYYY-MM-DD');
              return moment().add(this.setting.days_limit_offer, 'day').format('DD/MM/YYYY');
            }
          } else {
            return moment(this.formData.date_validate_offer).format('DD/MM/YYYY');
          }
        }
        this.formData.date_validate_offer = '';
        return null;
      },
    },
    created() {
      console.log("this.edit");
      console.log(this.edit);
      if (this.edit){
        console.log("this.edit");
        console.log(this.edit);
        this.formData = this.edit;
        if (this.edit.date_validate){
          this.date = this.edit.date_validate;
        }
        if (!this.edit.images){
          this.formData.images = [];
        }
        if (!this.edit.videos){
          this.formData.videos = [];
        }
        if (this.edit.picture){
          this.previewPic = this.edit.picture;
        }
      }
      this.getProductForms(this.edit);
    },

    watch: {
      date() {
        this.dateFormatted = this.formatDate(this.date);
        this.formData.date_validate = this.parseDate(
          this.dateFormatted
        );
      },
    },

    methods: {

      stopPropagation(event){
        event.stopPropagation();
      },

      formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      },

      parseDate(date) {
        if (!date) return null;

        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },

      clear(){
        this.formData = {
          id: '',
          company_id: this.company,
          user_id: this.user,
          product_id: '',
          type_id: '',
          category_id: '',
          caliber_id: '',
          lote: '',
          brand_id: '',
          picture: null,
          package_id: '',
          weight_id: '',
          min_atacado: '',
          min_varejo: '',
          available: '',
          projection: '',
          date_validate: '',
          special_offer: '',
          days_load: '',
          date_validate_offer: '',
          images: [],
          videos: [],
          status: 1,
        }
        this.formSelected = '';
        this.$refs.form.resetValidation();
      },

      getProductForms(edit){
        this.loadingProducts = true;
        axios
          .get('/api/product-form')
          .then((response) => {
            // console.log("getProductForms");
            // console.log(response.data);
            this.productForms = response.data;
            var self = this;
            response.data.forEach(element => {
              self.products.push(element.product);
            });
            this.loadingProducts = false;
            if (edit){
              this.selectProduct();
            }
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os formulários.");
            this.loadingProducts = false;
            console.log(err)
          })
      },

      getTypes(){
        this.loadingTypes = true;
        axios
          .get('/api/product-type?product_id='+this.formData.product_id)
          .then((response) => {
            // console.log("getTypes");
            // console.log(response.data);
            this.types = response.data;
            this.loadingTypes = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os tipos.");
            this.loadingTypes = false;
            console.log(err)
          })
      },

      getCategories(){
        this.loadingCategories = true;
        axios
          .get('/api/product-category?product_id='+this.formData.product_id)
          .then((response) => {
            // console.log("getCategories");
            // console.log(response.data);
            this.categories = response.data;
            this.loadingCategories = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar as categorias.");
            this.loadingCategories = false;
            console.log(err)
          })
      },

      getCalibers(){
        this.loadingCalibers = true;
        axios
          .get('/api/product-caliber?product_id='+this.formData.product_id)
          .then((response) => {
            // console.log("getCalibers");
            // console.log(response.data);
            this.calibers = response.data;
            this.loadingCalibers = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os calibres.");
            this.loadingCalibers = false;
            console.log(err)
          })
      },

      getBrands(){
        this.loadingBrands = true;
        axios
          .get('/api/product-brand')
          .then((response) => {
            console.log("getBrands");
            console.log(response.data);
            this.brands = response.data;
            this.loadingBrands = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar as marcas.");
            this.loadingBrands = false;
            console.log(err)
          })
      },

      getWeights(){
        this.loadingWeights = true;
        if (this.formData.package_id){
          axios
            .get('/api/product-weight?product_id='+this.formData.product_id+'&package_id='+this.formData.package_id)
            .then((response) => {
              // console.log("getWeights");
              // console.log(response.data);
              this.weights = response.data;
              this.loadingWeights = false;
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao buscar os pesos.");
              this.loadingWeights = false;
              console.log(err)
            })
        }
      },
      
      getPackages(){
        this.loadingPackages = true;
        this.packages = [];
        axios
          .get('/api/product-package?product_id='+this.formData.product_id)
          .then((response) => {
            console.log("getPackages");
            console.log(response.data);
            this.packages = response.data;
            this.loadingPackages = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar as embalagens.");
            this.loadingPackages = false;
            console.log(err)
          })
      },

      selectProduct(){
        if (this.formData.product_id){
          var self = this;
          this.productForms.forEach(element => {
            if (element.product.id == self.formData.product_id){
              self.formSelected = element;
            }
          });

          if (this.formSelected.customFields && this.formData.custom_fields){
            this.formSelected.customFields.forEach(element => {
              this.formData.custom_fields.forEach(element2 => {
                if (element.id == element2.custom_field_id){
                  if (element.type == 2){ // tipo seleção
                    element.value = parseInt(element2.value);
                  } else { // tipo texto
                    element.value = element2.value;
                  }
                }
              });
            });
          }

          if (this.formSelected.type){
            this.getTypes();
            if (this.formSelected.type_required){
              this.ruleType = [v => !!v || "Campo Obrigatório."];
            }
          }
          if (this.formSelected.category){
            this.getCategories();
            if (this.formSelected.category_required){
              this.ruleCategory = [v => !!v || "Campo Obrigatório."];
            }
          }
          if (this.formSelected.caliber){
            this.getCalibers();
            if (this.formSelected.caliber_required){
              this.ruleCaliber = [v => !!v || "Campo Obrigatório."];
            }
          }
          if (this.formSelected.brand){
            this.getBrands();
            if (this.formSelected.brand_required){
              this.ruleBrand = [v => !!v || "Campo Obrigatório."];
            }
          }
          if (this.formSelected.lote){
            if (this.formSelected.lote_required){
              this.ruleLote = [v => !!v || "Campo Obrigatório."];
            }
          }
          if (this.formSelected.picture){
            if (this.formSelected.picture_required){
              this.rulePicture = [v => !!v || "Campo Obrigatório."];
            }
          }
          // this.getWeights();
          this.getPackages();
        } else {
          this.formSelected = '';
        }
      },

      dialogGallery(){
        this.dialog_gallery = true;
      },

      dialogVideo(){
        this.dialog_video = true;
      },

      previewPicture: function(event) {
        this.previewPic = URL.createObjectURL(event);
      },

      modalPreview: function(image) {
        this.imageModalPreview = image;
        this.dialog_preview = true;
      },

      previewImage: function(event) {
        for (var i = 0; i < event.length; i++) {
          if (i < 5){
            var dados = { name: event[i].name, image: event[i], preview: URL.createObjectURL(event[i]) };
            this.formData.images.push(dados);
          }
        }
        this.formData.imageSelected = [];
      },

      deleteLineGallery(index) {
        this.formData.images.splice(index, 1);
      },

      previewVideo: function(event) {
        console.log("event");
        console.log(event);
        for (var i = 0; i < event.length; i++) {
          if (i < 5){
            var dados = { name: event[i].name, video: event[i], preview: URL.createObjectURL(event[i]) };
            this.formData.videos.push(dados);
          }
        }
        console.log("this.formData.videos");
        console.log(this.formData.videos);
        this.formData.videoSelected = [];
      },

      deleteLineVideo(index) {
        this.formData.videos.splice(index, 1);
      },

      getManzanasTable(){        
        // this.loadingBrands = true;
        if (this.formData.category_id && this.formData.caliber_id && this.formData.weight_id && this.formData.package_id){
          var dados = {
            'category_id': this.formData.category_id,
            'caliber_id': this.formData.caliber_id,
            'weight_id': this.formData.weight_id,
            'package_id': this.formData.package_id,
          }
          axios
            .post('/api/manzanas-table/filter', dados)
            .then((response) => {
              console.log("getManzanasTable");
              console.log(response.data);
              if (response.data){
                this.manzanas_table = response.data;
              } else {
                this.manzanas_table = '';
              }
              // this.loadingBrands = false;
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao buscar dados da tabela manzanas.");
              // this.loadingBrands = false;
              console.log(err)
            })
        }
      },

      save (){
        if (this.$refs.form.validate()) {
          this.loadingSave = true;
          if (this.formSelected.customFields){
            this.formData.customFields = this.formSelected.customFields;
          }
          console.log("this.formData");
          console.log(this.formData);
          var endpoint = "";
          var msg = "";
          if (!this.formData.id) {
            // CADASTRAR
            endpoint = "/api/company-product";
            msg = "Produto cadastrado com sucesso! :)";
          } else {
            // EDITAR
            endpoint = "/api/company-product/" + this.formData.id;
            msg = "Produto editado com sucesso! :)";
          }

          let formData = new FormData();
          Object.keys(this.formData).map((key) => {
            if (key === "picture") {
                let file = this.formData.picture;
                if (this.formData.picture) {
                  if (this.formData.picture.name) {
                    formData.append(
                      `picture[]`,
                      file
                    );
                  }
                }
            } else if (key === "pictureNew") {
                let file = this.formData.pictureNew;
                if (this.formData.pictureNew) {
                  formData.append(
                    `picture[]`,
                    file
                  );
                }
            } else if (key === "images") {
              for (let i = 0; i < this.formData.images.length; i++) {
                let file = this.formData.images[i].image;
                if (this.formData.images[i].image) {
                //if (this.formData.images[i].image.name) {
                  formData.append(
                    `images[]`,
                    file
                  );
                }
                if (this.formData.images[i].image) {
                  formData.append(
                    `nameImages[]`,
                    this.formData.images[i].name
                  );
                }
              }
            } else if (key === "videos") {
              for (let i = 0; i < this.formData.videos.length; i++) {
                let file = this.formData.videos[i].video;
                formData.append(
                  `videos[]`,
                  file
                );
                if (this.formData.videos[i].video) {
                  formData.append(
                    `nameVideos[]`,
                    this.formData.videos[i].name
                  );
                }
              }
            } else if (key === "customFields") {
              for (let i = 0; i < this.formData.customFields.length; i++) {
                formData.append(
                  `custom_fields[]`,
                  JSON.stringify(this.formData.customFields[i])
                );
              }
            } else {
              if (this.formData[key]) {
                formData.append(key, this.formData[key]);
              }
            }
          });

          axios
            .post(endpoint, formData)
            .then((response) => {
              console.log("save");
              console.log(response.data);
              if (!response.data.errors) {
                this.$toast.success(msg);
                // setTimeout(function(){
                //   window.location.href = "/products";
                //   this.clear();
                // }, 2000);
                window.location.href = "/products";
              } else {
                this.$toast.warning(response.data.errors);
              }
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao cadastrar/editar o produto.");
              console.log(err)
            })
            .finally(() => {
              this.loadingSave = false;
            });
        }
      },

    },
        
}
</script>
<style lang="scss" scoped>

</style>