<template>
  <div class="box box-widget">
    <div class="box-header">
      <div class="row">
        <div class="col-md-8 col-sm-6">
          <h3 class="box-title">{{title}}</h3>
        </div>
        <div v-if="busca != 'false'" class="col-md-4 col-sm-6">
          <form method="GET" class="form-horizontal" :action="url">
            <div class="input-group">
              <input
                type="text"
                name="busca"
                class="form-control"
                placeholder="Buscar por"
                v-model="search"
              />
              <span class="input-group-btn">
                <button type="submit" class="btn btn-primary btn-flat">
                  <i class="fa fa-search"></i>
                </button>
                <a
                  :href="url"
                  class="btn btn-default btn-flat"
                  data-toggle="tooltip"
                  title="Limpar Busca"
                >
                  <i class="fa fa-times"></i>
                </a>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="box-body">
      <slot></slot>

      <v-data-table
        no-data-text="Nenhuma empresa cadastrada"
        hide-default-footer
        calculate-widths
        :headers="header"
        :items="items.data"
        :items-per-page="items_per_page"
        :search="search"
        no-results-text="Nenhum registro encontrado"
        class="elevation-0 table-striped"
        loading-text="Carregando itens..."
        :header-props="{ sortByText: 'Ordenar por' }"
      >
        <template v-slot:item.type="{ item }">
          <v-chip small class="font-weight-bold" text-color="white" :color="item.type.color">{{item.type.text}}</v-chip>
        </template>
        <template v-slot:item.approved="{ item }">
          <v-chip small class="" :color="item.approved.status">{{item.approved.text}}</v-chip>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip small class="" text-color="white" :color="item.status.status">{{item.status.text}}</v-chip>
        </template>
        <template v-slot:item.acao="{ item }">
          <!-- <a
            class="btn btn-flat btn-primary"
            :href="url + '/' + item.id + '/edit'"
            data-toggle="tooltip"
            title="Editar"
          >
            <i class="fa fa-pencil"></i>
          </a> -->
          <button
            class="btn btn-flat btn-info"
            data-toggle="tooltip"
            title="Editar"
            @click="showDetails(item.id)"
            style="color:#fff !important;"
            type="button"
          >
            <i class="fa fa-eye"></i>
          </button>
          <button
            v-if="item.approved.text == 'Aprovado'"
            class="btn btn-flat btn-secondary"
            data-toggle="tooltip"
            title="Reenviar cadastro de senha"
            @click="resendPassword(item.id)"
            style="color:#fff !important;"
            :class="'password_'+item.id"
          >
            <i class="fa fa-asterisk"></i>
          </button>
          <button
            v-if="item.approved.text == 'Aprovado'"
            class="btn btn-flat btn-success"
            data-toggle="tooltip"
            title="Reenviar e-mail de aprovação"
            @click="resendEmail(item.id)"
            style="color:#fff !important;"
            :class="'resend_'+item.id"
          >
            <i class="fa fa-paper-plane-o"></i>
          </button>
          <button
            class="btn btn-flat btn-primary"
            data-toggle="tooltip"
            title="Aprovar cadastro"
            @click="approveRegistration(item.id)"
            v-if="item.approved.text != 'Aprovado'"
            :disabled="loadingApprove"
            style="color:#fff !important;"
            :class="'approve_'+item.id"
          >
            <i class="fa fa-check"></i>
          </button>
          <button
            class="btn btn-flat btn-danger"
            data-toggle="tooltip"
            title="Reprovar cadastro"
            @click="disapproveRegistration(item.id, index)"
            v-if="item.approved.text != 'Reprovado'"
            :disabled="item.approved.loading"
            style="color:#fff !important;"
            :class="'disapprove_'+item.id"
          >
            <i class="fa fa-ban"></i>
          </button>
          <span v-if="item.approved.loading">Loading</span>
        </template>

        <template v-slot:footer>
          <v-row justify="center" class="mb-2">
            <v-btn
              v-if="items_per_page > 10"
              @click="items_per_page -= 10"
              text
            >Mostrar menos</v-btn>
            <v-btn
              v-if="items.data.length > items_per_page"
              @click="items_per_page += 10"
              text
            >Carregar mais</v-btn>
          </v-row>
        </template>
      </v-data-table>
    </div>

    <!-- DIALOG DETAILS COMPANY -->
    <v-dialog v-model="dialogCompany" persistent max-width="850">
      <v-card class="borda" flat min-height="400" :loading="loadingDetails">
        <v-card-title class="ma-2">
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialogCompany = false;">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="dialogCompany">
          <config-company :company="company"></config-company>
          <!-- <v-col cols="12" sm="12" md="12" class="">
            <div class="text--primary">
              Razão Social: {{details.corporate_name}}<br>
              CNPJ: {{details.cnpj}}<br>
              IE: {{details.ie ? details.ie : '-'}}<br>
              Telefone: {{details.phone}}<br>
              E-mail: {{details.email}}<br>
            </div>
          </v-col>
          <v-row justify="center">
            <v-col cols="12" sm="12" md="4" class="" v-for="(item, index) in details.contacts" :key="index">
              <v-card class="mx-auto mb-3" min-height="160">
                <v-card-text>
                  <div>Contato <v-chip x-small class="" color="#17a2b8" text-color="white" v-if="item.main">Principal</v-chip></div>
                  <p class="headline text--primary">{{item.name}}</p>
                  <div class="text--primary">
                    CPF: {{item.cpf}}<br>
                    Telefone: {{item.phone}}<br>
                    E-mail: {{item.email}}<br>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" sm="12" md="4" class="" v-for="(item, index) in details.addresses" :key="index">
              <v-card class="mx-auto mb-3" min-height="160">
                <v-card-text>
                  <div>Endereço {{index+1}}</div>
                  <p class="headline text--primary">{{item.cep}}</p>
                  <div class="text--primary">
                    {{item.street}}, {{item.number}}, {{item.district}}<br>
                    <span v-if="item.complement">{{item.complement}}</span>
                    {{item.city}} - {{item.state}}, {{item.country}}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}
.v-middle {
  vertical-align: middle !important;
}
</style>

<script>
import axios from "axios";

export default {
  props: [
    "title",
    "titles",
    "items",
    "busca",
    "url",
    "token",
    "formClass",
    "notDeletable",
    "actions",
  ],
  data: function() {
    return {
      deleteItems: [],
      check_delete: [],
      btnDelete: {
        icon: "fa fa-trash",
        text: "Excluir registros selecionados",
        disabled: false
      },
      loadingApprove: false,
      loadingDisapprove: false,
      loadingResend: false,
      loadingDetails: false,
      dialogCompany: false,
      company: '',
      details: '',
      header: [
        { text: "#", align: "left", value: "id", },
        { text: "Nome", align: "left", value: "fantasy_name", },
        { text: "Atuação", align: "left", value: "type", },
        { text: "Aprovação", align: "left", value: "approved", },
        { text: "Status", align: "left", value: "status", },
        { text: "Ações", align: "left", sortable: false, value: "acao", },
      ],
      items_per_page: 10,
      search: '',
    };
  },
  created() {
    if (!this.items.data) {
      this.items.data = this.items;
    }
  },
  methods: {
    checkAll: function() {
      var checkboxOrigin = document.getElementById("checkbox-delete-origin");
      var checkboxes = document.getElementsByClassName("checkbox-delete");
      this.check_delete = [];
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        if (checkboxOrigin.checked){
          if (parseInt(checkboxes[i].value)){
            this.check_delete.push(parseInt(checkboxes[i].value));
          }
        } else {
          this.check_delete = [];
        }
        checkboxes[i].checked = checkboxOrigin.checked;
      }
    },

    confirmDelete: function(e) {
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente excluir os registros selecionados?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, deletar!",
        cancelButtonText: "Não!"
      }).then(result => {
        if (result.value) {
          e.target.submit(), (this.btnDelete.icon = "fa fa-spinner fa-pulse");
          this.btnDelete.text = "Excluindo";
          this.btnDelete.disabled = true;
        }
      });
    },

    approveRegistration: function(id) {
      $('.approve_'+id).html('<div class="spinner-grow spinner-grow-sm" role="status"></div>');
      $('.approve_'+id).prop( "disabled", true );
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente aprovar o cadastro desta empresa?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, aprovar!",
        cancelButtonText: "Não!",
      }).then(result => {
        if (result.value) {
          var dados = {
            'id' : id
          }
          axios
            .post("/api/company/approve", dados)
            .then((response) => {
              console.log("approve");
              console.log(response.data);
              if (!response.data.errors) {
                this.$toast.success("Aprovado!");

                // setTimeout(function(){
                //   window.location.href = "/products";
                //   this.clear();
                // }, 2000);
                window.location.href = "/cms/dashboard";
              } else {
                this.$toast.warning(response.data.errors);
              }
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao aprovar o cadastro.");
              console.log(err)
            })
            .finally(() => {
              $('.approve_'+id).html('<i class="fa fa-check"></i>');
              $('.approve_'+id).prop( "disabled", false );
            });
        } else {
          $('.approve_'+id).html('<i class="fa fa-check"></i>');
          $('.approve_'+id).prop( "disabled", false );
        }
      });
    },

    disapproveRegistration: function(id, index) {
      $('.disapprove_'+id).html('<div class="spinner-grow spinner-grow-sm" role="status"></div>');
      $('.disapprove_'+id).prop( "disabled", true );
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente reprovar o cadastro desta empresa?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, reprovar!",
        cancelButtonText: "Não!",
        input: 'text',
        inputPlaceholder: 'Informe o motivo',
        inputValidator: (value) => {
          if (!value) {
            return 'Informe o motivo!'
          }
        }
      }).then(result => {
        if (result.value) {
          var dados = {
            'id' : id,
            'reason': result.value
          }
          axios
            .post("/api/company/disapprove", dados)
            .then((response) => {
              console.log("disapprove");
              console.log(response.data);
              if (!response.data.errors) {
                this.$toast.success("Reprovado!");
                window.location.href = "/cms/dashboard";
              } else {
                this.$toast.warning(response.data.errors);
              }
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao reprovar o cadastro.");
              console.log(err)
            })
            .finally(() => {
              $('.disapprove_'+id).html('<i class="fa fa-ban"></i>');
              $('.disapprove_'+id).prop( "disabled", false );
            });
        } else {
          $('.disapprove_'+id).html('<i class="fa fa-ban"></i>');
          $('.disapprove_'+id).prop( "disabled", false );
        }
      });
    },
    
    resendEmail: function(id) {
      $('.resend_'+id).html('<div class="spinner-grow spinner-grow-sm" role="status"></div>');
      $('.resend_'+id).prop( "disabled", true );
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente reenviar o e-mail de aprovação?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, enviar!",
        cancelButtonText: "Não!",
      }).then(result => {
        if (result.value) {
          var dados = {
            'id' : id
          }
          axios
            .post("/api/company/resend-email", dados)
            .then((response) => {
              console.log("resend-email");
              console.log(response.data);
              this.$toast.success("E-mail reenviado!");
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao reenviar o e-mail para aprovação.");
              console.log(err)
            })
            .finally(() => {
              $('.resend_'+id).html('<i class="fa fa-paper-plane-o"></i>');
              $('.resend_'+id).prop( "disabled", false );
            });
        } else {
          $('.resend_'+id).html('<i class="fa fa-paper-plane-o"></i>');
          $('.resend_'+id).prop( "disabled", false );
        }
      });
    },

    resendPassword: function(id) {
      $('.password_'+id).html('<div class="spinner-grow spinner-grow-sm" role="status"></div>');
      $('.password_'+id).prop( "disabled", true );
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente reenviar o e-mail de senha para o contato principal?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, enviar!",
        cancelButtonText: "Não!",
      }).then(result => {
        if (result.value) {
          var dados = {
            'id' : id
          }
          axios
            .post("/api/company/resend-password", dados)
            .then((response) => {
              console.log("resendPassword");
              console.log(response.data);
              this.$toast.success("E-mail reenviado!");
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao reenviar o e-mail de senha.");
              console.log(err)
            })
            .finally(() => {
              $('.password_'+id).html('<i class="fa fa-asterisk"></i>');
              $('.password_'+id).prop( "disabled", false );
            });
        } else {
          $('.password_'+id).html('<i class="fa fa-asterisk"></i>');
          $('.password_'+id).prop( "disabled", false );
        }
      });
    },

    showDetails: function(item){
      this.dialogCompany = true;
      this.company = item;
      // this.getCompany();
    },

    getCompany() {
      this.loadingDetails = true;
      this.details = '';

      axios.get('/api/company/' + this.company)
      .then(response => {
        // console.log('getCompany');
        // console.log(response.data);
        if (response.data){
          this.details = response.data;
        }
      })
      .catch(e => {
        var msg = 'Houve um erro ao buscar os detalhes da empresa! Por favor, tente novamente.'
        this.$swal("Oops!", msg, "error")
        console.error(e)
      })
      .finally(() => {
        this.loadingDetails = false;
      })
    },
  }
};
</script>