<template>
  <div>
    <div class="box box-widget">
      <div class="box-header">
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <h3 class="box-title">{{title}}</h3>
            <v-progress-circular
              :width="2"
              :size="25"
              class="mt-1 mr-5 pull-right"
              indeterminate
              color="primary"
              v-if="loadingFilter"
            ></v-progress-circular>
          </div>
          <div v-if="busca != 'false'" class="col-md-8 col-sm-12">
            <form ref="formFilters" method="GET" class="form-horizontal" :action="url">
              <div class="input-group">
                <v-radio-group name="filter" v-if="company" v-model="filterList" row class="p-0 mr-5" @change="changeFilter()" style="margin-top: -7px;" :readonly="loadingFilter">
                  <v-radio label="Pedidos de Compra" value="compra" class="pr-5"></v-radio>
                  <v-radio label="Pedidos de Venda" value="venda"></v-radio>
                  <v-badge color="#95d904" left dot v-if="ordersOpened" offset-x="-8" offset-y="3"></v-badge>
                </v-radio-group>
                <input
                  type="text"
                  name="busca"
                  class="form-control"
                  placeholder="Buscar por"
                  v-model="search"
                />
                <span class="input-group-btn">
                  <button type="submit" class="btn btn-primary btn-flat">
                    <i class="fa fa-search"></i>
                  </button>
                  <a
                    :href="url"
                    class="btn btn-default btn-flat"
                    data-toggle="tooltip"
                    title="Limpar Busca"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="box-body">
        <slot></slot>

        <v-data-table
          no-data-text="Nenhum pedido cadastrado"
          hide-default-footer
          calculate-widths
          :headers="header"
          :items="items.data"
          :items-per-page="items_per_page"
          :search="search"
          no-results-text="Nenhum registro encontrado"
          class="elevation-0 table-striped"
          loading-text="Carregando itens..."
          :header-props="{ sortByText: 'Ordenar por' }"
        >
          <template v-slot:item.status="{ item }">
            <v-chip small class="" text-color="white" :color="item.status.color">{{item.status.text}}</v-chip>
          </template>
          <template v-slot:item.company_id="{ item }">
            <v-chip v-if="item.company_id" small class="" :color="item.company_id.status">{{item.company_id.text}}</v-chip>
          </template>
          <template v-slot:item.seller_id="{ item }">
            <v-chip v-if="item.seller_id" small class="" :color="item.seller_id.status">{{item.seller_id.text}}</v-chip>
          </template>
          <template v-slot:item.acao="{ item }">
          <button
              class="btn btn-flat btn-info"
              data-toggle="tooltip"
              title="Detalhes do pedido"
              @click="detailOrder(item)"
              style="color:#fff !important;"
            >
              <i class="fa fa-eye"></i>
            </button>
          </template>

          <template v-slot:footer>
            <v-row justify="center" class="mb-2">
              <v-btn
                v-if="items_per_page > 10"
                @click="items_per_page -= 10"
                text
              >Mostrar menos</v-btn>
              <v-btn
                v-if="items.data.length > items_per_page"
                @click="items_per_page += 10"
                text
              >Carregar mais</v-btn>
            </v-row>
          </template>
        </v-data-table>
        
      </div>
    </div>

    <v-dialog v-model="dialogOrder" max-width="800">
      <v-card class="borda" flat min-height="400">
        <v-card-title class="ma-2" v-if="order">
          <div style="flex: 0 1 auto;" class="mr-3">
            Pedido {{order.id}}
          </div>
          <div style="flex: 0 1 auto;" class="mr-3" v-if="order.status">
            <v-chip small :color="order.status.color" text-color="white">{{order.status.text}}</v-chip>
          </div>
          <v-spacer></v-spacer>
          <span style="font-size: 13px;" v-if="order.created_at">
            Solicitado em
            <span class="font-weight-bold">{{order.created_at}}</span>
          </span>
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialogOrder = false;">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="order">

          <details-order :order="order.id" v-if="dialogOrder" :user="user" :company="company" @openProposalChild="detailProposal"></details-order>

        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogProposal" max-width="800">
      <v-card class="borda" flat min-height="400">
        <v-card-title class="ma-2" v-if="proposal">
          <div style="flex: 0 1 auto;" class="mr-3">
            Proposta {{proposal.id}}
          </div>
          <div style="flex: 0 1 auto;" class="mr-3" v-if="proposal.status">
            <v-chip small :color="proposal.status.color" text-color="white">{{proposal.status.text}}</v-chip>
          </div>
          <v-spacer></v-spacer>
          <span style="font-size: 13px;" v-if="proposal.created_at">
            Criada em
            <span class="font-weight-bold">{{proposal.created_at}}</span>
          </span>
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialogProposal = false;">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="proposal">

          <details-proposal :proposal="proposal.id" v-if="dialogProposal" :user="user" :company="company"></details-proposal>

        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}
.v-middle {
  vertical-align: middle !important;
}
</style>

<script>
import axios from "axios";
import io from 'socket.io-client';
import moment from 'moment';

export default {
  props: [
    "title",
    "titles",
    "items",
    "busca",
    "url",
    "token",
    "formClass",
    "notDeletable",
    "actions",
    "user",
    "company",
    "filter",
  ],
  data: function() {
    var tunnel = 'https://tunnel.d3t.com.br';
    var api = 'https://manzanas.app/api';

    if (window.location.hostname == 'localhost'){
      tunnel = 'http://172.20.0.1:5555';
      api = 'http://172.24.0.1/api';
    }
    return {
      deleteItems: [],
      check_delete: [],
      order: '',
      loadingOrder: false,
      dialogOrder: false,
      dialogProposal: false,
      proposal: '',
      socket: io(tunnel, {
        query: `domain=${api}&authorization=${this.token}`
      }),
      filterList: 'compra',
      filters: [
        {"value": 'compra', "text": "Meus pedidos de Compra"},
        {"value": 'venda', "text": "Meus pedidos de Venda"},
      ],
      loadingFilter: false,
      header: this.company ? [
        { text: "#", align: "left", value: "id", },
        { text: this.filter == 'venda' ? 'Comprador' : 'Vendedor', align: "left", value: this.filter == 'venda' ? 'company_id' : 'seller_id', },
        { text: "Produtos", align: "left", value: "products", },
        { text: "Valor Total", align: "left", value: "value", },
        { text: "Total Caixas", align: "left", value: "boxes", },
        { text: "Data/Hora", align: "left", value: "created_at", },
        { text: "Status", align: "left", value: "status", },
        { text: "Ações", align: "left", sortable: false, value: "acao", },
      ] : [
        { text: "#", align: "left", value: "id", },
        { text: 'Comprador', align: "left", value: "company_id", },
        { text: 'Vendedor', align: "left", value: "seller_id", },
        { text: "Produtos", align: "left", value: "products", },
        { text: "Valor Total", align: "left", value: "value", },
        { text: "Total Caixas", align: "left", value: "boxes", },
        { text: "Data/Hora", align: "left", value: "created_at", },
        { text: "Status", align: "left", value: "status", },
        { text: "Ações", align: "left", sortable: false, value: "acao", },
      ],
      items_per_page: 10,
      search: '',
      ordersOpened: 0,
    };
  },
  created() {
    if (this.filter){
      this.filterList = this.filter;
    }
    if (!this.items.data) {
      this.items.data = this.items;
    }
    if (this.company){
      this.getOrdersOpened();
    }
  },
  methods: {
    detailOrder: function(item){
      this.dialogOrder = true;
      this.order = [];
      this.order = item;
    },

    changeFilter(){
      this.loadingFilter = true;
      var self = this;
      setTimeout(function(){
        console.log(self.filterList);
        self.$refs.formFilters.submit();
      }, 100)
    },

    enviar_mensagem (status, reason = null) {
      if (status == 'approve'){
        var message = '<span class="title_chat_proposal">********** OFERTA **********</span><br/>Sua solicitação de cadastro da oferta <span class="font-weight-bold">'+this.offer.id+'</span> foi APROVADA! :)<br/>';
      } else if (status == 'disapprove'){
        var message = '<span class="title_chat_proposal">********** OFERTA **********</span><br/>Sua solicitação de cadastro da oferta <span class="font-weight-bold">'+this.offer.id+'</span> foi REPROVADA! :(<br/> Motivo: '+reason;
      }

      const data = {
        method: 'POST',
        endpoint: '/chat/new_message',
        
        content: {
          send_id: 1,
          receive_id: this.offer.user_id.id,
          archive: null,
          message: message,
          created_at: moment().format('YYYY-MM-DD HH:mm'),
        }
      }
      console.log("data");
      console.log(data);

      this.socket.emit('event', data);

      window.location.reload(true);
    },

    detailProposal: function(){
      this.dialogProposal = true;
      this.proposal = '';
      this.proposal = this.order.proposal;
    },

    getOrdersOpened: function(){
      axios
        .get('/api/order/opened/'+this.company)
        .then((response) => {
          console.log("getOrdersOpened");
          console.log(response.data);
          this.ordersOpened = response.data;
        })
        .catch(err => {
          this.$toast.error("Ocorreu um erro ao buscar os pedidos abertos.");
          console.log(err)
        })
    },
    
  }
};
</script>