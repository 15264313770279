<template>
<div class="w-100">

  <!-- CABEÇALHO -->
  <section class="content-header custom_header" v-bind:class="{ 'fixed_header': $vuetify.breakpoint.mobile }">
    <div class="box_img" v-if="!$vuetify.breakpoint.mobile">
      <img src="img/chat.png" class="">
    </div>
    <div class="box_line">
      <v-row class="ml-0 mr-0">
        <v-col cols="12" sm="12" md="6" class="pb-0 pt-0 box_title">
          <div>
            <div class="title" style="font-size: 15px !important;">Conversando com:</div>
            <div class="description" style="font-size: 20px;">Manzanas</div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="pb-0 box_buttons">
          <v-btn
            color="primary"
            type="button"
            class="pull-right mt-1 mr-4"
            @click="dialogProposals()"
          >
            <!-- {{proposals.length}} {{proposals.length > 1 ? 'propostas' : 'proposta'}} -->
            propostas
          </v-btn>
          <v-btn
            color="#95D904"
            style="color: #FFF !important"
            type="button"
            class="pull-right mt-1 mr-2"
            @click="dialogOrders()"          
          >
            <!-- {{orders.length}} {{orders.length > 1 ? 'pedidos' : 'pedido'}} -->
            pedidos
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </section>

  <!-- CHAT -->
	<div class="col-12 chat_solicitation" v-bind:class="{ 'space_top': $vuetify.breakpoint.mobile }">
		<div class="row align-items-center">
			<div class="col-12 box_chat">
				<div class="col-12 box_interno_chat">
					<div v-if="loadingChat" class="d-flex justify-content-center box_loading" style="padding: 32vh 0;">
						<div class="spinner-grow text-secondary" style="width: 5rem; height: 5rem;" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
					<div class="col-12 box_aviso" v-if="!chat.user && !loadingChat">
						<i class="fa fa-info-circle" aria-hidden="true"></i><br/>
						<span>Clique em um item do lado esquerdo para inicar uma interação.</span>
					</div>
					<div v-if="chat.user" class="row align-items-end box_conversa">
						<transition-group name="list" style="width:100%;">
							<div class="col-12 box_line_msg" v-for="(c, index) in chat.messages" :key="index">
								<div class="box_msg" :class="c.send_id == user.id ? 'msg1' : 'msg2'">

                  <v-avatar size="35" class="mr-3 pull-left" color="grey" v-if="c.send_id != user.id">
                    <v-img
                      :src="c.send_avatar"
                      :alt="c.send_avatar"
                      :lazy-src="c.send_avatar"
                      v-if="c.send_avatar"
                      >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular indeterminate color="grey lighten-5" size="20" width="3"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-icon dark v-if="!c.send_avatar">
                      mdi-account
                    </v-icon>
                  </v-avatar>

									<div class="box_text" :class="c.send_id == user.id ? 'cor1' : 'cor2'">
										<!-- <div class="box_interno_text" v-if="c.message" v-html="verifMessage(c.message)"></div> -->
                    <div class="box_interno_text" v-if="c.message && !c.type" v-html="c.message"></div>
                    <!-- MENSAGENS DINAMICAS -->
										<div class="box_interno_text" v-if="c.message && c.type">
                      <!-- EXIBIR MENSAGEM -->
                      <div v-html="c.message"></div>
                      <!-- NOVA PROPOSTA -->
                      <div v-if="c.type.includes('proposal')">
                        <a v-if="c.control" class="text-underline" @click="dialogProposals(contact, c.control)">Acessar proposta</a>
                      </div>
                      <!-- PEDIDO -->
                      <div v-if="c.type.includes('order')">
                        <a v-if="c.control" class="text-underline" @click="dialogOrders(contact, c.control)">Acessar pedido</a>
                      </div>
                    </div>
										<div class="box_interno_text" style="text-align: center;" v-if="c.archive">
											<img class="img_enviada" :src="c.archive" data-toggle="modal" data-target="#previewImageModal" @click="imagePreview = c.archive">
										</div>
										<div class="box_time">
											{{c.created_at}}
										</div>
									</div>

                  <v-avatar size="35" class="ml-3 pull-left" color="grey" v-if="c.send_id == user.id">
                    <v-img
                      :src="c.send_avatar"
                      :alt="c.send_avatar"
                      :lazy-src="c.send_avatar"
                      v-if="c.send_avatar"
                      >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular indeterminate color="grey lighten-5" size="20" width="3"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-icon dark v-if="!c.send_avatar">
                      mdi-account
                    </v-icon>
                  </v-avatar>
								</div>
							</div>
						</transition-group>
					</div>
					<div v-if="chat.user" class="col-12 box_footer">
						<button class="box_anexo" @click="clickSelectFiles()">
							<i class="fa fa-paperclip" aria-hidden="true"></i>
							<input id="files-upload" name="file" type="file" accept="image/*" @change="selected_images(user)" style="display: none;" multiple/>
						</button>
						<div class="box_message">
							<input type="search" autofocus v-model="message" id="input_message" class="form-control pull-left" placeholder="Digite uma mensagem e pressione ENTER para enviar" v-on:keyup.enter="enviar_mensagem(message)">
						</div>
						<button class="box_send d-flex justify-content-center align-items-center" @click="enviar_mensagem(message)">
              <v-icon>
                mdi-send
              </v-icon>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

  <!-- DIALOG PROPOSALS -->
  <v-dialog persistent v-model="dialog_proposals" max-width="800">
    <v-card class="borda" flat min-height="250" :loading="loadingProposals">
      <v-card-title class="ma-2">
        Minhas propostas
        <v-spacer></v-spacer>
        <v-btn icon text @click="dialog_proposals = false;">
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-col cols="12" sm="12" md="12" class="pa-0">
          <v-expansion-panels
            v-model="panel"
          >
            <v-expansion-panel v-for="(item, index) in proposals" :key="index">
              <v-expansion-panel-header>
                <div style="flex: 0 1 auto;" class="mr-2">
                  #{{item.id}}
                </div>
                <div style="flex: 0 1 auto;" class="mr-3">
                  <span class="font-weight-bold">{{item.proposal_product[0].company_product.product.name}}</span>
                </div>
                <div style="flex: 0 1 auto;" class="mr-3">
                  <span>{{item.proposal_product.length}} {{item.proposal_product.length > 1 ? 'produtos' : 'produto'}}</span>
                </div>
                <div style="flex: 0 1 auto;" class="">
                  <v-chip small :color="item.status.color" text-color="white" v-if="!$vuetify.breakpoint.mobile">{{item.status.text}}</v-chip>
                  <v-chip x-small :color="item.status.color" text-color="white" v-if="$vuetify.breakpoint.mobile">{{item.status.text}}</v-chip>
                </div>
                <v-spacer></v-spacer>
                <div style="flex: 0 1 auto;" class="mr-3" v-if="!$vuetify.breakpoint.mobile">
                  <span style="font-size:11px;">publicado por</span>
                  <span class="font-weight-bold">{{item.proposal_product[0].company_product.company.fantasy_name}}</span>
                </div>
                <template v-slot:actions>
                  <v-icon>mdi-chevron-down</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>

                <details-proposal :proposal="item.id" v-if="panel == index" :user="user" :company="company"></details-proposal>

                <!-- <v-col sm="12" md="12" class="px-0 py-3 text-center" v-if="item.status.status == 2">
                  <v-btn
                    color="#95D904"
                    style="color: #FFF !important"
                    type="button"
                    class=""
                    :loading="loadingStatus"
                    :disabled="loadingStatus"
                    @click="dialogPedido(item)"
                  >
                    SOLICITAR PEDIDO
                  </v-btn>
                  <hr/>
                </v-col>
                <v-col sm="12" md="12" justify="center" class="mt-0 mb-0 pa-0" v-if="item.status.status >= 4">
                  <v-col sm="12" md="12" class="px-0 py-3 text-center" v-if="item.status.status >= 4">
                    <a class="text-underline" @click="accessOrder(item.id)">Ir para o pedido</a>
                  </v-col>
                  <hr/>
                </v-col>
                <v-col class="ml-0 mr-0 pa-0" v-for="(item2, index2) in item.proposal_product" :key="index2+item.id">
                  <v-row class="ml-0 mr-0 mb-4" align="center">
                    <div class="mr-2">
                      <v-avatar tile size="50" class="pull-left" color="grey">
                        <img
                          :src="item2.company_product.picture"
                          :alt="item2.company_product.picture"
                          v-if="item2.company_product.picture"
                        >
                        <v-icon dark v-else>
                          mdi-food-apple
                        </v-icon>
                      </v-avatar>
                    </div>
                    <div class="mr-3">
                      <span v-if="item2.company_product.type"> {{item2.company_product.type.name}}</span>
                      <span v-if="item2.company_product.category"> {{item2.company_product.category.name}}</span>
                      <span v-if="item2.company_product.caliber"> Cal.{{item2.company_product.caliber.value}}</span>
                      <span v-if="item2.company_product.weight"> {{item2.company_product.weight.value}}kg</span>
                      <span v-if="item2.company_product.brand"> - {{item2.company_product.brand.name}}</span>
                    </div>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col sm="4" md="4" class="px-0 py-0 text-center">
                      <div style="color:#4C4C4F;font-size:15px;">Disponível:</div>
                      <div class="headline" style="">{{item2.company_product.available}}</div>
                    </v-col>
                    <v-col sm="4" md="4" class="px-0 py-0 text-center">
                      <div style="color:#4C4C4F;font-size:15px;">Projeção:</div>
                      <div class="headline" style="">{{item2.company_product.projection}}</div>
                    </v-col>
                    <v-col sm="4" md="4" class="px-0 py-0 text-center">
                      <div style="color:#4C4C4F;font-size:15px;">Estoque:</div>
                      <div class="headline" style="">{{item2.company_product.available + item2.company_product.projection}}</div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-1 mr-0 ml-0" justify="center">
                    <v-col sm="12" md="6" class="px-0 text-center" v-if="item2.company_product.min_atacado">
                      <div class="title_items" style="color:#4C4C4F;font-size:14px;">Atacado</div>
                      <div>
                        <span class="" style="color:#5FA348;font-size:15px;">R$</span>
                        <span class="headline font-weight-bold" style="color:#5FA348;">{{item2.company_product.min_atacado}}</span>
                      </div>
                    </v-col>
                    <v-col sm="12" md="6" class="px-0 text-center" v-if="item2.company_product.min_varejo">
                      <div class="title_items" style="color:#4C4C4F;font-size:14px;">Varejo</div>
                      <div>
                        <span class="" style="color:#5FA348;font-size:15px;">R$</span>
                        <span class="headline font-weight-bold" style="color:#5FA348;">{{item2.company_product.min_varejo}}</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col sm="12" md="12" class="px-0 py-0 text-center">
                      <span class="font-weight-bold" style="font-size:15px;">PROPOSTA</span>
                    </v-col>
                    <v-col sm="12" md="12" class="px-0 py-0 text-center">
                      <span class="" style="color:#0057FF;font-size:12px;">R$</span>
                      <span class="headline font-weight-bold" style="color:#0057FF;font-size:18px !important;">{{item2.value}}</span>
                    </v-col>
                    <v-col sm="12" md="12" class="px-0 py-0 text-center">
                      <span class="" style="color:#858585;"> {{item2.boxes}} caixas</span>
                    </v-col>
                  </v-row>
                  <v-row justify="center" class="mt-3 mb-2">
                    <v-col sm="12" md="12" class="px-0 py-0 text-center">
                      <v-chip medium :color="item2.status.color" text-color="white">{{item2.status.text}}</v-chip>
                    </v-col>
                  </v-row>
                  <hr/>
                </v-col> -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="text-center mt-5" v-if="proposals.length == 0 && !loadingProposals">Nenhuma proposta encontrada</div>
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- DIALOG SOLICITAR PEDIDO -->
  <v-dialog persistent v-model="dialog_pedido" max-width="800">
    <v-card class="borda" flat min-height="400">
      <v-card-title class="ma-2">
        Solicitar Pedido
        <v-spacer></v-spacer>
        <v-btn icon text @click="dialog_pedido = false;">
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="pa-0">
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <span class="font-weight-bold black--text">Transportadora</span>
            <v-select
              no-data-text="Nenhum tranportadora cadastrada"
              label="Selecione"
              solo
              dense
              flat
              outlined
              color="#858585"
              v-model="pedido.transport_id"
              :items="transports"
              item-value="id"
              item-text="fantasy_name"
              :loading="loadingTransports"
              :disabled="loadingTransports"
              clearable
              @change="pedido.transport_id ? clearTransport() : ''"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="mb-n5 text-left d-flex align-items-center">
            <v-chip class="" color="primary" outlined small @click="newTransport()">
              Informar dados manualmente
              <v-icon v-if="!panelAddTransport" right>mdi-menu-down</v-icon>
              <v-icon v-if="panelAddTransport" right>mdi-menu-up</v-icon>
            </v-chip>
          </v-col>
        </v-row>

        <!-- CADASTRAR TRANSPORTADORA -->
        <v-expand-transition>
          <div v-show="panelAddTransport" class="mb-2">
            <v-card class="">
              <v-card-text class="">
                <div class="h5">Dados da Transportadora</div>
                <v-row justify="center">
                  <v-col cols="12" sm="12" md="6" class="mb-n5">
                    <v-text-field
                      label="Nome comercial"
                      outlined
                      color="#858585"
                      v-model="pedido.transport_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="mb-n5">
                    <v-text-field
                      label="CNPJ"
                      outlined
                      color="#858585"
                      v-model="pedido.transport_cnpj"
                      v-mask="cnpj"
                      :rules="rulesAdicionalCNPJ"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="12" md="6" class="mb-n5">
                    <v-text-field
                      label="Telefone"
                      outlined
                      color="#858585"
                      v-model="pedido.transport_phone"
                      v-mask="telefone"
                      :rules="rulesAdicionalTelefone"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="mb-n5">
                    <v-text-field
                      label="E-mail"
                      outlined
                      color="#858585"
                      v-model="pedido.transport_email"
                      :rules="rulesAdicionalEmail"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="mb-n5">
                    <v-textarea
                      label="Informações adicionais"
                      outlined
                      color="#858585"
                      v-model="pedido.transport_information"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-expand-transition>

        <v-row class="pa-0">
          <v-col cols="12" sm="12" md="6" class="mb-n5">
            <span class="font-weight-bold black--text">Endereço de Entrega *</span>
            <v-select
              no-data-text="Nenhum endereço cadastrado"
              label="Selecione"
              solo
              dense
              flat
              outlined
              color="#858585"
              v-model="pedido.address_id"
              :items="addresses"
              item-value="id"
              :item-text="item => item.cep + ' - ' + item.street + ', ' + item.number"
              required
              :rules="requiredRules"
              :loading="loadingAddress"
              :disabled="loadingAddress"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="mb-n5 text-left d-flex align-items-center">
            <v-chip class="" color="primary" outlined small @click="newAddress()">
              <v-icon left>
                mdi-plus-circle
              </v-icon>
              Novo endereço
              <v-icon v-if="!panelAddAddress" right>mdi-menu-down</v-icon>
              <v-icon v-if="panelAddAddress" right>mdi-menu-up</v-icon>
            </v-chip>
          </v-col>
        </v-row>

        <!-- CADASTRAR ENDEREÇO -->
        <v-expand-transition>
          <div v-show="panelAddAddress" class="mb-2">
            <v-card class="">
              <v-card-text class="">
                <v-form ref="form2" v-model="valid" v-on:submit="saveAddress($event)">
                  <div class="h5">Novo Endereço</div>
                  <v-row>
                    <v-col cols="12" sm="12" md="4" class="mb-n5">
                      <v-text-field
                        label="CEP *"
                        outlined
                        color="#858585"
                        v-model="formAddress.cep"
                        @input="viaCep()"
                        v-mask="cep"
                        :disabled="loadingViaCep"
                        :loading="loadingViaCep"
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="8" class="mb-n5">
                      <v-text-field
                        label="Rua *"
                        outlined
                        color="#858585"
                        v-model="formAddress.street"
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" class="mb-n5">
                      <v-text-field
                        label="Número *"
                        outlined
                        color="#858585"
                        v-model="formAddress.number"
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="8" class="mb-n5">
                      <v-text-field
                        label="Complemento"
                        outlined
                        color="#858585"
                        v-model="formAddress.complement"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="mb-n5">
                      <v-autocomplete
                        label="País *"
                        outlined
                        color="#858585"
                        v-model="formAddress.country"
                        :items="countries"
                        :disabled="disableLocals"
                        no-data-text="Nenhum registro"
                        item-value="nome_pais"
                        item-text="nome_pais"
                        @change="selectedCountry"
                        required
                        :rules="requiredRules"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="mb-n5">
                      <v-autocomplete
                        label="Estado *"
                        outlined
                        color="#858585"
                        v-model="formAddress.state"
                        :items="states"
                        :disabled="disableLocals"
                        item-value="sigla"
                        item-text="sigla"
                        no-data-text="Nenhum registro"
                        @change="selectedState"
                        required
                        :rules="requiredRules"
                        v-if="brazil"
                      ></v-autocomplete>
                      <v-text-field
                        label="Estado *"
                        outlined
                        color="#858585"
                        v-model="formAddress.state"
                        required
                        :rules="requiredRules"
                        v-if="!brazil"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="mb-n5">
                      <v-autocomplete
                        label="Cidade *"
                        outlined
                        color="#858585"
                        v-model="formAddress.city"
                        :items="cities"
                        :disabled="disableLocals"
                        no-data-text="Selecine um Estado"
                        required
                        :rules="requiredRules"
                        v-if="brazil"
                      ></v-autocomplete>
                      <v-text-field
                        label="Cidade *"
                        outlined
                        color="#858585"
                        v-model="formAddress.city"
                        required
                        :rules="requiredRules"
                        v-if="!brazil"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="mb-n5">
                      <v-text-field
                        label="Bairro *"
                        outlined
                        color="#858585"
                        v-model="formAddress.district"
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-col cols="12" sm="12" md="12" class="">
                    <v-row justify="center" class="mb-2 mt-5">
                      <v-btn
                        color="primary"
                        style="color: #FFF !important"
                        type="submit"
                        :loading="loadingSaveAddress"
                        :disabled="loadingSaveAddress"
                        >
                        CADASTRAR
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-form>
              </v-card-text>
            </v-card>
          </div>
        </v-expand-transition>

        <v-row class="pa-0">
          <v-col cols="12" sm="12" md="12" class="mb-n5">
            <v-textarea
              label="Observações do pedido"
              outlined
              color="#858585"
              v-model="pedido.observation"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-col cols="12" sm="12" md="12" class="text-center">
          <span class="text-danger font-weight-bold mb-1" v-if="errorPedido">{{errorPedido}}</span>
          <v-row justify="center" class="mb-2 mt-5">
            <v-btn
              color="#95D904"
              style="color: #FFF !important"
              type="button"
              @click="saveOrder()"
              :disabled="loadingSave"
              :loading="loadingSave"
              >
              ENVIAR SOLICITAÇÃO
            </v-btn>
          </v-row>
        </v-col>

      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- DIALOG ORDERS -->
  <v-dialog persistent v-model="dialog_orders" max-width="800">
    <v-card class="borda" flat min-height="250" :loading="loadingOrders">
      <v-card-title class="ma-2">
        Meus pedidos
        <v-spacer></v-spacer>
        <v-btn icon text @click="dialog_orders = false;">
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-col cols="12" sm="12" md="12" class="pa-0">
          <v-expansion-panels
            v-model="panelOrder"
          >
            <v-expansion-panel v-for="(item, index) in orders" :key="index">
              <v-expansion-panel-header>
                <div style="flex: 0 1 auto;" class="mr-2">
                  #{{item.id}}
                </div>
                <div style="flex: 0 1 auto;" class="mr-3">
                  <span class="font-weight-bold">{{item.proposal.proposal_product[0].company_product.product.name}}</span>
                </div>
                <div style="flex: 0 1 auto;" class="mr-3" v-if="!$vuetify.breakpoint.mobile">
                  <span>{{item.proposal.proposal_product.length}} {{item.proposal.proposal_product.length > 1 ? 'produtos' : 'produto'}}</span>
                </div>
                <div style="flex: 0 1 auto;" class="mr-3">
                  <span class="font-weight-bold">R$ {{item.total_value}}</span>
                </div>
                <div style="flex: 0 1 auto;" class="">
                  <v-chip small :color="item.status.color" text-color="white" v-if="!$vuetify.breakpoint.mobile">{{item.status.text}}</v-chip>
                  <v-chip x-small :color="item.status.color" text-color="white" v-if="$vuetify.breakpoint.mobile">{{item.status.text}}</v-chip>
                </div>
                <v-spacer></v-spacer>
                <div style="flex: 0 1 auto;" class="mr-3" v-if="!$vuetify.breakpoint.mobile">
                  <span style="font-size:11px;">vendido por</span>
                  <span class="font-weight-bold">{{item.seller.fantasy_name}}</span>
                </div>
                <template v-slot:actions>
                  <v-icon>mdi-chevron-down</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>

                <details-order :order="item.id" v-if="panelOrder == index" :user="user" :company="company" @openProposalChild="accessProposal"></details-order>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="text-center mt-5" v-if="orders.length == 0 && !loadingOrders">Nenhum pedido encontrado</div>
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
	import axios from 'axios';
	import moment from 'moment';
  import io from 'socket.io-client';
  import { cnpj, cpf } from "cpf-cnpj-validator";

	export default {
		props: ['user', 'authorization', 'solicitation_id', 'company'],
		data: function() {
			var tunnel = 'https://tunnel.d3t.com.br';
			var api = 'https://manzanas.app/api';

			if (window.location.hostname == 'localhost'){
				tunnel = 'http://172.20.0.1:5555';
				api = 'http://172.24.0.1/api';
			}

			return {
				loadingSolicitations: true,
				loadingProposals: true,
				loadingChat: false,
				loadingFinalizar: false,
        loadingOrders: false,
        loadingSave: false,
				imagePreview: '',
				detailsSolicitation: '',
				search: '',
				solicitations: [],
        proposals: [],
        orders: [],
        transports: [],
        addresses: [],
				contact: '',
				chat: [],
				message: '',
				file: '',
				selectedFiles: [],
				socket: io(tunnel, {
					query: `domain=${api}&authorization=${this.user.app_token}`
				}),
				response: '',
				responseCustom: '',
        responsesDefault: [],
        dialog_proposals: false,
        dialog_orders: false,
        dialog_pedido: false,
        panel: [],
        panelOrder: [],
        dialogLoading: false,
        loadingStatus: false,
        loadingTransports: false,
        loadingAddress: false,
        loadingViaCep: false,
        loadingSaveAddress: false,
        requiredRules: [v => !!v || "Campo Obrigatório."],
        rulesAdicionalCNPJ: [
          // v => !!v || "Campo Obrigatório.",
          v => cnpj.isValid(v) || "CNPJ inválido"
        ],
        rulesAdicionalEmail: [
          // v => !!v || "Campo Obrigatório.",
          v => /^(([^<>()\\.,;:ç~\s@"]+(\.[^<>()\\.,;:ç~\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v) || "E-mail inválido"
        ],
        rulesAdicionalTelefone: [
          // v => !!v || "Campo Obrigatório.",
          v => (v && v.length >= 14) || "Telefone inválido"
        ],
        cnpj: "##.###.###/####-##",
        cep: "#####-###",
        panelAddTransport: false,
        panelAddAddress: false,
        valid: true,
        pedido: {
          proposal_id: '',
          user_id: '',
          company_id: '',
          address_id: '',
          transport_id: '',
          transport_name: '',
          transport_cnpj: '',
          transport_phone: '',
          transport_email: '',
          transport_information: '',
        },
        formAddress: {
          cep: '',
          street: '',
          number: '',
          complement: '',
          country: 'Brasil',
          state: '',
          city: '',
          district: '',
        },
        countries: [],
        states: [],
        cities: [],
        brazil: true,
        disableLocals: false,
        errorPedido: '',
			};
		},

		created() {
      this.get_solicitations();
      this.timeoutSolicitations();
      this.init_chat();
      this.getProposals();
      this.getOrders();
      this.socket.on('observer', async (data) => {
        console.log(data, 'observer')
        const content = data.content.content;
        if (this.chat){
          //if (this.chat.user.id == content.receive_id){
          if (this.user.id == content.receive_id){
            this.chat.messages.push(content);
            this.scrollChat(200);
            // this.setViewedMessage(this.user.id);

            // if (content.finished){
            //   if (this.chat.solicitation){
            //     this.chat.solicitation.finished = 1;
            //     var index = this.solicitations.map((el) => el.id).indexOf(content.solicitation_id);
            //     this.solicitations[index].finished = 1;
            //   }
            // }
          } else {
            var index = this.contacts.map((el) => el.solicitation.id).indexOf(content.solicitation_id);
            this.contacts[index].new_messages++;
          }
        } else {
          var index = this.solicitations.map((el) => el.solicitation.id).indexOf(content.solicitation_id);
          this.solicitations[index].contact.new_messages++;
        }
      });
    },

    mounted() {

    },

    computed: {
      filteredSolicitations() {
        return this.solicitations.filter(item => {
          return item.seller.fantasy_name.toLowerCase().includes(this.search.toLowerCase())
        })
      },
      telefone() {
        let telefone = "";
        if (this.pedido.transport_phone){
          if (this.pedido.transport_phone.length > 14) {
            telefone = "(##) # ####-####";
          } else {
            telefone = "(##) ####-####";
          }
        }
        return telefone;
      },
    },

    methods: {
      modalSimulator(){
        $('#simulatorModal').modal({
          show: true,
        })
      },

      scrollChat (time) {
        setTimeout(function() {
          $(".box_conversa").scrollTop($(".box_conversa").prop("scrollHeight"));
        }, time);
      },

      verifLineActive (data) {
        if (this.contact){
          /*if (data.id == this.contact.id){*/
          if (data.id == this.chat.solicitation.id){
            return true;
          }
        }
        return false;
      },

      clickSelectFiles () {
        $('#files-upload').val('');
        $('#files-upload').trigger('click');
      },

      get_solicitations () {
        this.loadingSolicitations = true
        this.solicitations = [];

        let url = '/api/proposal/company';
        
        // For clients
        if(this.user.type == 1) {
          url = '/api/chat/solicitations'
        }

        //axios.get(url, { headers: { Authorization: this.authorization } })
        var dados = {
          'company_id': this.company
        }
        axios.post(url, dados)
        .then(response => {
          console.log('get_solicitations');
          console.log(response.data);
          this.solicitations = response.data;

          // if (this.solicitation_id){
          //   this.solicitations.forEach((value, index) => {
          //     if (value.id == this.solicitation_id){
          //       this.init_chat(value);
          //     }
          //   });
          // }
        })
        .catch(e => {
          var msg = 'Houve um erro ao buscar as solicitações! Por favor, tente novamente.'
          this.$swal("Oops!", msg, "error")
          console.error(e)
        })
        .finally(() => {
          this.loadingSolicitations = false
        })
      },

      updateSolicitations () {
        let url = '/api/solicitations/provider';
        // For clients
        if(this.user.type == 1) {
          url = '/api/chat/solicitations'
        }
        axios.get(url, { headers: { Authorization: this.authorization } })
        .then(response => {
          this.solicitations = response.data.content;
          this.timeoutSolicitations();
        })
        .catch(e => {
          console.error(e)
        })
      },

      timeoutSolicitations () {
        var self = this;
        setTimeout(function() {
          //self.updateSolicitations();
        }, 60000);
      },

      init_chat (data) {
        this.loadingChat = true
        this.chat = [];
        this.contact = '';
        this.category = {};

        axios.get(`/api/chat/messages/${this.user.id}`)
        .then(response => {
          console.log('init_chat');
          console.log(response.data);
          this.chat = response.data;
        })
        .catch(e => {
          var msg = 'Houve um erro ao iniciar o chat! Por favor, tente novamente.'
          this.$swal("Oops!", msg, "error")
          console.error(e)
        })
        .finally(() => {
          this.loadingChat = false;
          this.scrollChat(200);
        })
      },

      enviar_mensagem (message, proposal = null, order = null) {
        const data = {
          method: 'POST',
          endpoint: '/chat/new_message',
          
          content: {
            send_id: this.user.id,
            //receive_id: contact.id,
            receive_id: 1,
            archive: null,
            message: message,
            created_at: moment().format('YYYY-MM-DD HH:mm'),
            type: proposal ? 'proposal_att' : order ? 'order_request' : null,
            control: proposal ? proposal : order ? order : null,
            send_avatar: this.user.image,
          }
        }
        
        this.chat.messages.push(data.content);
        this.socket.emit('event', data);

        this.message = '';
        this.scrollChat(200);
      },

      getExtension(path) {
          var basename = path.split(/[\\/]/).pop(),
              pos = basename.lastIndexOf(".");

          if (basename === "" || pos < 1)
              return "";

          return basename.slice(pos + 1);
      },

      async send_files (message, files) {

        let formData = new FormData();
                Object.keys(message).map((key) => {
            if(key === 'archive') {
              for (let i = 0; i < files.length; i++) {
                      let file = files[i];
                      formData.append(`archive_${i}`,file, `${i}_${new Date().getTime()}.${this.getExtension(files[i].name)}`);
                  }
            } else {
              if(message[key]) {
                formData.append(key, message[key])
              }
            }
          });

        const { data } = await axios.post(`/api/chat/new_image_message`, formData, { headers: { Authorization: this.authorization }})
        .catch(e => {
          var msg = 'Houve um erro ao enviar imagens! Por favor, tente novamente.'
          this.$swal("Oops!", msg, "error")
          console.error(e)
        });
        const { content } = data;

          return {
            success: true,
            content: content
          };
      },

      async selected_images (user) {
        if (!event.target.files.length){
          return false;
        }

        var message = {
          //solicitation_id: solicitation.id,
          send_id: user.id,
          receive_id: 1,
          archive: null,
          message: null,
          created_at: moment().format('DD/MM/YYYY [às] HH:mm'),
        }

        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          message.archive = URL.createObjectURL(file);
            this.chat.messages.push(Vue.util.extend({}, message));                    
            this.scrollChat(200);
          }

          let { success, content } = await this.send_files(message, files);

          if (!success) {
            return Alert.alert('Oops!', content);
          }

          let send = [];
          content.map((a) => {
            if (a.archive.indexOf('storage/') != -1) {
                const split = a.archive.split('storage/');
                a.archive = `storage/${split[1]}`;
            }
            send.push({
              "method": 'POST',
              "endpoint": '/chat/new_message',
              "content": a,
            })
          });

          for (let i = 0; i < send.length; i++) {
            this.socket.emit('event', send[i]);
          }

        this.selectedFiles = '';
        $('#files-upload').val('');
        this.scrollChat(200);
      },

      verifMessage (message){
        if (message.substring(0,1) == "¨"){
          return "<span class='font-weight-bold'>"+message.replace("¨", "")+"</span>";
        } else {
          return message;
        }
      },

      dialogProposals(item, id = null){
        // this.panel = [];
        this.dialog_proposals = true;
        this.getProposals(id);
      },

      dialogOrders(item, id = null){
        // this.panelOrder = [];
        this.dialog_orders = true;
        this.getOrders(id);
      },

      accessOrder(proposal){
        this.dialog_proposals = false;
        this.dialog_orders = true;
        this.getOrders(null, proposal);
      },

      accessProposal(response){
        if (response.proposal){
          this.dialogProposals(null, response.proposal);
        }
      },

      dialogPedido(item){
        this.proposal = item;
        this.dialog_pedido = true;
        this.clearPedido();
        this.clearAddress();
        this.getTransport();
        this.getAddresses();
        this.getCountries();
        this.getStates();
      },

      changeStatus: function(proposal, status) {
        this.dialogLoading = true;
        this.$swal({
          title: "Atenção!",
          text: "Deseja realmente alterar o status desta proposta?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#008d4c",
          cancelButtonColor: "#d4d4d4",
          confirmButtonText: "Sim!",
          cancelButtonText: "Não!",
        }).then(result => {
          if (result.value) {
            this.loadingStatus = true;
            var dados = {
              'id' : proposal.id,
              'status': status
            }
            axios
              .post("/api/proposal/status", dados)
              .then((response) => {
                console.log("status");
                console.log(response.data);
                if (!response.data.errors) {
                  this.$toast.success("Status alterado!");

                  this.getProposals();
                  // ENVIAR MENSAGEM
                  this.enviar_mensagem(response.data.message, proposal.id);
                } else {
                  this.$toast.warning(response.data.errors);
                }
              })
              .catch(err => {
                this.$toast.error("Ocorreu um erro ao alterar o status.");
                console.log(err)
              })
              .finally(() => {
                this.loadingStatus = false;
                this.dialogLoading = false;
              });
          } else {
            this.dialogLoading = false;
          }
        });
      },

      getProposals(id){
        this.loadingProposals = true;
        axios
          .get('/api/proposal/user/'+this.user.id)
          .then((response) => {
            console.log("getProposals");
            console.log(response.data);
            this.proposals = response.data;
            this.loadingProposals = false;
            if (id){ // ABRIR PROPOSTA ESPECÍFICA
              var self = this;
              response.data.forEach((element, index) => {
                if (element.id == id){
                  this.panel = index;
                }
              });
            }
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar as propostas.");
            this.loadingProposals = false;
            console.log(err)
          })
      },

      getOrders (id = null, proposal = null) {
        this.loadingOrders = true

        axios.get('/api/order/company/'+this.company)
        .then(response => {
          console.log('getOrders');
          console.log(response.data.content);
          this.orders = response.data.content;
          // if (id){ // ABRIR PEDIDO ESPECÍFICO
          //   var self = this;
          //   response.data.content.forEach((element, index) => {
          //     if (element.id == id){
          //       this.panelOrder = index;
          //     }
          //   });
          // }
          if (id || proposal){ // ABRIR PEDIDO ESPECÍFICO
            var self = this;
            response.data.content.forEach((element, index) => {
              if (proposal){
                if (element.proposal_id == proposal){
                  this.panelOrder = index;
                }
              } else {
                if (element.id == id){
                  this.panelOrder = index;
                }
              }
            });
          }
        })
        .catch(e => {
          var msg = 'Houve um erro ao buscar os pedidos! Por favor, tente novamente.'
          this.$swal("Oops!", msg, "error")
          console.error(e)
        })
        .finally(() => {
          this.loadingOrders = false
        })
      },

      getTransport(){
        this.loadingTransport = true;
        axios
          .get('/api/company?type=6')
          .then((response) => {
            this.transports = response.data;
            this.loadingTransport = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar as transportadoras.");
            this.loadingTransport = false;
            console.log(err)
          })
      },

      getAddresses(){
        this.loadingAddress = true;
        axios
          .get('/api/company/addresses?id=' + this.company)
          .then((response) => {
            this.addresses = response.data;
            if (response.data.length == 1){
              this.pedido.address_id = response.data[0].id;
            }
            this.loadingAddress = false;
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao buscar os endereços.");
            this.loadingAddress = false;
            console.log(err)
          })
      },

      newTransport(){
        this.panelAddTransport = !this.panelAddTransport;
      },

      newAddress(){
        this.panelAddAddress = !this.panelAddAddress;
        this.$refs.form2.resetValidation();
      },

      getCountries(){
        axios
          .get('/json/paises.json')
          .then((response) => {
            if (response.data){
              this.countries = response.data;
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
              
          });
      },

      getStates(){
        axios
          .get('/json/cidades.json')
          .then((response) => {
            if (response.data.estados){
              this.states = response.data.estados;
              if (this.stateselected){
                this.selectedState(true);
              }
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
              
          });
      },

      selectedCountry(){
        if (this.formAddress.country == 'Brasil'){
          this.brazil = true;
        } else {
          this.brazil = false;
          this.formAddress.state = '';
          this.formAddress.city = '';
        }
      },

      selectedState(init){
        this.cities = [];
        if (init != true){
            this.formAddress.city = '';
        }
        this.states.forEach(value => {
            if (this.formAddress.state == value.sigla){
                this.cities = value.cidades;
            }
          });
      },

      viaCep() {
        if (this.formAddress.cep) {
          if (this.formAddress.cep.length != 9) {
            this.brazil = true;
            this.disableLocals = false;
          } else {
            this.loadingViaCep = true;
            axios
              .get(
                "https://viacep.com.br/ws/" +
                  this.formAddress.cep +
                  "/json"
              )
              .then(response => {
                console.log("viaCep");
                console.log(response);
                this.loadingViaCep = false;
                if (response.data.erro == true) {
                  this.cep_errado = "CEP não encontrado";
                  this.brazil = true;
                  this.disableLocals = false;
                } else {
                  this.brazil = false;
                  this.disableLocals = true;

                  this.formAddress.street = response.data.logradouro;
                  this.formAddress.city = response.data.localidade;
                  this.formAddress.district = response.data.bairro;
                  this.formAddress.state = response.data.uf;
                  this.formAddress.country = "Brasil";
                  this.cep_errado = "";
                  this.selectedCountry();
                  this.selectedState(true);
                }
              });
          }
        } else {
          this.brazil = true;
          this.disableLocals = false;
        }
      },

      clearAddress(){
        this.formAddress = {
          cep: '',
          street: '',
          number: '',
          complement: '',
          country: 'Brasil',
          state: '',
          city: '',
          district: '',
        }
      },

      clearPedido(){
        this.pedido = {
          proposal_id: '',
          user_id: '',
          company_id: '',
          address_id: '',
          transport_id: '',
          transport_name: '',
          transport_cnpj: '',
          transport_phone: '',
          transport_email: '',
          transport_information: '',
        }
      },

      clearTransport(){
        this.pedido.transport_name = '';
        this.pedido.transport_cnpj = '';
        this.pedido.transport_phone = '';
        this.pedido.transport_email = '';
        this.pedido.transport_information = '';
        this.panelAddTransport = false;
      },

      saveAddress(e){
        if (this.$refs.form2.validate()) {
          e.preventDefault();
          this.loadingSaveAddress = true;
          this.formAddress.company_id = this.company;
          axios
            .post('/api/company/address', this.formAddress)
            .then((response) => {
              console.log("saveAddress");
              console.log(response.data);
              if (!response.data.errors) {
                this.panelAddAddress = false;
                this.getAddresses();
                this.pedido.address_id = response.data.id;
                this.clearAddress();
              } else {
                this.$toast.warning(response.data.errors);
              }
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao cadastrar o endereço.");
              console.log(err)
            })
            .finally(() => {
              this.loadingSaveAddress = false;
            });
        } else {
          e.preventDefault();
          return false;
        }
      },

      saveOrder(){
        this.errorPedido = '';
        if (!this.pedido.transport_id){
          if (!this.pedido.transport_name && !this.pedido.transport_cnpj && !this.pedido.transport_phone && !this.pedido.transport_email){
            this.errorPedido = 'Informe a transportadora.';
            return null;
          }
        }

        if (!this.pedido.address_id){
          this.errorPedido = 'Informe o endereço de entrega.';
          return null;
        }

        this.pedido.proposal_id = this.proposal.id;
        this.pedido.user_id = this.user.id;
        this.pedido.company_id = this.company;

        console.log("this.pedido");
        console.log(this.pedido);
        // return null;

        this.$swal({
          title: "Atenção!",
          text: "Deseja realmente solicitar o pedido?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#008d4c",
          cancelButtonColor: "#d4d4d4",
          confirmButtonText: "Sim!",
          cancelButtonText: "Não!",
        }).then(result => {
          if (result.value) {
            this.loadingSave = true;
            axios
              .post("/api/order", this.pedido)
              .then((response) => {
                console.log("order");
                console.log(response.data);
                
                if (!response.data.errors) {
                  this.$toast.success("Pedido solicitado!");

                  this.dialog_pedido = false;
                  this.clearPedido();
                  this.getProposals();
                  // ENVIAR MENSAGEM
                  this.enviar_mensagem(response.data.message, response.data.proposal_id);
                } else {
                  this.$toast.warning(response.data.errors);
                }
              })
              .catch(err => {
                this.$toast.error("Ocorreu um erro ao alterar o status.");
                console.log(err)
              })
              .finally(() => {
                this.loadingSave = false;
                this.dialogLoading = false;
              });
          } else {
            this.dialogLoading = false;
          }
        });
      },
    }
	}
</script>

<style lang="scss" scoped>
	.chat_solicitation {
		width: 100%;
		background-color: #fff;
		padding: 0;

		.padding_none {
		  padding-left: 0 !important;
		  padding-right: 0 !important;
		}

		.btn_menu_acoes{
			position: absolute;
			right: 15px;
			top: 22px;
			cursor:pointer;

			i{
				font-size:25px;
			}
		}

		.box-stars{
			i{
				/*cursor: pointer;*/
				padding: 0 2px;
			}

			.fa-star{
				color: #FF8533;
			}
		}

		.form-control{
		    border-radius: 20px;
		    border: 0;
		    padding: 15px;
		    font-size: 15px;
		}

		.row{
			margin: 0;
		}

		.box_loading{
			padding: 50px 0;
		}
		
		.box_lista{
			padding: 0;
			min-height: 85vh;
			max-height: 85vh;

			.box_header{
    			display: flex;
				align-items: center;
				background-color: #e7e7e7;
				border-left: 1px solid #e0e0e0;
				padding: 0 15px;
				line-height: 55px;
				min-height: 55px;
				max-height: 55px;

				&_text {
					width: 100%;
					background-color: #fff;
					border-top-right-radius: 15px;
        			border-bottom-right-radius: 15px;
					padding: 5px 20px;
    				margin-left: -15px;
				}
			}

			.box_search{
				// background-color: #f7f7f7;
				// border-left: 1px solid #e0e0e0;
				border-right: 1px solid #e0e0e0;
				padding: 7px 15px;
				line-height: 55px;
				min-height: 55px;
				max-height: 55px;
			}

			.box_lines{
				border-right: 1px solid #e0e0e0;
				min-height: 69vh;
				max-height: 69vh;
				padding: 0;
				line-height: 0;
				overflow: auto;

				.box_sem_registro{
				    text-align: center;
    				padding: 12px;
    				color: #ccc;
				}

				.line{
					padding: 15px 10px;
					border-bottom: 1px solid #eee;
					display: inline-block;
					overflow:auto;
					cursor: pointer;

					.box_avatar{
						width: 40px;
						height: 40px;
						border-radius: 100%;
						background-color: #ccc;
						margin: 0 5px;
						box-shadow: 1px 1px 3px #ccc;
						float:left;
						margin-right: 10px;
						background-position: center;
                		background-repeat: no-repeat;
                		background-size: cover;
					}

					.box_info{
						float:left;

						.box_nome{
							font-weight: 600;
							float:left;
							width:100%;
						}

						.box_details{
							float:left;
							font-size: 14px;
							width:100%;
						}
					}

					.box_new_messages{
						float:right;
						width: 20px;
						height: 20px;
						background-color: #c56e0d;
						border-radius: 100%;
						text-align:center;
						line-height: 20px;
						padding: 0;
						color:#fff;
						font-size: 14px;
						box-shadow: 1px 1px 3px #ccc;
					}
				}

				.line_active{
					background-color: #ebebeb;
				}

				.new_solicitation{
		    		animation: pulse 1s infinite;
		    		z-index: 1;
		    		border: 1px solid rgba(40, 167, 69, 0.4);
				}
			}
		}
		
		.box_chat{
			padding: 0;
			min-height: 79vh;
			max-height: 79vh;

			.box_avatar_header {
				width: 40px;
				height: 40px;
				border-radius: 100%;
				background-color: #ccc;
				margin: 0 15px 0 0;
				box-shadow: 1px 1px 3px #ccc;
				background-position: center;
        		background-repeat: no-repeat;
        		background-size: cover;
			}

			.box_header{
				background-color: #e7e7e7;
				border-bottom: 1px solid #e0e0e0;
				padding: 7px 15px;
				min-height: 55px;
				max-height: 55px;
				display:flex;
				justify-content: space-between;

				.box_nome{
					font-weight: 600;
				}

				.box_info{
					font-size: 11px;
				}
			}

			.box_footer{
				background-color: #e7e7e7;
				border-top: 1px solid #e0e0e0;
				padding: 7px 15px;
				line-height: 55px;
				min-height: 55px;
				position:absolute;
				bottom:0;
				display: flex;

				.box_anexo{
					float:left;
					font-size: 22px;
					line-height: 36px;
					margin-right: 15px;
					cursor: pointer;
					background: none;
    			border: 0;
    			color: #7e7e7e;
    			padding: 0;
				}

				.box_message{
					float:left;
					width: 100%;
				}

				.box_send{
					line-height: 32px;
					margin: 0 10px;
					cursor: pointer;
			    background: none;
    			border: 0;

					img{
						width: 20px;
    					vertical-align: middle;
					}
				}
			}

			.box_interno_chat{
				min-height: 79vh;
				max-height: 79vh;
				// background-color: rgba(236, 240, 245, 0.93);
				background-color: #fff;
				padding: 0;
				padding-bottom: 55px;
				// background-image: url('/img/bg_chat.png');
        // background-size: contain;
        // background-position: center;

				.box_header_top{
					position: absolute;
					width: 100%;
					z-index: 1;
				    padding-bottom: 10px;
					border-bottom: 1px solid #ccc;
					background-color: #fff;
				}

				.box_header_review{
					margin-top: 30px;
					background-color: #333333;
					border-radius: 35px;
					padding: 10px 15px;
					font-size: 14px;
					text-align: center;
					cursor: pointer;
					color: #fff;

					&_image_info {
						position: absolute;
						top: 6px;
						left: 5px;
						width: 45px;
						height: 45px;
						z-index: 1;
					}

					&_info_text {
						position: absolute;
						top: 20px;
						left: 30px;
						min-width: 100px;
						background-color: #e7e7e7;
						border-radius: 15px;
						color: #333333;
						font-size: 13px;
						font-weight: bold;
						text-transform: uppercase;
						text-align: left;
						padding: 0px 35px;
					}
				}

				.box_aviso{
					text-align:center;
					padding: 32vh 0;

					i{
						font-size: 50px;
					}
					span{
						font-size: 13px;
						margin-top: 15px;
					}
				}

				.box_conversa{
					overflow:auto;
					padding: 15px;
					// padding-top: 100px;
					max-height: 70vh;

					.box_finish_chat{
						display: flex;
						align-items: center;
						justify-content: center;

						.msg{
							box-shadow: 1px 1px 3px #ccc;
							border-radius: 30px;
							font-size: 14px;
							width: 400px;
							text-align: center;
							padding: 10px;
							background-color: #ccc;
							color: #333333;
						}
					}

					.box_line_msg{
						padding: 5px 20px;
						display: inline-block;

						.box_msg{
							width: auto;

							.box_avatar {
								width: 35px;
								height: 35px;
								border-radius: 100%;
								background-color: #ccc;
								margin: 0 10px;
								box-shadow: 1px 1px 3px #ccc;
								background-position: center;
		        		background-repeat: no-repeat;
		        		background-size: cover;
							}

							.box_text{
								width: auto;
								float: left;
								border-radius: 30px;
								line-height: 1.3;
								padding: 0;
								box-shadow: 1px 1px 3px #ccc;
								font-size: 14px;
								position:relative;
								min-width: 200px;

								.box_interno_text{
									float: left;
									padding: 10px;
									width: 100%;
									padding-bottom: 3px;
									color: #333333;
								}

								.img_enviada{
									max-width: 250px;
									cursor: pointer;
								}

								.box_time{
									float: left;
									font-size: 10px;
									color: #333333;
									text-align: right;
									width: 100%;
									padding-right: 10px;
								}
							}

							.cor1{
								background-color: #fff;
								border-top-right-radius: 0;
								padding-right: 15px;
							}

							.cor2{
								//background-color: #FF8533;
								//background-color: #d9043d;
                background-color: #eee;
								border-top-left-radius: 0;
								padding-right: 15px;
                // * {
                //   color:#fff !important;
                // }
							}
						}

						.msg1{
							float: right;
						}

						.msg2{
							float: left;
						}
					}
				}
			}
		}

	  @-webkit-keyframes pulse {
		  0% {
		    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4);
		  }
		  70% {
		      -webkit-box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
		  }
		  100% {
		      -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
		  }
		}

		@keyframes pulse {
		  0% {
		    -moz-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4);
		    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4);
		  }
		  70% {
		      -moz-box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
		      box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
		  }
		  100% {
		      -moz-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
		      box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
		  }
		}

		.list-enter-active, .list-leave-active {
		  transition: all 0.5s;
		}
		.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
		  opacity: 0;
		  transform: translateY(20px);
		}
  }

  .box_buttons {
    @media (max-width: 768px) {
      display: flex !important;
    }
  }

  .box_buttons button{
    @media (max-width: 768px) {
      float: left !important;
    }
  }

  .fixed_header{
    position: fixed;
    top: 50px;
    right: 0;
    left: 0;
    z-index: 1030;

    .box_line{
      padding-top: 5px;
    }

    .box_title{
      padding-top: 0;
    }
  }

  .space_top{
    margin-top: 135px;
  }

  .box_line_msg{
    @media (max-width: 768px) {
      padding: 5px 0 !important;
    }
  }

  .box_msg .box_text {
    @media (max-width: 768px) {
      max-width: 85%;
    }
  }

  .v-expansion-panel-header{
    @media (max-width: 768px) {
      padding: 10px !important;
    }
  }

  .v-chip__content{
    @media (max-width: 768px) {
      line-height: 15px !important;
    }
  }

</style>