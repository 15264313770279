<template>
  <div>
    <v-card class="borda" flat min-height="400" :loading="loadingDetails">
      <div v-if="details">
        <v-col cols="12" sm="12" md="12" class="">
          <v-col sm="12" md="12" class="px-0">
            <v-chip class="mb-2" text-color="white" color="#17a2b8">{{details.product.name}}</v-chip>
            <!-- <v-row justify="center" class="mb-0">
              <div v-bind:class="{ 'col-6': !$vuetify.breakpoint.mobile }">
                <img
                  :src="details.picture"
                  class="rounded_img pa-0"
                  style="cursor: pointer; max-height: 400px;"
                  @click="modalPreview(details.picture)"
                >
              </div>
            </v-row> -->
          </v-col>
          <v-col sm="12" md="12" class="px-0">
            <v-list-item-title class="headline" style="white-space: normal;">
              <span v-if="details.type">{{details.type.name}}</span>
              <span v-if="details.category">{{details.category.name}}</span>
              <span v-if="details.caliber">Cal.{{details.caliber.value}}</span>
              <span v-if="details.weight">{{details.weight.value}}kg</span>
              <span v-if="details.brand">- {{details.brand.name}}</span>
            </v-list-item-title>
          </v-col>
        </v-col>

        <!-- CUSTOM FIELDS -->
        <v-col cols="12" sm="12" md="12" class="" v-for="(item, index) in details.custom_fields" :key="index">
          <div class="headline" style="">{{item.field.name}}:</div>
          <div class="subtitle" v-if="item.field.type == 1">{{item.value}}</div>
          <div class="subtitle" v-if="item.field.type == 2">
            <span v-for="(item2, index2) in item.field.values" :key="index2">
              <span v-if="item2.id == item.value">{{item2.name}}</span>
            </span>
            </div>
        </v-col>

        <!-- GALERIA DE IMAGENS -->
        <v-row justify="center" class="mb-0" v-if="details.images.length > 0">
          <v-carousel
            hide-delimiters
            height="300"
            v-bind:class="{ 'col-6': !$vuetify.breakpoint.mobile }"
            >
            <v-carousel-item
              v-for="(item,i) in details.images"
              :key="i"
              @click="modalCarousel(i, $event);"
              reverse-transition="fade-transition"
              transition="fade-transition"
              class="text-center"
            >
              <img
                :src="item.url"
                class="rounded_img"
                style="cursor: pointer; height: 285px;"
              >
            </v-carousel-item>
          </v-carousel>
        </v-row>

        <!-- GALERIA DE VÍDEOS -->
        <v-row justify="center" class="mb-0" v-if="details.videos.length > 0">
          <v-col v-for="(item, index) in details.videos" :key="index" sm="3" md="2">
            <v-card flat tile class="d-flex align-center justify-center">
              <v-icon size="120" @click="modalPreviewVideo(item.url)">mdi-video-box</v-icon>
              <!-- <video width="100%" preload="metadata" @click="modalPreviewVideo(item.url)">
                <source :src="item.url">
              </video> -->
            </v-card>
          </v-col>
        </v-row>

        <hr/>

        <v-row class="mt-2 d-flex align-center">
          <v-col sm="4" md="4" class="px-0 py-0 text-center">
            <div style="color:#4C4C4F;font-size:15px;">Disponível</div>
            <div class="headline" style="">{{details.available}}</div>
            <div style="color:#4C4C4F;font-size:15px;margin-top: -3px;">caixas</div>
          </v-col>
          <v-col sm="4" md="4" class="px-0 py-0 text-center">
            <div style="color:#4C4C4F;font-size:15px;">Projeção na classificação {{details.date_validate ? 'em '+details.date_validate : ''}}</div>
            <div class="headline" style="">{{details.projection}}</div>
            <div style="color:#4C4C4F;font-size:15px;margin-top: -3px;">caixas</div>
          </v-col>
          <v-col sm="4" md="4" class="px-0 py-0 text-center">
            <div style="color:#4C4C4F;font-size:15px;">Estoque</div>
            <div class="headline" style="">{{details.available + details.projection}}</div>
            <div style="color:#4C4C4F;font-size:15px;margin-top: -3px;">caixas</div>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col sm="12" md="12" class="px-0 text-center" v-if="details.days_load">
            Carrega em <span class="font-weight-bold">{{details.days_load}} dia(s)</span>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <div class="w-100 text-center subtitle" style="white-space: normal;">
            <span v-if="details.type">{{details.type.name}}</span>
            <span v-if="details.category">{{details.category.name}}</span>
            <span v-if="details.caliber">Cal.{{details.caliber.value}}</span>
            <span v-if="details.weight">{{details.weight.value}}kg</span>
            <span v-if="details.brand">- {{details.brand.name}}</span>
          </div>
        </v-row>
        
        <v-row class="mt-2">
          <v-col sm="6" md="6" class="px-5 py-3 text-right" v-if="details.min_atacado != '0,00'" :class="details.min_varejo == '0,00' ? 'col-md-12 col-sm-12 text-center' : 'col-md-6 col-sm-12'">
            <div style="color:#4C4C4F;font-size:15px;">Valor Atacado</div>
            <div class="headline">
              <span class="" style="color:#5FA348;font-size:15px;">R$</span>
              <span class="headline font-weight-bold" style="color:#5FA348;">{{details.min_atacado}}</span>
              <div style="margin-top: -3px;"><small class="text-help" style="color:#4C4C4F;font-size:15px;">por caixa</small></div>
            </div>
          </v-col>
          <v-col sm="6" md="6" class="px-5 py-3 text-left" v-if="details.min_varejo != '0,00'" :class="details.min_atacado == '0,00' ? 'col-md-12 col-sm-12 text-center' : 'col-md-6 col-sm-12'">
            <div style="color:#4C4C4F;font-size:15px;">Valor Varejo</div>
            <div class="headline">
              <span class="" style="color:#5FA348;font-size:15px;">R$</span>
              <span class="headline font-weight-bold" style="color:#5FA348;">{{details.min_varejo}}</span>
              <div style="margin-top: -3px;"><small class="text-help" style="color:#4C4C4F;font-size:15px;">por caixa</small></div>
            </div>
          </v-col>
          <v-col class="px-0 py-0 text-center" md="12" v-if="details.min_atacado == '0,00' && details.min_varejo == '0,00'">
            <span class="text-help" style="color:#4C4C4F;font-size:14px;font-style:italic;">Preço sob consulta</span>
          </v-col>
        </v-row>

        <v-row class="mt-2" v-if="details.package">
          <v-col sm="12" md="12" class="px-0 text-center" v-if="details.days_load">
            <span class="font-weight-bold">{{details.package.name}}</span> - {{details.weight.value}}kg
          </v-col>
        </v-row>

        <v-row justify="center" class="mt-5">
          <!-- <a href="/login?register=1">
            <v-btn color="#95D904" style="color: #FFF !important">
              <v-icon class="mr-2">mdi-rocket-launch</v-icon>
              Fazer minha proposta
            </v-btn>
          </a> -->
          <a :href="'/special-offers?hashOffer=' + hash">
            <v-btn color="#95D904" style="color: #FFF !important">
              <v-icon class="mr-2">mdi-rocket-launch</v-icon>
              Fazer uma proposta
            </v-btn>
          </a>
        </v-row>
        <v-row justify="center" class="pt-5 mt-3 mb-4 text-center">
          <a href="https://manzanas.com.br/" class="text-underline">
            Clique aqui e conheça mais sobre o <strong>manzanas.app</strong>
          </a>
        </v-row>
      </div>
    </v-card>

    <!-- DIALOG PREVIEW IMAGENS -->
    <v-dialog v-model="dialog_preview" max-width="600">
      <v-card class="borda" flat>
        <v-card-text class="pa-0">
          <v-col cols="12" sm="12" md="12" class="pa-0 d-flex justify-content-center">
            <!-- <v-img
              :src="imageModalPreview"
              :alt="imageModalPreview"
              :lazy-src="imageModalPreview"
              aspect-ratio="1"
              class=""
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img> -->
            <img
              :src="imageModalPreview"
              class="rounded_img"
              style="max-height: 400px;"
            >
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG CAROUSEL -->
    <v-dialog v-model="dialog_carousel" max-width="800">
      <v-card class="borda" flat style="background-color:transparent;">        
        <v-card-text class="pa-0">
          <v-col cols="12" sm="12" md="12" class="pa-0 d-flex justify-content-center align-center">
            <v-carousel
              v-model="indexCarousel"
              hide-delimiters>
              <v-carousel-item
                v-for="(item,i) in details.images"
                :key="i"
                reverse-transition="fade-transition"
                transition="fade-transition"
                class="text-center"
                max-height="500"
              >
                <div class="d-flex justify-content-center align-center" style="height:100%;" @click="dialog_carousel = false">
                  <img
                    :src="item.url"
                    class="rounded_img"
                    style="cursor: pointer; max-height: 500px;"
                  >
                </div>
              </v-carousel-item>
            </v-carousel>
          </v-col>
          
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG PREVIEW VIDEO -->
    <v-dialog v-model="dialog_video" max-width="800">
      <v-card class="borda" flat>
        <v-card-text class="pa-0">
          <v-col cols="12" sm="12" md="12" class="pa-0 d-flex justify-content-center">
            <video width="100%" controls v-if="videoModalPreview">
              <source :src="videoModalPreview">
            </video>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}
.v-middle {
  vertical-align: middle !important;
}

.v-dialog{
  box-shadow: none !important;
}
</style>

<script>
import axios from "axios";

export default {
  props: [
    "product",
    "hash"
  ],
  data: function() {
    return {
      details: '',
      loadingDetails: false,
      dialog_preview: false,
      dialog_carousel: false,
      dialog_video: false,
      imageModalPreview: '',
      videoModalPreview: '',
      indexCarousel: 0,
    };
  },
  created() {
    if (this.product) {
      this.getCompanyProduct(this.product);
    }
  },
  methods: {

    getCompanyProduct(id) {
      this.loadingDetails = true;
      this.details = '';

      axios.get('/api/company-product/' + id)
      .then(response => {
        console.log('getCompanyProduct');
        console.log(response.data);
        if (response.data){
          this.details = response.data;
        }
      })
      .catch(e => {
        var msg = 'Houve um erro ao buscar os detalhes da oferta! Por favor, tente novamente.'
        this.$swal("Oops!", msg, "error")
        console.error(e)
      })
      .finally(() => {
        this.loadingDetails = false;
      })
    },

    modalPreview: function(image) {
      this.imageModalPreview = image;
      this.dialog_preview = true;
    },

    modalCarousel: function(index, e) {
      console.log("index");
      console.log(index);
      this.indexCarousel = index;
      this.dialog_carousel = true;
      e.stopPropagation()
    },

    modalPreviewVideo: function(video) {
      this.videoModalPreview = video;
      this.dialog_video = true;
    },
  }
};
</script>