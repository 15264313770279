<template>
  <div class="box box-widget" style="box-shadow:none;">
    <div class="box-header">
      <div class="row">
        <div class="col-md-2 col-sm-12">
          <v-progress-circular
            :width="2"
            :size="25"
            class="mt-1 mr-5 pull-right"
            indeterminate
            color="primary"
            v-if="loadingFilter"
          ></v-progress-circular>
        </div>
        <div v-if="busca != 'false'" class="col-md-10 col-sm-12">
          <form ref="formFilters" method="GET" class="form-horizontal row" :action="url">
            <div class="col-md-3 col-sm-12 p-0">
              <input type="hidden" name="proposal" :value="proposalFilter">
              <v-checkbox v-model="proposalFilter" hide-details label="Somente com propostas" class="p-0 mr-5" style="margin-top: -7px;" :readonly="loadingFilter" @change="changeFilter()" false-value="0" true-value="1"></v-checkbox>
            </div>
            <div class="col-md-4 col-sm-12 p-0">
              <v-radio-group name="filter" v-model="filterList" row class="p-0 mr-5" @change="changeFilter()" style="margin-top: -7px;" :readonly="loadingFilter">
                <v-radio label="Ativos" value="ativo" class="pr-5"></v-radio>
                <v-radio label="Inativos" value="inativo"></v-radio>
              </v-radio-group>
            </div>
            <div class="input-group col-md-5 col-sm-12 p-0">
              <input
                type="text"
                name="busca"
                class="form-control"
                placeholder="Buscar por"
                v-model="search"
              />
              <span class="input-group-btn">
                <button type="submit" class="btn btn-primary btn-flat">
                  <i class="fa fa-search"></i>
                </button>
                <a
                  :href="url"
                  class="btn btn-default btn-flat"
                  data-toggle="tooltip"
                  title="Limpar Busca"
                >
                  <i class="fa fa-times"></i>
                </a>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="box-body">
      <slot></slot>

      <v-data-table
        no-data-text="Nenhum produto cadastrado"
        hide-default-footer
        calculate-widths
        :headers="header"
        :items="items.data"
        :items-per-page="items_per_page"
        :search="search"
        text-order="aaa"
        no-results-text="Nenhum registro encontrado"
        class="elevation-0 table-striped"
        loading-text="Carregando itens..."
        :header-props="{ sortByText: 'Ordenar por' }"
      >
        <template v-slot:item.status="{ item }">
          <v-chip small class="" :color="item.status.status">{{item.status.text}}</v-chip>
        </template>
        <template v-slot:item.special_offer="{ item }">
          <v-chip small class="" :color="item.special_offer.status">{{item.special_offer.text}}</v-chip>
        </template>
        <template v-slot:item.proposals="{ item }">
          <v-chip small class="" color="primary">{{item.proposals}}</v-chip>
        </template>
        <template v-slot:item.acao="{ item }">
          <a
            class="btn btn-flat btn-primary"
            :href="url + '/' + item.id + '/edit'"
            data-toggle="tooltip"
            title="Editar"
            style="color:#fff !important;"
          >
            <i class="fa fa-pencil"></i>
          </a>
        </template>

        <template v-slot:footer>
          <v-row justify="center" class="mb-2">
            <v-btn
              v-if="items_per_page > 10"
              @click="items_per_page -= 10"
              text
            >Mostrar menos</v-btn>
            <v-btn
              v-if="items.data.length > items_per_page"
              @click="items_per_page += 10"
              text
            >Carregar mais</v-btn>
          </v-row>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}
.v-middle {
  vertical-align: middle !important;
}
</style>

<script>
export default {
  props: [
    "title",
    "titles",
    "items",
    "busca",
    "url",
    "token",
    "formClass",
    "notDeletable",
    "actions",
    "filter",
    "proposal",
  ],
  data: function() {
    return {
      header: [
        { text: "#", align: "left", value: "id", },
        { text: "Nome", align: "left", value: "product_id", },
        { text: "Disponível", align: "left", value: "available", },
        { text: "Projeção", align: "left", value: "projection", },
        { text: "Status", align: "left", value: "status", },
        { text: "Oferta Especial?", align: "left", value: "special_offer", },
        { text: "Propostas", align: "left", value: "proposals", },
        { text: "Ações", align: "left", sortable: false, value: "acao", },
      ],
      items_per_page: 10,
      search: '',
      loadingFilter: false,
      filterList: 'ativo',
      proposalFilter: 0,
    };
  },
  created() {
    // this.items.data = [];
    if (!this.items.data) {
      this.items.data = this.items;
    }
    if (this.filter){
      this.filterList = this.filter;
    }
    if (this.proposal){
      this.proposalFilter = this.proposal;
    }
  },
  methods: {
    changeFilter(){
      this.loadingFilter = true;
      var self = this;
      setTimeout(function(){
        self.$refs.formFilters.submit();
      }, 100)
    },
  }
};
</script>