<template>
    <div>
        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="status" class="col-sm-2 control-label">Ativo</label>
                <div class="col-sm-10">
                  <input type="checkbox" name="status" id="status" value="1" checked v-model="form.status">
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="state" class="col-sm-2 control-label">Produto *</label>
                <div class="col-sm-4">
                    <select class="form-control" name="product_id" id="product_id" v-model="form.product_id" @change="changeProduct()" required>
                        <option value="" selected>Selecione</option>
                        <option :value="item.id" v-for="(item, index) in products" :key="index">{{item.name}}</option>
                    </select>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="state" class="col-sm-2 control-label">Categoria *</label>
                <div class="col-sm-4">
                    <select class="form-control" name="category_id" id="category_id" v-model="form.category_id" required>
                        <option value="" selected>Selecione</option>
                        <option :value="item.id" v-for="(item, index) in categories" :key="index">{{item.name}}</option>
                    </select>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="state" class="col-sm-2 control-label">Calibre *</label>
                <div class="col-sm-4">
                    <select class="form-control" name="caliber_id" id="caliber_id" v-model="form.caliber_id" required>
                        <option value="" selected>Selecione</option>
                        <option :value="item.id" v-for="(item, index) in calibers" :key="index">{{item.value}}</option>
                    </select>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="state" class="col-sm-2 control-label">Tipo da Embalagem *</label>
                <div class="col-sm-4">
                    <select class="form-control" name="package_id" id="package_id" v-model="form.package_id" @change="getWeights();" required>
                        <option value="" selected>Selecione</option>
                        <option :value="item.id" v-for="(item, index) in packages" :key="index">{{item.name}}</option>
                    </select>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="state" class="col-sm-2 control-label">Peso da Caixa *</label>
                <div class="col-sm-4">
                    <select class="form-control" name="weight_id" id="weight_id" v-model="form.weight_id" required>
                        <option value="" selected>Selecione</option>
                        <option :value="item.id" v-for="(item, index) in weights" :key="index">{{item.value}}</option>
                    </select>
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="state" class="col-sm-2 control-label">Valor Atacado *</label>
                <div class="col-sm-4">
                    <money
                      class="input"
                      v-model.lazy="form.atacado"
                      v-bind="moneyFormat"
                      required
                    ></money>
                    <input name="atacado" type="hidden" :value="computedAtacado">
                </div>
              </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-sm-12">
              <div class="row">
                <label for="state" class="col-sm-2 control-label">Valor Varejo *</label>
                <div class="col-sm-4">
                    <money
                      class="input"
                      v-model.lazy="form.varejo"
                      v-bind="moneyFormat"
                      required
                    ></money>
                    <input name="varejo" type="hidden" :value="computedVarejo">
                </div>
              </div>
            </div>
        </div>

    </div>
</template>
<script>
import axios from "axios";

export default {
    props: [
        'products',
        'edit',        
    ],
    data() {        
        return {
            form: {
                id: '',
                product_id: '',
                category_id: '',
                caliber_id: '',
                weight_id: '',
                package_id: '',
                atacado: '',
                varejo: '',
                status: 1,
            },
            categories: [],
            calibers: [],
            weights: [],
            packages: [],
            moneyFormat: {
              decimal: ",",
              thousands: ".",
              prefix: "R$ ",
              precision: 2,
              masked: false,
            },
            loadingCalibers: false,
            loadingWeights: false,
            loadingPackages: false,
        };
    },
    computed: {
      computedAtacado() {
        return this.form.atacado;
      },
      computedVarejo() {
        return this.form.varejo;
      },
    },
    created() {
        console.log("this.edit");
        console.log(this.edit);

        if (this.edit){
          this.form = this.edit;
          this.getCategories();
          this.getCalibers();
          this.getWeights();
          this.getPackages();
        }
    },

    methods: {
        changeProduct(){
          if (this.form.product_id){
            this.getCategories();
            this.getCalibers();
            this.getPackages();
          }
        },

        getCategories(){
          this.categories = [];
          if (this.form.product_id){
            axios
            .get('/api/product-category/product/'+this.form.product_id)
            .then((response) => {
              this.categories = response.data;
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao buscar as cagegorias.");
              console.log(err)
            })
          }
        },

        getCalibers(){
          this.loadingCalibers = true;
          this.calibers = [];
          axios
            .get('/api/product-caliber?product_id='+this.form.product_id)
            .then((response) => {
              console.log("getCalibers");
              console.log(response.data);
              this.calibers = response.data;
              this.loadingCalibers = false;
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao buscar os calibres.");
              this.loadingCalibers = false;
              console.log(err)
            })
        },

        getPackages(){
          this.loadingPackages = true;
          this.packages = [];
          axios
            .get('/api/product-package?product_id='+this.form.product_id)
            .then((response) => {
              console.log("getPackages");
              console.log(response.data);
              this.packages = response.data;
              this.loadingPackages = false;
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao buscar as embalagens.");
              this.loadingPackages = false;
              console.log(err)
            })
        },

        getWeights(){
          this.loadingWeights = true;
          this.weights = [];
          if (this.form.package_id){
            axios
              .get('/api/product-weight?product_id='+this.form.product_id+'&package_id='+this.form.package_id)
              .then((response) => {
                console.log("getWeights");
                console.log(response.data);
                this.weights = response.data;
                this.loadingWeights = false;
              })
              .catch(err => {
                this.$toast.error("Ocorreu um erro ao buscar os pesos.");
                this.loadingWeights = false;
                console.log(err)
              })
          }
        },

        changeAtacado(){

        },
    },
        
}
</script>
<style lang="scss" scoped>
  .input {
    border-color: #ced4da !important;
    min-height: 38px !important;
    color: #495057 !important;
  }
</style>