<template>
  <div>
    <v-col cols="12" sm="12" md="12" class="pa-0" v-if="details">
      <v-col sm="12" md="12" class="px-0 py-3 text-center" v-if="details.status.status == 2 && details.company_id == company">
        <v-btn
          color="#95D904"
          style="color: #FFF !important"
          type="button"
          class="mr-3"
          :loading="loadingStatus"
          :disabled="loadingStatus"
          @click="dialogPedido(details)"
        >
          SOLICITAR PEDIDO
        </v-btn>
      </v-col>
      <v-col sm="12" md="12" justify="center" class="mt-0 mb-0 pa-0" v-if="(details.status.status == 2 || details.status.status >= 4) && !company">
        <v-col sm="12" md="12" class="px-0 py-3 text-center text-danger" v-if="details.status.status == 2">
          Aguardando solicitação de pedido do cliente...
        </v-col>
        <!-- <v-col sm="12" md="12" class="px-0 py-3 text-center" v-if="details.status.status >= 4">
          <a class="text-underline" @click="accessOrder(details.id)">Ir para o pedido</a>
        </v-col> -->
        <hr/>
      </v-col>
      <v-col sm="12" md="12" justify="center" class="mt-2 mb-2 text-center" v-if="details.status.status == 1 && !company && details.proposal_product.length > 1">
        <v-btn
          color="#95D904"
          style="color: #FFF !important"
          type="button"
          class="mr-3"
          :loading="loadingStatus"
          :disabled="loadingStatus"
          @click="changeStatus(details, 'aprovar', 'proposal')"
        >
          APROVAR PROPOSTA
        </v-btn>
        <v-btn
          color="red"
          style="color: #FFF !important"
          type="button"
          :loading="loadingStatus"
          :disabled="loadingStatus"
          @click="changeStatus(details, 'reprovar', 'proposal')"
        >
          REPROVAR PROPOSTA
        </v-btn>
        <hr style="border-width: 2px;">
      </v-COL>
      <v-col class="ml-0 mr-0 pa-0" v-for="(item2, index) in details.proposal_product" :key="index">
        <v-row class="ml-0 mr-0 mb-4" align="center">
          <div class="mr-2">
            <v-avatar tile size="50" class="pull-left" color="grey" style="border-radius: 10px !important;">
              <img
                :src="item2.company_product.picture"
                :alt="item2.company_product.picture"
                v-if="item2.company_product.picture"
              >
              <v-icon dark v-else>
                mdi-food-apple
              </v-icon>
            </v-avatar>
          </div>
          <div class="mr-3">
            <span v-if="item2.company_product.type"> {{item2.company_product.type.name}}</span>
            <span v-if="item2.company_product.category"> {{item2.company_product.category.name}}</span>
            <span v-if="item2.company_product.caliber"> Cal.{{item2.company_product.caliber.value}}</span>
            <span v-if="item2.company_product.weight"> {{item2.company_product.weight.value}}kg</span>
            <span v-if="item2.company_product.brand"> - {{item2.company_product.brand.name}}</span>
          </div>
        </v-row>
        <v-row class="mt-5">
          <v-col sm="4" md="4" class="px-0 py-0 text-center">
            <div style="color:#4C4C4F;font-size:15px;">Disponível:</div>
            <div class="headline" style="">{{item2.company_product.available}}</div>
            <div style="color:#4C4C4F;font-size:15px;margin-top: -3px;">caixas</div>
          </v-col>
          <v-col sm="4" md="4" class="px-0 py-0 text-center">
            <div style="color:#4C4C4F;font-size:15px;">Projeção:</div>
            <div class="headline" style="">{{item2.company_product.projection}}</div>
            <div style="color:#4C4C4F;font-size:15px;margin-top: -3px;">caixas</div>
          </v-col>
          <v-col sm="4" md="4" class="px-0 py-0 text-center">
            <div style="color:#4C4C4F;font-size:15px;">Estoque:</div>
            <div class="headline" style="">{{item2.company_product.available + item2.company_product.projection}}</div>
            <div style="color:#4C4C4F;font-size:15px;margin-top: -3px;">caixas</div>
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0 ml-0" justify="center">
          <v-col sm="12" md="6" class="px-0 text-center" v-if="item2.company_product.min_atacado">
            <div class="title_items" style="color:#4C4C4F;font-size:14px;">Atacado</div>
            <div>
              <span class="" style="color:#5FA348;font-size:15px;">R$</span>
              <span class="headline font-weight-bold" style="color:#5FA348;">{{item2.company_product.min_atacado}}</span>
              <div><small class="text-help">por caixa</small></div>
            </div>
          </v-col>
          <v-col sm="12" md="6" class="px-0 text-center" v-if="item2.company_product.min_varejo">
            <div class="title_items" style="color:#4C4C4F;font-size:14px;">Varejo</div>
            <div>
              <span class="" style="color:#5FA348;font-size:15px;">R$</span>
              <span class="headline font-weight-bold" style="color:#5FA348;">{{item2.company_product.min_varejo}}</span>
              <div><small class="text-help">por caixa</small></div>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col cols="12" sm="12" md="12" class="px-0 py-0 text-center">
            <span class="font-weight-bold" style="font-size:15px;">PROPOSTA</span>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="px-0 py-0 text-center">
            <!-- <span class="" style="color:#0057FF;font-size:12px;">R$</span> -->
            <span class="headline font-weight-bold" style="color:#0057FF;font-size:18px !important;">{{item2.value | currency('R$ ', 2, { thousandsSeparator: '.', decimalSeparator: ',' })}}</span>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="px-0 py-0 text-center">
            <span class="" style="color:#858585;"> {{item2.boxes}} caixas</span>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-3 mb-2">
          <v-col cols="12" sm="12" md="12" class="px-0 py-0 text-center">
            <v-chip medium :color="item2.status.color" text-color="white">{{item2.status.text}}</v-chip>
            <v-col cols="12" sm="12" md="12" class="text-center py-0 mt-3" v-if="item2.status.status == 3">
              <span class="" style="font-size:15px;cursor:pointer;" @click="expandFilters(item2)">
                <i class="fa fa-pencil"></i>
                Editar proposta
              </span>
            </v-col>

            <div class="mb-4" style="display:none;" :class="'form_'+item2.id" v-if="item2.status.status == 3">
              <v-card flat class="px-0">
                <v-form ref="formProposal" v-model="valid" @submit.prevent="saveProposal(item2)" justify="center">
                  <v-row justify="center" class="mr-0 ml-0">
                    <v-col cols="12" sm="12" md="12" class="">
                      <v-row justify="center" class="mb-2">
                        <v-col sm="12" md="5" class="mb-n5">
                          <v-text-field
                            label="Caixas"
                            solo
                            flat
                            dense
                            outlined
                            color="#858585"
                            v-model="item2.boxes_proposal"
                            type="number"
                            required
                            :max="details.available + details.projection"
                            :rules="requiredRules"
                            hide-details
                          ></v-text-field>
                          <small class="text-help">Informe quantas caixas deseja comprar</small>
                        </v-col>
                        <v-col sm="12" md="5" class="mb-n5">
                          <money
                            class="input"
                            v-model.lazy="item2.value_proposal"
                            v-bind="moneyFormat"
                            required
                            :rules="requiredRules"
                          ></money>
                          <small class="text-help">Informe o valor por caixa</small>
                        </v-col>
                      </v-row>
                      <div style="flex: 0 1 auto;" class="text-center" v-if="item2.value_proposal && item2.boxes_proposal">
                        Total: <span class="font-weight-bold">{{(item2.value_proposal * item2.boxes_proposal) | currency('R$ ', 2, { thousandsSeparator: '.', decimalSeparator: ',' })}}</span>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="pt-0" v-if="!item2.status || (item2.status && (item2.status.status == 1 || item2.status.status == 3))">
                      <v-row justify="center" class="mb-2">
                        <v-btn
                          color="#D9043D"
                          style="color: #FFF !important"
                          type="button"
                          :loading="loadingCancelProposal"
                          :disabled="loadingCancelProposal"
                          class="mr-2"
                          @click="cancelProposal()"
                          >
                          CANCELAR PROPOSTA
                        </v-btn>
                        <v-btn
                          color="#95D904"
                          style="color: #FFF !important"
                          type="submit"
                          :loading="loadingProposal"
                          :disabled="loadingProposal || loadingCancelProposal || !item2.value"
                          >
                          ALTERAR
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </div>
          </v-col>
          <v-row justify="center" class="mt-3 mb-2" v-if="item2.status.status == 1 && !company">
            <v-btn
              color="#95D904"
              style="color: #FFF !important"
              type="button"
              class="mr-3"
              :loading="loadingStatus"
              :disabled="loadingStatus"
              @click="changeStatus(item2, 'aprovar', 'product')"
            >
              APROVAR
            </v-btn>
            <v-btn
              color="red"
              style="color: #FFF !important"
              type="button"
              :loading="loadingStatus"
              :disabled="loadingStatus"
              @click="changeStatus(item2, 'reprovar', 'product')"
            >
              REPROVAR
            </v-btn>
          </v-row>
        </v-row>
        <hr/>
      </v-col>
    </v-col>

    <!-- DIALOG SOLICITAR PEDIDO -->
    <v-dialog persistent v-model="dialog_pedido" max-width="800">
      <v-card class="borda" flat min-height="400">
        <v-card-title class="ma-2">
          Solicitar Pedido
          <v-spacer></v-spacer>
          <v-btn icon text @click="dialog_pedido = false;">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="pa-0 ml-0 mr-0">
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <span class="font-weight-bold black--text">Escolha uma transportadora já cadastrada</span>
              <v-select
                no-data-text="Nenhum tranportadora cadastrada"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="pedido.transport_id"
                :items="transports"
                item-value="id"
                item-text="fantasy_name"
                :loading="loadingTransports"
                :disabled="loadingTransports"
                clearable
                @change="pedido.transport_id ? clearTransport() : ''"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="text-left d-flex align-items-center">
              <v-chip class="mt-4" color="primary" outlined small @click="newTransport()">
                Informe os dados manualmente
                <v-icon v-if="!panelAddTransport" right>mdi-menu-down</v-icon>
                <v-icon v-if="panelAddTransport" right>mdi-menu-up</v-icon>
              </v-chip>
            </v-col>
          </v-row>

          <!-- CADASTRAR TRANSPORTADORA -->
          <v-expand-transition>
            <div v-show="panelAddTransport" class="mb-2">
              <v-card class="">
                <v-card-text class="">
                  <div class="h5 pl-4">Dados da Transportadora</div>
                  <v-row justify="center" class="ml-0 mr-0">
                    <v-col cols="12" sm="12" md="6" class="mb-n5">
                      <v-text-field
                        label="Nome comercial"
                        outlined
                        color="#858585"
                        v-model="pedido.transport_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="mb-n5">
                      <v-text-field
                        label="CNPJ"
                        outlined
                        color="#858585"
                        v-model="pedido.transport_cnpj"
                        v-mask="cnpj"
                        :rules="pedido.transport_cnpj ? rulesAdicionalCNPJ : []"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="mb-n5">
                      <v-text-field
                        label="Telefone"
                        outlined
                        color="#858585"
                        v-model="pedido.transport_phone"
                        v-mask="telefone"
                        :rules="pedido.transport_phone ? rulesAdicionalTelefone : []"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="mb-n5">
                      <v-text-field
                        label="E-mail"
                        outlined
                        color="#858585"
                        v-model="pedido.transport_email"
                        :rules="pedido.transport_email ? rulesAdicionalEmail : []"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="mb-n5">
                      <v-textarea
                        label="Informações adicionais"
                        outlined
                        color="#858585"
                        v-model="pedido.transport_information"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-expand-transition>

          <v-row class="pa-0 ml-0 mr-0">
            <v-col cols="12" sm="12" md="6" class="mb-n5">
              <span class="font-weight-bold black--text">Endereço de Entrega *</span>
              <v-select
                no-data-text="Nenhum endereço cadastrado"
                label="Selecione"
                solo
                dense
                flat
                outlined
                color="#858585"
                v-model="pedido.address_id"
                :items="addresses"
                item-value="id"
                :item-text="item => item.cep + ' - ' + item.street + ', ' + item.number"
                required
                :rules="requiredRules"
                :loading="loadingAddress"
                :disabled="loadingAddress"
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="text-left d-flex align-items-center">
              <v-chip class="mt-4" color="primary" outlined small @click="newAddress()">
                <v-icon left>
                  mdi-plus-circle
                </v-icon>
                Novo endereço
                <v-icon v-if="!panelAddAddress" right>mdi-menu-down</v-icon>
                <v-icon v-if="panelAddAddress" right>mdi-menu-up</v-icon>
              </v-chip>
            </v-col>
          </v-row>

          <!-- CADASTRAR ENDEREÇO -->
          <v-expand-transition>
            <div v-show="panelAddAddress" class="mb-2">
              <v-card class="">
                <v-card-text class="">
                  <v-form ref="form2" v-model="valid" v-on:submit="saveAddress($event)">
                    <div class="h5 pl-4">Novo Endereço</div>
                    <v-row class="ml-0 mr-0">
                      <v-col cols="12" sm="12" md="4" class="mb-n5">
                        <v-text-field
                          label="CEP *"
                          outlined
                          color="#858585"
                          v-model="formAddress.cep"
                          @input="viaCep()"
                          v-mask="cep"
                          :disabled="loadingViaCep"
                          :loading="loadingViaCep"
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8" class="mb-n5">
                        <v-text-field
                          label="Rua *"
                          outlined
                          color="#858585"
                          v-model="formAddress.street"
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4" class="mb-n5">
                        <v-text-field
                          label="Número *"
                          outlined
                          color="#858585"
                          v-model="formAddress.number"
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8" class="mb-n5">
                        <v-text-field
                          label="Complemento"
                          outlined
                          color="#858585"
                          v-model="formAddress.complement"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" class="mb-n5">
                        <v-autocomplete
                          label="País *"
                          outlined
                          color="#858585"
                          v-model="formAddress.country"
                          :items="countries"
                          :disabled="disableLocals"
                          no-data-text="Nenhum registro"
                          item-value="nome_pais"
                          item-text="nome_pais"
                          @change="selectedCountry"
                          required
                          :rules="requiredRules"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" class="mb-n5">
                        <v-autocomplete
                          label="Estado *"
                          outlined
                          color="#858585"
                          v-model="formAddress.state"
                          :items="states"
                          :disabled="disableLocals"
                          item-value="sigla"
                          item-text="sigla"
                          no-data-text="Nenhum registro"
                          @change="selectedState"
                          required
                          :rules="requiredRules"
                          v-if="brazil"
                        ></v-autocomplete>
                        <v-text-field
                          label="Estado *"
                          outlined
                          color="#858585"
                          v-model="formAddress.state"
                          required
                          :rules="requiredRules"
                          v-if="!brazil"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" class="mb-n5">
                        <v-autocomplete
                          label="Cidade *"
                          outlined
                          color="#858585"
                          v-model="formAddress.city"
                          :items="cities"
                          :disabled="disableLocals"
                          no-data-text="Selecine um Estado"
                          required
                          :rules="requiredRules"
                          v-if="brazil"
                        ></v-autocomplete>
                        <v-text-field
                          label="Cidade *"
                          outlined
                          color="#858585"
                          v-model="formAddress.city"
                          required
                          :rules="requiredRules"
                          v-if="!brazil"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" class="mb-n5">
                        <v-text-field
                          label="Bairro *"
                          outlined
                          color="#858585"
                          v-model="formAddress.district"
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-col cols="12" sm="12" md="12" class="">
                      <v-row justify="center" class="mb-2 mt-5">
                        <v-btn
                          color="primary"
                          style="color: #FFF !important"
                          type="submit"
                          :loading="loadingSaveAddress"
                          :disabled="loadingSaveAddress"
                          >
                          CADASTRAR
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-form>
                </v-card-text>
              </v-card>
            </div>
          </v-expand-transition>

          <v-row class="pa-0 ml-0 mr-0">
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-textarea
                label="Observações do pedido"
                outlined
                color="#858585"
                v-model="pedido.observation"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-col cols="12" sm="12" md="12" class="text-center">
            <span class="text-danger font-weight-bold mb-1" v-if="errorPedido">{{errorPedido}}</span>
            <v-row justify="center" class="mb-2 mt-5 ml-0 mr-0">
              <v-btn
                color="#95D904"
                style="color: #FFF !important"
                type="button"
                @click="saveOrder()"
                :disabled="loadingSave"
                :loading="loadingSave"
                >
                ENVIAR SOLICITAÇÃO
              </v-btn>
            </v-row>
          </v-col>

        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG LOADING -->
    <v-dialog
      v-model="dialogLoading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
        class="d-flex align-center"
      >
        <v-card-text class="py-3 text-center">
          Aguarde...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}
.v-middle {
  vertical-align: middle !important;
}
</style>

<script>
import axios from "axios";
import moment from 'moment';
import io from 'socket.io-client';
import { cnpj, cpf } from "cpf-cnpj-validator";

export default {
  props: [
    "proposal",
    "user",
    "company",
  ],
  data: function() {
    var tunnel = 'https://tunnel.d3t.com.br';
    var api = 'https://manzanas.app/api';

    if (window.location.hostname == 'localhost'){
      tunnel = 'http://172.20.0.1:5555';
      api = 'http://172.24.0.1/api';
    }

    return {
      loadingProposal: false,
      dialogProposal: false,
      dialog_pedido: false,
      details: '',
      panel: [],
      loadingTransports: false,
      loadingAddress: false,
      loadingViaCep: false,
      loadingSaveAddress: false,
      loadingSave: false,
      loadingStatus: false,
      requiredRules: [v => !!v || "Campo Obrigatório."],
      rulesAdicionalCNPJ: [
        // v => !!v || "Campo Obrigatório.",
        v => cnpj.isValid(v) || "CNPJ inválido"
      ],
      rulesAdicionalEmail: [
        // v => !!v || "Campo Obrigatório.",
        v => /^(([^<>()\\.,;:ç~\s@"]+(\.[^<>()\\.,;:ç~\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v) || "E-mail inválido"
      ],
      rulesAdicionalTelefone: [
        // v => !!v || "Campo Obrigatório.",
        v => (v && v.length >= 14) || "Telefone inválido"
      ],
      cnpj: "##.###.###/####-##",
      cep: "#####-###",
      panelAddTransport: false,
      panelAddAddress: false,
      valid: true,
      pedido: {
        proposal_id: '',
        user_id: '',
        company_id: '',
        address_id: '',
        transport_id: '',
        transport_name: '',
        transport_cnpj: '',
        transport_phone: '',
        transport_email: '',
        transport_information: '',
      },
      formAddress: {
        cep: '',
        street: '',
        number: '',
        complement: '',
        country: 'Brasil',
        state: '',
        city: '',
        district: '',
      },
      countries: [],
      states: [],
      cities: [],
      brazil: true,
      disableLocals: false,
      errorPedido: '',
      transports: [],
      addresses: [],
      socket: io(tunnel, {
        query: `domain=${api}&authorization=${this.user.app_token}`
      }),
      dialogLoading: false,
      loadingCancelProposal: false,
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
    };
  },
  created() {
    if (this.proposal) {
      this.getProposal(this.proposal);
    }
  },
  computed: {
    telefone() {
      let telefone = "";
      if (this.pedido.transport_phone){
        if (this.pedido.transport_phone.length > 14) {
          telefone = "(##) # ####-####";
        } else {
          telefone = "(##) ####-####";
        }
      }
      return telefone;
    },
  },
  methods: {

    getProposal(notLoading, emit) {
      if (!notLoading){
        this.loadingDetails = true;
        this.details = '';
      }

      axios.get('/api/proposal/' + this.proposal)
      .then(response => {
        console.log('getProposal');
        console.log(response.data);
        if (response.data){
          this.details = response.data;
          if (emit){
            this.$emit('attProposalChild', { 'proposal': response.data});
          }
        }
      })
      .catch(e => {
        var msg = 'Houve um erro ao buscar os detalhes da proposta! Por favor, tente novamente.'
        this.$swal("Oops!", msg, "error")
        console.error(e)
      })
      .finally(() => {
        this.loadingDetails = false;
      })
    },

    dialogPedido(){
      // this.proposal = item;
      this.dialog_pedido = true;
      this.clearPedido();
      this.clearAddress();
      this.getTransport();
      this.getAddresses();
      this.getCountries();
      this.getStates();
    },

    getTransport(){
      this.loadingTransport = true;
      axios
        .get('/api/company?type=6')
        .then((response) => {
          this.transports = response.data;
          this.loadingTransport = false;
        })
        .catch(err => {
          this.$toast.error("Ocorreu um erro ao buscar as transportadoras.");
          this.loadingTransport = false;
          console.log(err)
        })
    },

    getAddresses(){
      this.loadingAddress = true;
      axios
        .get('/api/company/addresses?id=' + this.company)
        .then((response) => {
          this.addresses = response.data;
          if (response.data.length == 1){
            this.pedido.address_id = response.data[0].id;
          }
          this.loadingAddress = false;
        })
        .catch(err => {
          this.$toast.error("Ocorreu um erro ao buscar os endereços.");
          this.loadingAddress = false;
          console.log(err)
        })
    },

    newTransport(){
      this.panelAddTransport = !this.panelAddTransport;
    },

    newAddress(){
      this.panelAddAddress = !this.panelAddAddress;
      this.$refs.form2.resetValidation();
    },

    getCountries(){
      axios
        .get('/json/paises.json')
        .then((response) => {
          if (response.data){
            this.countries = response.data;
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
            
        });
    },

    getStates(){
      axios
        .get('/json/cidades.json')
        .then((response) => {
          if (response.data.estados){
            this.states = response.data.estados;
            if (this.stateselected){
              this.selectedState(true);
            }
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
            
        });
    },

    selectedCountry(){
      if (this.formAddress.country == 'Brasil'){
        this.brazil = true;
      } else {
        this.brazil = false;
        this.formAddress.state = '';
        this.formAddress.city = '';
      }
    },

    selectedState(init){
      this.cities = [];
      if (init != true){
        this.formAddress.city = '';
      }
      this.states.forEach(value => {
        if (this.formAddress.state == value.sigla){
            this.cities = value.cidades;
        }
      });
    },

    viaCep() {
      if (this.formAddress.cep) {
        if (this.formAddress.cep.length != 9) {
          this.brazil = true;
          this.disableLocals = false;
        } else {
          this.loadingViaCep = true;
          axios
            .get(
              "https://viacep.com.br/ws/" +
                this.formAddress.cep +
                "/json"
            )
            .then(response => {
              console.log("viaCep");
              console.log(response);
              this.loadingViaCep = false;
              if (response.data.erro == true) {
                this.cep_errado = "CEP não encontrado";
                this.brazil = true;
                this.disableLocals = false;
              } else {
                this.brazil = false;
                this.disableLocals = true;

                this.formAddress.street = response.data.logradouro;
                this.formAddress.city = response.data.localidade;
                this.formAddress.district = response.data.bairro;
                this.formAddress.state = response.data.uf;
                this.formAddress.country = "Brasil";
                this.cep_errado = "";
                this.selectedCountry();
                this.selectedState(true);
              }
            });
        }
      } else {
        this.brazil = true;
        this.disableLocals = false;
      }
    },

    clearAddress(){
      this.formAddress = {
        cep: '',
        street: '',
        number: '',
        complement: '',
        country: 'Brasil',
        state: '',
        city: '',
        district: '',
      }
    },

    clearPedido(){
      this.pedido = {
        proposal_id: '',
        user_id: '',
        company_id: '',
        address_id: '',
        transport_id: '',
        transport_name: '',
        transport_cnpj: '',
        transport_phone: '',
        transport_email: '',
        transport_information: '',
      }
    },

    clearTransport(){
      this.pedido.transport_name = '';
      this.pedido.transport_cnpj = '';
      this.pedido.transport_phone = '';
      this.pedido.transport_email = '';
      this.pedido.transport_information = '';
      this.panelAddTransport = false;
    },

    saveAddress(e){
      if (this.$refs.form2.validate()) {
        e.preventDefault();
        this.loadingSaveAddress = true;
        this.formAddress.company_id = this.company;
        axios
          .post('/api/company/address', this.formAddress)
          .then((response) => {
            console.log("saveAddress");
            console.log(response.data);
            if (!response.data.errors) {
              this.panelAddAddress = false;
              this.getAddresses();
              this.pedido.address_id = response.data.id;
              this.clearAddress();
            } else {
              this.$toast.warning(response.data.errors);
            }
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao cadastrar o endereço.");
            console.log(err)
          })
          .finally(() => {
            this.loadingSaveAddress = false;
          });
      } else {
        e.preventDefault();
        return false;
      }
    },

    saveOrder(){
      this.errorPedido = '';
      if (!this.pedido.transport_id){
        if (!this.pedido.transport_name && !this.pedido.transport_cnpj && !this.pedido.transport_phone && !this.pedido.transport_email){
          this.errorPedido = 'Informe a transportadora.';
          return null;
        }
      }

      if (!this.pedido.address_id){
        this.errorPedido = 'Informe o endereço de entrega.';
        return null;
      }

      this.pedido.proposal_id = this.proposal;
      this.pedido.user_id = this.user.id;
      this.pedido.company_id = this.company;

      console.log("this.pedido");
      console.log(this.pedido);
      // return null;

      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente solicitar o pedido?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim!",
        cancelButtonText: "Não!",
      }).then(result => {
        if (result.value) {
          this.loadingSave = true;
          axios
            .post("/api/order", this.pedido)
            .then((response) => {
              console.log("order");
              console.log(response.data);
              
              if (!response.data.errors) {
                this.$toast.success("Pedido solicitado!");

                this.dialog_pedido = false;
                this.clearPedido();
                this.getProposal(true, 'emit');
                // ENVIAR MENSAGEM
                this.enviar_mensagem(response.data.message, this.user);
              } else {
                this.$toast.warning(response.data.errors);
              }
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao alterar o status.");
              console.log(err)
            })
            .finally(() => {
              this.loadingSave = false;
              this.dialogLoading = false;
            });
        } else {
          this.dialogLoading = false;
        }
      });
    },

    enviar_mensagem (message, user, proposal) {
      const data = {
        method: 'POST',
        endpoint: '/chat/new_message',
        
        content: {
          send_id: user.id,
          receive_id: !this.company ? this.details.user_id : 1,
          archive: null,
          message: message,
          created_at: moment().format('YYYY-MM-DD HH:mm'),
          type: proposal ? 'proposal_att' : null,
          control: proposal ? proposal : null,
        }
      }
      this.socket.emit('event', data);
    },

    changeStatus: function(proposal, status, qual = null) {
      this.dialogLoading = true;
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente alterar o status desta proposta?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim!",
        cancelButtonText: "Não!",
      }).then(result => {
        if (result.value) {
          this.loadingStatus = true;
          var dados = {
            'id' : proposal.id,
            'status': status,
          }
          var url = '';
          if (qual == 'product'){
            url = '/api/proposal/status';
          } else {
            url = '/api/proposal/status-proposal';
          }
          axios
            // .post("/api/proposal/status", dados)
            .post(url, dados)
            .then((response) => {
              console.log("status");
              console.log(response.data);
              if (!response.data.errors) {
                this.$toast.success("Status alterado!");

                this.getProposal(true, 'emit');
                // ENVIAR MENSAGEM
                this.enviar_mensagem(response.data.message, this.user, qual && qual == 'proposal' ? proposal.id : proposal.proposal_id);
              } else {
                this.$toast.warning(response.data.errors);
              }
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao alterar o status.");
              console.log(err)
            })
            .finally(() => {
              this.loadingStatus = false;
              this.dialogLoading = false;
            });
        } else {
          this.dialogLoading = false;
        }
      });
    },

    expandFilters(item){
      $(".form_"+item.id).slideToggle();
      item.value_proposal = item.value;
      item.boxes_proposal = item.boxes;
    },

    saveProposal (item){
      console.log("item");
      console.log(item);
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente enviar esta proposta?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, enviar!",
        cancelButtonText: "Não!",
      }).then(result => {
        if (result.value) {
          var dados = [{
            'id': item.id,
            'value': item.value_proposal,
            'boxes': item.boxes_proposal,
          }]
          axios
            .post("/api/proposal", dados)
            .then((response) => {
              console.log("proposal");
              console.log(response.data);
              if (!response.data.errors) {
                $(".form_"+item.id).slideToggle();
                var self = this;
                this.details.proposal_product.forEach((value, index) => {
                  if (value.id == item.id){
                    self.details.proposal_product[index].value = response.data.value;
                    self.details.proposal_product[index].boxes = response.data.boxes;
                    self.details.proposal_product[index].status = response.data.status;
                  }
                })
                this.$toast.success("Proposta enviada!");
                // ENVIAR MENSAGEM NO CHAT
                var message = '<span class="title_chat_proposal">********** ATUALIZAÇÃO DE PROPOSTA **********</span><br/>Proposta <span class="font-weight-bold">'+item.proposal_id+'</span> atualizada, aguardo retorno! :)<br/>';
                this.enviar_mensagem(message, this.user, item.proposal_id);
              } else {
                this.$toast.warning(response.data.errors);
              }
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao enviar a proposta.");
              console.log(err)
            })
            .finally(() => {
              this.loadingProposal = false;
            });
        } else {
          this.loadingProposal = false;
        }
      });
      },
  }
};
</script>