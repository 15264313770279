<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>Empresa</v-tab>
      <v-tab>Usuários</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      
      <!-- EMPRESA -->
      <v-tab-item>
        <v-card flat min-height="400" :loading="loadingDetails">
          <v-card-text v-if="details">
            <v-col cols="12" sm="12" md="12" class="">
              <p class="headline text--primary">
                {{details.fantasy_name}}
                <v-chip small :color="details.type.color" text-color="white" class="font-weight-bold ml-1">{{details.type.text}}</v-chip>
                <v-btn icon @click="openDialogCompany(details)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </p>
              <div class="text--primary">
                Razão Social: {{details.corporate_name}}<br/>
                CNPJ: {{details.cnpj}}<br/>
                IE: {{details.ie ? details.ie : '-'}}<br/>
                Telefone: {{details.phone}}<br/>
                E-mail: {{details.email}}<br/>
              </div>
            </v-col>

            <!-- CONTATOS -->
            <v-row class="ml-0 mr-0">
              <v-col cols="12" sm="12" md="12" class="pb-0 mt-2">
                <v-card-title class="pa-0">
                  Contatos
                  <v-btn icon color="primary" class="ml-1" @click="openDialogContact()">
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </v-card-title>
                <v-divider class="ma-1"></v-divider>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="" v-for="(item, index) in details.contacts" :key="index">
                <v-card class="mb-3 pa-3" min-height="160">
                  <v-card-text class="pa-0 pb-1">
                    <p class="headline text--primary">
                      {{item.name}}
                      <v-chip x-small outlined class="pull-right" color="#17a2b8" v-if="item.main">Principal</v-chip>
                    </p>
                    <div class="text--primary">
                      Cargo: {{officeName(item.office_id)}}<br/>
                      CPF: {{item.cpf}}<br>
                      Telefone: {{item.phone}}<br>
                      E-mail: {{item.email}}<br>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn icon @click="openDialogContact(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="deleteContact()" :disabled="loadingDeleteContact || details.contacts.length == 1">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <!-- ENDEREÇOS -->
            <v-row class="ml-0 mr-0">
              <v-col cols="12" sm="12" md="12" class="pb-0 mt-2">
                <v-card-title class="pa-0">
                  Endereços
                  <v-btn icon color="primary" class="ml-1" @click="openDialogAddress()">
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </v-card-title>
                <v-divider class="ma-1"></v-divider>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="" v-for="(item, index) in details.addresses" :key="index">
                <v-card class="mb-3 pa-3" min-height="160">
                  <v-card-text class="pa-0 pb-1">
                    <div>Endereço {{index+1}}</div>
                    <p class="headline text--primary">{{item.cep}}</p>
                    <div class="text--primary">
                      {{item.street}}, {{item.number}}, {{item.district}}<br>
                      <span v-if="item.complement">{{item.complement}}</span>
                      {{item.city}} - {{item.state}}, {{item.country}}
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn icon @click="openDialogAddress(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="deleteAddress(item)" :disabled="loadingDeleteAddress || details.addresses.length == 1">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- USUÁRIOS -->
      <v-tab-item>
        <v-card flat min-height="400">
          <v-card-text>
            <v-col cols="12" sm="12" md="12">
              <v-col cols="12" sm="12" md="12" class="p-0">
                <v-card-title class="pa-0">
                  Usuários
                  <v-btn icon color="primary" class="ml-1" @click="openDialogUser()">
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </v-card-title>
              </v-col>
              <v-data-table
                no-data-text="Nenhum usuário cadastrado"
                hide-default-footer
                calculate-widths
                :headers="header"
                :items="users"
                :items-per-page="items_per_page"
                :search="search"
                no-results-text="Nenhum registro encontrado"
                class="elevation-0 table-striped"
                loading-text="Carregando itens..."
                :header-props="{ sortByText: 'Ordenar por' }"
              >
                <template v-slot:item.name="{ item }">
                  <v-avatar size="32" color="#283480" v-if="!item.image" class="mr-2">
                    <span class="white--text headline text-uppercase">{{item.name.charAt(0)}}</span>
                  </v-avatar>
                  <v-avatar size="32" v-else class="mr-2">
                    <img :src="item.image" :lazy-src="item.image" />
                  </v-avatar>
                  {{item.name}}
                </template>
                <template v-slot:item.acao="{ item }">
                  <v-btn icon @click="openDialogUser(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="deleteUser(item)" :disabled="loadingDeleteUser || users.length == 1">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>

                <template v-slot:footer>
                  <v-row justify="center" class="mb-2">
                    <v-btn
                      v-if="items_per_page > 10"
                      @click="items_per_page -= 10"
                      text
                    >Mostrar menos</v-btn>
                    <v-btn
                      v-if="users.length > items_per_page"
                      @click="items_per_page += 10"
                      text
                    >Carregar mais</v-btn>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs-items>

    <!-- DIALOG EMPRESA -->
    <v-dialog v-model="dialog_company" persistent max-width="800">
      <v-card flat class="borda">
        <v-card-title class="ma-2" align-items="center">
          Editar Empresa
          <v-spacer></v-spacer>
          <v-btn icon text @click="closeCompany()">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="formCompany" v-model="validCompany" @submit.prevent="saveCompany()">
            <v-row class="ml-0 mr-0">
              <v-col cols="12" class="mb-n5" :class="formData.company.type.value == 3 || formData.company.type.value == 4 ? 'col-md-6 col-sm-12' : 'col-md-12 col-sm-12'">
                <v-combobox
                  label="Qual a sua atuação? *"
                  v-model="formData.company.type"
                  :items="types"
                  chips
                  outlined
                  color="#858585"
                  item-value="value"
                  item-text="text"
                  required
                  :rules="requiredRules"
                  disabled
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      @click="select"
                      color="#95D904"
                      style="color: #FFF !important"
                    >
                      <strong>{{ item.text }}</strong>                                
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="mb-n5" v-if="formData.company.type.value == 3 || formData.company.type.value == 4">
                <v-select
                  label="Pólo de atuação *"
                  outlined
                  color="#858585"
                  v-model="formData.company.pole_id"
                  :items="poles"
                  item-value="id"
                  item-text="name"
                  required
                  :rules="requiredRules"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-n5">
                <v-text-field
                  label="CNPJ *"
                  outlined
                  color="#858585"
                  v-model="formData.company.cnpj"
                  v-mask="cnpj"
                  required
                  :rules="rulesAdicionalCNPJ"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-n5">
                <v-text-field
                  label="Nome comercial *"
                  outlined
                  color="#858585"
                  required
                  :rules="requiredRules"
                  v-model="formData.company.fantasy_name"
                  autofocus
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-n5">
                <v-text-field
                  label="Razão Social *"
                  outlined
                  color="#858585"
                  v-model="formData.company.corporate_name"
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="mb-n5">
                <v-text-field
                  label="Inscrição Estadual"
                  outlined
                  color="#858585"
                  v-model="formData.company.ie"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="mb-n5">
                <v-text-field
                  label="Telefone *"
                  outlined
                  color="#858585"
                  v-model="formData.company.phone"
                  v-mask="telefone"
                  required
                  :rules="rulesAdicionalTelefone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-n5">
                <v-text-field
                  label="E-mail *"
                  outlined
                  color="#858585"
                  v-model="formData.company.email"
                  required
                  :rules="rulesAdicionalEmail"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row justify="center" class="mb-2">
              <v-btn
                @click="closeCompany()"
                color="secondary"
                class="mr-2"
                outlined
                :disabled="loadingSave"
                >
                CANCELAR
              </v-btn>
              <v-btn
                color="#95D904"
                style="color: #FFF !important"
                :loading="loadingSave"
                :disabled="loadingSave"
                type="submit"
              >
                SALVAR
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG CONTATO -->
    <v-dialog v-model="dialog_contact" persistent max-width="800">
      <v-card flat class="borda">
        <v-card-title class="ma-2" align-items="center">
          {{!formData.contact.id ? 'Novo Contato' : 'Editar Contato'}}
          <v-spacer></v-spacer>
          <v-checkbox v-model="formData.contact.main" label="Contato Principal" class="p-0 mt-0" style="" hide-details></v-checkbox>
          <v-spacer></v-spacer>
          <v-btn icon text @click="closeContact()">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="formContact" v-model="validContact" @submit.prevent="saveContact()">
            <v-row class="ml-0 mr-0">
              <v-col cols="12" sm="12" md="8" class="mb-n5">
                <v-text-field
                  label="Nome *"
                  outlined
                  color="#858585"
                  v-model="formData.contact.name"
                  required
                  :rules="requiredRules"
                  autofocus
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="mb-n5">
                <v-select
                  label="Cargo *"
                  outlined
                  color="#858585"
                  v-model="formData.contact.office_id"
                  :items="offices"
                  item-value="id"
                  item-text="name"
                  required
                  :rules="requiredRules"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="mb-n5">
                <v-text-field
                  label="Telefone *"
                  outlined
                  color="#858585"
                  v-model="formData.contact.phone"
                  v-mask="telefone2"
                  required
                  :rules="rulesAdicionalTelefone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="mb-n5">
                <v-text-field
                  label="CPF *"
                  outlined
                  color="#858585"
                  v-model="formData.contact.cpf"
                  v-mask="cpf"
                  required
                  :rules="rulesAdicionalCPF"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-n5">
                <v-text-field
                  label="E-mail *"
                  outlined
                  color="#858585"
                  v-model="formData.contact.email"
                  required
                  :rules="rulesAdicionalEmail"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row justify="center" class="mb-2">
              <v-btn
                @click="closeContact()"
                color="secondary"
                class="mr-2"
                outlined
                :disabled="loadingSave"
                >
                CANCELAR
              </v-btn>
              <v-btn
                color="#95D904"
                style="color: #FFF !important"
                :loading="loadingSave"
                :disabled="loadingSave"
                type="submit"
              >
                SALVAR
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG ENDEREÇO -->
    <v-dialog v-model="dialog_address" persistent max-width="800">
      <v-card flat class="borda">
        <v-card-title class="ma-2" align-items="center">
          {{!formData.address.id ? 'Novo Endereço' : 'Editar Endereço'}}
          <v-spacer></v-spacer>
          <v-btn icon text @click="closeAddress()">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="formAddress" v-model="validAddress" @submit.prevent="saveAddress()">
            <v-row class="ml-0 mr-0">
              <v-col cols="12" sm="12" md="4" class="mb-n5">
                <v-text-field
                  label="CEP *"
                  outlined
                  color="#858585"
                  v-model="formData.address.cep"
                  @input="viaCep()"
                  v-mask="cep"
                  :disabled="loadingViaCep"
                  :loading="loadingViaCep"
                  required
                  :rules="requiredRules"
                  autofocus
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="8" class="mb-n5">
                <v-text-field
                  label="Rua *"
                  outlined
                  color="#858585"
                  v-model="formData.address.street"
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="mb-n5">
                <v-text-field
                  label="Número *"
                  outlined
                  color="#858585"
                  v-model="formData.address.number"
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="8" class="mb-n5">
                <v-text-field
                  label="Complemento"
                  outlined
                  color="#858585"
                  v-model="formData.address.complement"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="mb-n5">
                <v-autocomplete
                  label="País *"
                  outlined
                  color="#858585"
                  v-model="formData.address.country"
                  :items="countries"
                  :disabled="disableLocals"
                  no-data-text="Nenhum registro"
                  item-value="nome_pais"
                  item-text="nome_pais"
                  @change="selectedCountry"
                  required
                  :rules="requiredRules"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="mb-n5">
                <v-autocomplete
                  label="Estado *"
                  outlined
                  color="#858585"
                  v-model="formData.address.state"
                  :items="states"
                  :disabled="disableLocals"
                  item-value="sigla"
                  item-text="sigla"
                  no-data-text="Nenhum registro"
                  @change="selectedState"
                  required
                  :rules="requiredRules"
                  v-if="brazil"
                ></v-autocomplete>
                <v-text-field
                  label="Estado *"
                  outlined
                  color="#858585"
                  v-model="formData.address.state"
                  required
                  :rules="requiredRules"
                  v-if="!brazil"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="mb-n5">
                <v-autocomplete
                  label="Cidade *"
                  outlined
                  color="#858585"
                  v-model="formData.address.city"
                  :items="cities"
                  :disabled="disableLocals"
                  no-data-text="Selecine um Estado"
                  required
                  :rules="requiredRules"
                  v-if="brazil"
                ></v-autocomplete>
                <v-text-field
                  label="Cidade *"
                  outlined
                  color="#858585"
                  v-model="formData.address.city"
                  required
                  :rules="requiredRules"
                  v-if="!brazil"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="mb-n5">
                <v-text-field
                  label="Bairro *"
                  outlined
                  color="#858585"
                  v-model="formData.address.district"
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row justify="center" class="mb-2">
              <v-btn
                @click="closeAddress()"
                color="secondary"
                class="mr-2"
                outlined
                >
                CANCELAR
              </v-btn>
              <v-btn
                type="submit"
                color="#95D904"
                style="color: #FFF !important"
                :disabled="loadingSave"
                :loading="loadingSave"
                >
                SALVAR
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOG USUÁRIOS -->
    <v-dialog v-model="dialog_user" persistent max-width="800">
      <v-card flat class="borda">
        <v-card-title class="ma-2" align-items="center">
          {{!user.id ? 'Novo Usuário' : 'Editar Usuário'}}
          <v-spacer></v-spacer>
          <v-btn icon text @click="closeUser()">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="formUser" v-model="validUser" @submit.prevent="saveUser()">
            <v-row class="ml-0 mr-0">
              <v-col cols="12" sm="12" md="12" class="mb-n5">
                <v-text-field
                  label="Nome *"
                  outlined
                  color="#858585"
                  v-model="user.name"
                  required
                  :rules="requiredRules"
                  autofocus
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-n5">
                <v-text-field
                  label="E-mail *"
                  outlined
                  color="#858585"
                  v-model="user.email"
                  required
                  :rules="rulesAdicionalEmail"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="mb-n5">
                <v-text-field
                  label="Usuário *"
                  outlined
                  color="#858585"
                  v-model="user.username"
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="mb-n5">
                <v-text-field
                  label="Senha"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  @click:append="showPass = !showPass"
                  outlined
                  color="#858585"
                  v-model="user.password"
                  v-if="user.id"
                ></v-text-field>
                <v-text-field
                  label="Senha *"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  @click:append="showPass = !showPass"
                  outlined
                  color="#858585"
                  v-model="user.password"
                  required
                  :rules="requiredRules"
                  v-else
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="mb-n5">
                <v-file-input
                  label="Imagem"
                  show-size
                  prepend-icon="mdi-camera"
                  v-model="user.imageNew"
                  outlined
                  v-if="user.id"
                  @click="$event.stopPropagation()"
                ></v-file-input>
                <v-file-input
                  label="Imagem"
                  show-size
                  prepend-icon="mdi-camera"
                  v-model="user.image"
                  outlined
                  v-else
                  @click="$event.stopPropagation()"
                ></v-file-input>
              </v-col>
            </v-row>

            <v-row justify="center" class="mb-2">
              <v-btn
                @click="closeUser()"
                color="secondary"
                class="mr-2"
                outlined
                :disabled="loadingSave"
                >
                CANCELAR
              </v-btn>
              <v-btn
                color="#95D904"
                style="color: #FFF !important"
                :loading="loadingSave"
                :disabled="loadingSave"
                type="submit"
              >
                SALVAR
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { cnpj, cpf } from "cpf-cnpj-validator";

export default {
  props: ['company'],
  data(){
    return {
      tab: null,
      loadingDetails: false,
      loadingUsers: false,
      loadingSave: false,
      loadingDeleteAddress: false,
      loadingDeleteContact: false,
      loadingDeleteUser: false,
      details: '',
      dialog_company: false,
      dialog_address: false,
      dialog_contact: false,
      dialog_user: false,
      loadingViaCep: false,
      types: [
        { value: 1, text: 'Atacadista'},
        { value: 2, text: 'Supermercadista'},
        { value: 3, text: 'Embalador'},
        { value: 4, text: 'Produtor'},
        { value: 5, text: 'Representante'},
        { value: 6, text: 'Transportador'},
      ],
      requiredRules: [v => !!v || "Campo Obrigatório."],
      rulesAdicionalCPF: [
        v => !!v || "Campo Obrigatório.",
        v => cpf.isValid(v) || "CPF inválido"
      ],
      rulesAdicionalCNPJ: [
        v => !!v || "Campo Obrigatório.",
        v => cnpj.isValid(v) || "CNPJ inválido"
      ],
      rulesAdicionalEmail: [
        v => !!v || "Campo Obrigatório.",
        v => /^(([^<>()\\.,;:ç~\s@"]+(\.[^<>()\\.,;:ç~\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v) || "E-mail inválido"
      ],
      rulesAdicionalTelefone: [
        v => !!v || "Campo Obrigatório.",
        v => (v && v.length >= 14) || "Telefone inválido"
      ],
      countries: [],
      states: [],
      cities: [],
      brazil: true,
      cpf: "###.###.###-##",
      cnpj: "##.###.###/####-##",
      cep: "#####-###",
      disableLocals: false,
      validCompany: true,
      validAddress: true,
      validContact: true,
      validUser: true,
      formData: {},
      offices: [],
      header: [
        { text: "#", align: "left", value: "id", },
        { text: 'Usuário', align: "left", value: "name", },
        { text: 'E-mail', align: "left", value: "email", },        
        { text: "Ações", align: "left", sortable: false, value: "acao", },
      ],
      items_per_page: 10,
      search: '',
      user: [],
      users: [],
      showPass: false,
    }
  },

  created() {
    if (this.company) {
      this.getCompany();
      this.getUsers();
    }
    this.initFormData();
    this.getCountries();
    this.getStates();
    this.getOffices();
  },

  computed: {
    telefone() {
      let telefone = "";
      if (this.formData.company.phone.length > 14) {
        telefone = "(##) # ####-####";
      } else if (!this.formData.company.phone){
        telefone = "";
      } else {
        telefone = "(##) ####-####";
      }
      return telefone;
    },
    telefone2() {
      let telefone = "";
      if (this.formData.contact.phone.length > 14) {
        telefone = "(##) # ####-####";
      } else if (!this.formData.contact.phone){
        telefone = "";
      } else {
        telefone = "(##) ####-####";
      }
      return telefone;
    },
  },

  methods: {
    getCompany() {
      this.loadingDetails = true;
      this.details = '';

      axios.get('/api/company/' + this.company)
      .then(response => {
        // console.log('getCompany');
        // console.log(response.data);
        if (response.data){
          this.details = response.data;
        }
      })
      .catch(e => {
        var msg = 'Houve um erro ao buscar os detalhes da empresa! Por favor, tente novamente.'
        this.$swal("Oops!", msg, "error")
        console.error(e)
      })
      .finally(() => {
        this.loadingDetails = false;
      })
    },

    getUsers() {
      this.loadingUsers = true;

      axios.get('/api/company/users/' + this.company)
      .then(response => {
        console.log('getUsers');
        console.log(response.data);
        this.users = response.data;
      })
      .catch(e => {
        var msg = 'Houve um erro ao buscar os usuários da empresa! Por favor, tente novamente.'
        this.$swal("Oops!", msg, "error")
        console.error(e)
      })
      .finally(() => {
        this.loadingUsers = false;
      })
    },

    officeName(id) {
      let name = this.offices.filter(item2 => item2.id == id);
      return name.length ? name[0].name : '';
    },

    openDialogAddress(item){
      this.dialog_address = true;
      if (item) {
        this.formData.address = JSON.parse(JSON.stringify(item));
        this.selectedState(true);
      }
    },

    closeAddress() {
      this.dialog_address = false;
      this.brazil = true;
      this.cities = [];
      this.initFormData();
    },

    openDialogContact(item){
      this.dialog_contact = true;
      if (item) {
        this.formData.contact = JSON.parse(JSON.stringify(item));
      }
    },

    closeContact() {
      this.dialog_contact = false;
      this.initFormData();
    },

    openDialogCompany(item){
      this.dialog_company = true;
      if (item) {
        this.formData.company = JSON.parse(JSON.stringify(item));
      }
    },

    closeCompany() {
      this.dialog_company = false;
      this.initFormData();
    },

    initFormData(){
      this.formData = {};
      this.formData = {
        company: {
          type: '',
          pole_id: '',
          fantasy_name: '',
          corporate_name: '',
          cnpj: '',
          ie: '',
          phone: '',
          email: '',
        },
        address: {
          id: '',
          cep: '',
          street: '',
          number: '',
          complement: '',
          country: 'Brasil',
          state: '',
          city: '',
          district: '',
        },
        contact: {
          id: '',
          office_id: '',
          name: '',
          email: '',
          phone: '',
          cpf: '',
          main: '',
        }
      }
      if (this.$refs.formAddress){
        this.$refs.formAddress.resetValidation();
      }
      if (this.$refs.formContact){
        this.$refs.formContact.resetValidation();
      }
    },

    getCountries(){
      axios
        .get('/json/paises.json')
        .then((response) => {
          if (response.data){
            this.countries = response.data;
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
            
        });
    },

    getStates(){
      axios
        .get('/json/cidades.json')
        .then((response) => {
          if (response.data.estados){
            this.states = response.data.estados;
            if (this.stateselected){
              this.selectedState(true);
            }
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
            
        });
    },

    selectedCountry(){
      if (this.formData.address.country == 'Brasil'){
        this.brazil = true;
      } else {
        this.brazil = false;
        this.formData.address.state = '';
        this.formData.address.city = '';
      }
    },

    selectedState(init){
      this.cities = [];
      if (init != true){
          this.formData.address.city = '';
      }
      this.states.forEach(value => {
          if (this.formData.address.state == value.sigla){
              this.cities = value.cidades;
          }
      });
    },

    viaCep() {
      if (this.formData.address.cep) {
        if (this.formData.address.cep.length != 9) {
          this.brazil = true;
          this.disableLocals = false;
        } else {
          this.loadingViaCep = true;
          axios
            .get(
              "https://viacep.com.br/ws/" +
                this.formData.address.cep +
                "/json"
            )
            .then(response => {
              console.log("viaCep");
              console.log(response);
              this.loadingViaCep = false;
              if (response.data.erro == true) {
                this.cep_errado = "CEP não encontrado";
                this.brazil = true;
                this.disableLocals = false;
              } else {
                this.brazil = false;
                this.disableLocals = true;

                this.formData.address.street = response.data.logradouro;
                this.formData.address.city = response.data.localidade;
                this.formData.address.district = response.data.bairro;
                this.formData.address.state = response.data.uf;
                this.formData.address.country = "Brasil";
                this.cep_errado = "";
                this.selectedCountry();
                this.selectedState(true);
              }
            });
        }
      } else {
        this.brazil = true;
        this.disableLocals = false;
      }
    },

    saveAddress (){
      if (this.$refs.formAddress.validate()) {
        this.loadingSave = true;

        var url = '';
        if (this.formData.address.id){
          url = '/api/company/address/' + this.formData.address.id;
        } else {
          url = '/api/company/address';
        }

        this.formData.address.company_id = this.details.id;

        axios
          .post(url, this.formData.address)
          .then((response) => {
            console.log("saveAddress");
            console.log(response.data);
            if (response.data.errors) {
              this.$toast.warning(response.data.errors);
            } else {
              this.$toast.success("Endereço salvo com sucesso!");
              this.getCompany();
              this.closeAddress();
            }
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao salvar o endereço.");
            console.log(err)
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },

    deleteAddress: function(item) {
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente remover este registro?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim!",
        cancelButtonText: "Não!",
      }).then(result => {
        if (result.value) {
          this.loadingDeleteAddress = true;
          axios
            .delete("/api/company/address/" + item.id)
            .then((response) => {
              console.log("status");
              console.log(response.data);
              if (!response.data.errors) {
                this.$toast.success("Registro removido com sucesso!");
                this.getCompany();
              } else {
                this.$toast.warning(response.data.errors);
              }
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao remover o endereço.");
              console.log(err)
            })
            .finally(() => {
              this.loadingDeleteAddress = false;
            });
        }
      });
    },

    getOffices(){
      axios
        .get('/api/office')
        .then((response) => {
          this.offices = response.data;
        })
        .catch(err => {
          console.log(err)
        })
    },

    saveContact (){
      if (this.$refs.formContact.validate()) {
        this.loadingSave = true;

        var url = '';
        if (this.formData.contact.id){
          url = '/api/company/contact/' + this.formData.contact.id;
        } else {
          url = '/api/company/contact';
        }

        this.formData.contact.company_id = this.details.id;

        axios
          .post(url, this.formData.contact)
          .then((response) => {
            console.log("saveContact");
            console.log(response.data);
            if (response.data.errors) {
              this.$toast.warning(response.data.errors);
            } else {
              this.$toast.success("Contato salvo com sucesso!");
              this.getCompany();
              this.closeContact();
            }
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao salvar o contato.");
            console.log(err)
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },

    deleteContact: function(item) {
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente remover este registro?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim!",
        cancelButtonText: "Não!",
      }).then(result => {
        if (result.value) {
          this.loadingDeleteContact = true;
          axios
            .delete("/api/company/contact/" + item.id)
            .then((response) => {
              console.log("status");
              console.log(response.data);
              if (!response.data.errors) {
                this.$toast.success("Registro removido com sucesso!");
                this.getCompany();
              } else {
                this.$toast.warning(response.data.errors);
              }
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao remover o contato.");
              console.log(err)
            })
            .finally(() => {
              this.loadingDeleteContact = false;
            });
        }
      });
    },

    saveCompany (){
      if (this.$refs.formCompany.validate()) {
        this.loadingSave = true;
        axios
          .post('/api/company/' + this.formData.company.id, this.formData.company)
          .then((response) => {
            console.log("saveCompany");
            console.log(response.data);
            if (response.data.errors) {
              this.$toast.warning(response.data.errors);
            } else {
              this.$toast.success("Dados salvos com sucesso!");
              this.getCompany();
              this.closeCompany();
            }
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao salvar a empresa.");
            console.log(err)
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },

    openDialogUser(item){
      if (this.$refs.formUser){
        this.$refs.formUser.resetValidation();
      }
      this.dialog_user = true;
      if (item) {
        this.user = JSON.parse(JSON.stringify(item));
      }
    },

    closeUser() {
      this.dialog_user = false;
      this.user = [];
      if (this.$refs.formUser){
        this.$refs.formUser.resetValidation();
      }
    },

    saveUser (){
      if (this.$refs.formUser.validate()) {
        this.loadingSave = true;

        var url = '';
        if (this.user.id){
          url = '/api/company/user/' + this.user.id;
        } else {
          url = '/api/company/user';
        }

        this.user.company_id = this.details.id;

        let formData = new FormData();
        Object.keys(this.user).map((key) => {
          if (key === "image") {
            let file = this.user.image;
            if (this.user.image) {
              if (this.user.image.name) {
                formData.append(
                  `image`,
                  file
                );
              }
            }
          } else if (key === "imageNew") {
            let file = this.user.imageNew;
            if (this.user.imageNew) {
              formData.append(
                `image`,
                file
              );
            }
          } else {
            if (this.user[key]) {
              formData.append(key, this.user[key]);
            }
          }
        });

        axios
          .post(url, formData)
          .then((response) => {
            console.log("saveUser");
            console.log(response.data);
            if (response.data.errors) {
              this.$toast.warning(response.data.errors);
            } else {
              this.$toast.success("Usuário salvo com sucesso!");
              this.getUsers();
              this.closeUser();
            }
          })
          .catch(err => {
            this.$toast.error("Ocorreu um erro ao salvar o usuário.");
            console.log(err)
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },

    deleteUser: function(item) {
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente remover este registro?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim!",
        cancelButtonText: "Não!",
      }).then(result => {
        if (result.value) {
          this.loadingDeleteUser = true;
          axios
            .delete("/api/company/user/" + item.id)
            .then((response) => {
              console.log("deleteUser");
              console.log(response.data);
              if (!response.data.errors) {
                this.$toast.success("Registro removido com sucesso!");
                this.getUsers();
              } else {
                this.$toast.warning(response.data.errors);
              }
            })
            .catch(err => {
              this.$toast.error("Ocorreu um erro ao remover o contato.");
              console.log(err)
            })
            .finally(() => {
              this.loadingDeleteUser = false;
            });
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>

</style>