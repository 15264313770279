<template>
    <div>
    <v-selectt
        multiple
        label="text"
        :reduce="label => label.value.toString()"
        :options="items"
        v-model="selected"
        >
        <template #search="{attributes, events}">
            <input
              class="vs__search"
              :required="!selected"
              v-bind="attributes"
              v-on="events"
            />
        </template>
    </v-selectt>    
    <input type="hidden" v-model="selected" :name="name"/>
    </div>
</template>
<script>
export default {
    props: [
        'items',
        'name',
        'disabled',
        'selected',
    ],

    data() {
        return {
            // 'selected': '',
        };
    },

    created() {
        if (this.selected){
            this.selected = JSON.parse(this.selected).map(function(item){
               return item.toString();
            });
        }
    }
}
</script>
<style lang="scss" scoped>

</style>